define("mvp-web/services/constants/form-options", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    limits: {
      maxEndorsements: 2500,
      maxWorkflowSmsAction: 1500,
      smsCharacterPerSegment: 160
    }
  };
});