define('mvp-web/models/uploaded-file', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    file: (0, _attr.default)('string'),
    originalFilename: (0, _attr.default)('string'),
    fileUrl: (0, _attr.default)('string'),
    group: (0, _relationships.belongsTo)('group', { inverse: 'uploadedFiles' }),
    groupId: (0, _attr.default)('number'),
    size: (0, _attr.default)('number')
  });
});