define('mvp-web/components/message-items/messages/text', ['exports', 'mvp-web/helpers/format-message-body'], function (exports, _formatMessageBody) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    formattedBody: computed('model.body', function () {
      var message = this.get('model.body');

      return (0, _formatMessageBody.formatMessageBody)(message);
    })
  });
});