define('mvp-web/router', ['exports', 'mvp-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('login');

    this.route('customers', function () {
      this.route('auto');
      this.route('life');
      this.route('umbrella');
      this.route('specialty');
      this.route('commercial');
      this.route('edit', { path: '/:id/edit' });
      this.route('home');
      this.route('new');
      this.route('missed-appointments');
      this.route('pipelined');
    });

    this.route('imports', function () {
      this.route('index');
      this.route('history');
      this.route('customers');
    });

    this.route('exports', function () {
      this.route('history');
    });

    this.route('mvp-exclusive-dashboard', function () {
      this.route('index', { path: '/' });
    });

    this.route('group-organization-transfer-dashboard', function () {
      this.route('index', { path: '/' });
    });

    this.route('leads', function () {
      this.route('hot');
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
      this.route('missed-appointments');
      this.route('pipelined');
      this.route('referrals');
    });

    this.route('new-leads', function () {
      this.route('index', { path: '/' });
    });

    this.route('my-leads', function () {
      this.route('index', { path: '/' });
    });

    this.route('messages', function () {
      this.route('show', { path: '/:id' });
    });

    this.route('organizations', function () {
      this.route('index', { path: '/' });
      this.route('edit', { path: '/:id/edit' });
      this.route('account', { path: '/:id/account' });
    });

    this.route('prospects', function () {
      this.route('pipelined');
      this.route('awaiting-quote');
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
      this.route('missed-appointments');
    });

    this.route('users', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('quotesDefaults', { path: '/:id/quotes_defaults' });
    });

    this.route('tools');

    this.route('workflows', function () {
      this.route('new', { path: '/:type/new' });
      this.route('edit', { path: '/:id/edit' });
    });

    this.route('templates', function () {});

    this.route('phone-numbers', function () {
      this.route('index', { path: '/' });
      this.route('voicemails');
      this.route('recordings');
    });

    this.route('task-sequences', function () {
      this.route('index', { path: '/' });
      this.route('new', { path: '/new' });
      this.route('edit', { path: '/:id/edit' });
    });

    this.route('task-templates', function () {});

    this.route('not-found', { path: '/*path' });
    this.route('dashboard');
    this.route('reports');
    this.route('referral-program');

    this.route('mvp-tests', function () {
      this.route('inputs');
      this.route('checkboxes');
      this.route('radios');
      this.route('buttons');
      this.route('datetime');
      this.route('buttongroup');
    });

    this.route('oauth', function () {
      this.route('azure');
      this.route('google');
      this.route('ringcentral');
    });
  });

  exports.default = Router;
});