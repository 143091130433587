define('mvp-web/mixins/quote-shareable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    carrierLabel: computed(function () {
      if (this.get('group.isCustomer')) {
        if (this.get('isFirstParty')) return 'Carrier';

        return 'Prior Carrier';
      }

      if (this.get('isFirstParty')) return 'Quoted Carrier';

      return 'Current Carrier';
    }),
    dateTypeLabel: computed('isFirstParty', 'isSold', function () {
      if (!this.get('isFirstParty')) return 'Renewal Date';

      if (this.get('isSold')) return 'Policy Effective Date';

      return 'Quoted Effective Date';
    })
  });
});