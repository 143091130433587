define('mvp-web/validations/at-workflow-conditions-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    atDimensionId: [(0, _validators.validatePresence)(true)],
    operator: [(0, _validators.validatePresence)(true)],
    value: [(0, _validators.validatePresence)(true)]
  };
});