define('mvp-web/components/quote-forms/commercial', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      run = Ember.run;
  exports.default = Component.extend({
    store: service(),
    actions: {
      addNewCommercial: function addNewCommercial() {
        var store = this.get('store');
        var newAddress = store.createRecord('address', { usState: this.get('group.address.usState') });
        var commercial = store.createRecord('commercial', { address: newAddress, group: this.get('model') });
        this.get('group.commercials').pushObject(commercial);

        run.scheduleOnce('afterRender', function () {
          Ember.$('.commercial-info .commercial-list').find('> tbody > tr:last td button.trigger-btn').trigger('click');
        });
      }
    }
  });
});