define('mvp-web/controllers/prospects/edit', ['exports', 'mvp-web/controllers/groups/edit'], function (exports, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _edit.default.extend({
    detailsPath: 'prospects.edit',
    transitionStateIsProspect: true,
    transitionStateIsCustomer: false,
    isBeingDeleted: false,

    actions: {
      delete: function _delete(group) {
        this.set('isBeingDeleted', true);
        group.destroyRecord();
        this.transitionToRoute('prospects.index');
      }
    }
  });
});