define('mvp-web/components/task-template-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.get('fetchTemplates').perform();
    }
  });
});