define('mvp-web/components/stalled-quote-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    quoteMappingInfo: {
      CommercialQuote: { text: 'Commercial Quote', icon: 'fa fa-fw fa-briefcase fa-lg' },
      HomeQuote: { text: 'Home Quote', icon: 'fa fa-fw fa-home fa-lg' },
      LifeQuote: { text: 'Life Quote', icon: 'fa fa-fw fa-user fa-lg' },
      SpecialtyQuote: { text: 'Specialty Quote', icon: 'fa fa-fw fa-star-o fa-lg' },
      UmbrellaQuote: { text: 'Umbrella Quote', icon: 'fa fa-fw fa-umbrella fa-lg' },
      VehicleQuote: { text: 'Vehicle Quote', icon: 'fa fa-fw fa-car fa-lg' }
    },

    quoteMapping: computed('stalledQuote.quoteType', function () {
      return this.get('quoteMappingInfo')[this.get('stalledQuote.quoteType')];
    }),

    actions: {}
  });
});