define('mvp-web/models/task-template', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Title'
    })
  });

  var service = Ember.inject.service,
      computed = Ember.computed,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent;
  exports.default = _model.default.extend(Validations, {
    session: service(),
    title: (0, _attr.default)(),
    description: (0, _attr.default)(),
    user: (0, _relationships.belongsTo)('user'),
    organization: (0, _relationships.belongsTo)('organization'),
    taskSequenceTemplates: (0, _relationships.hasMany)('taskSequenceTemplates'),
    userId: (0, _attr.default)(),

    isUserOwned: computed('session.currentUser.id', 'user', 'user.id', function () {
      var currentUser = this.get('session.currentUser');

      return isPresent(this.get('user.id')) && this.get('user.id') === currentUser.get('id');
    }),

    isOrganizationOwned: computed('user', function () {
      return isEmpty(this.get('user.id'));
    }),

    owner: computed('isUserOwned', 'isOrganizationOwned', function () {
      return this.get('isUserOwned') ? 'user' : 'organization';
    })
  });
});