define('mvp-web/components/vehicle-finder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var service = Ember.inject.service,
      computed = Ember.computed,
      Component = Ember.Component;

  var vehicleProperties = ['vin', 'licensePlate'];
  var addressProperties = ['street', 'postalCode'];
  var allProperties = vehicleProperties.concat(addressProperties);

  exports.default = Component.extend({
    store: service(),

    isLookingForVehicles: false,

    formValid: computed.or.apply(computed, _toConsumableArray(allProperties)),
    submitButtonDisabled: computed.not('formValid'),

    vinValid: computed('vin', function () {
      return this.getWithDefault('vin', '').length > 5;
    }),

    licensePlateValid: computed('licensePlate', function () {
      return this.getWithDefault('licensePlate', '').length > 3;
    }),

    vinOrLicenseValid: computed.or('vinValid', 'licensePlateValid'),

    actions: {
      toggleVehiclesBrowser: function toggleVehiclesBrowser() {
        this.sendAction('toggleVehiclesBrowser');
      },
      search: function search() {
        var _this = this;

        var conditions = {
          vehicle: this.getProperties(vehicleProperties),
          address: this.getProperties(addressProperties)
        };

        this.sendAction('setIsLookingForVehicles', true);

        this.get('store').query('vehicles-lookup', conditions).then(function (vehicles) {
          _this.set('vehicles', vehicles);
        }).finally(function () {
          _this.set('hasFetchedVehicles', true);
          _this.sendAction('setIsLookingForVehicles', false);
        });
      }
    }
  });
});