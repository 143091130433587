define("mvp-web/form-objects/at-workflow-new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: undefined,
    atActions: undefined,
    startingWorkflowActionId: undefined,
    atWorkflowActions: []
  };
});