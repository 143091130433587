define('mvp-web/components/customer-new-form', ['exports', 'lodash/uniq', 'mvp-web/mixins/power-select-highlighted'], function (exports, _uniq, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    classNames: ['_customer-new-form'],
    store: service(),

    displayCar: false,

    existingPolicies: computed.alias('model.existingPolicies'),

    formErrors: computed('changeset.errors.[]', 'changeset.errors.@each.validation', function () {
      // Because some of the fields that can be alternatively valid have the same
      // message we want to have unique errors.
      return (0, _uniq.default)(Ember.get(this, 'changeset.errors').map(function (errorSet) {
        return errorSet.validation[0];
      }));
    }),

    actions: {
      didSelectAddress: function didSelectAddress(selected, changeset) {
        var addressComponents = selected ? selected.formatted_address.split(', ') : [];

        var _addressComponents = _slicedToArray(addressComponents, 2),
            street1 = _addressComponents[0],
            city = _addressComponents[1];

        changeset.setProperties({
          city: city,
          postalCode: null,
          isVerified: true,
          street1: street1
        });
      },
      addExistingPolicy: function addExistingPolicy() {
        var newPolicy = this.get('store').createRecord('existing-policy', {
          policyTerm: '6 months'
        });
        var array = this.get('existingPolicies');
        array = [].concat(_toConsumableArray(array), [newPolicy]);
        this.set('existingPolicies', array);
        this.set('changeset.policies', array);
      },
      removePolicy: function removePolicy(index) {
        var array = this.get('existingPolicies');
        array = array.filter(function (_, i) {
          return i !== index;
        });
        this.set('existingPolicies', array);
        this.set('changeset.policies', array);
      }
    }
  });
});