define('mvp-web/serializers/phone-number-action', ['exports', 'mvp-web/serializers/application', 'mvp-web/mixins/embedded-relationships'], function (exports, _application, _embeddedRelationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRelationships.default, {
    // Overriden for STI structure in backend
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      hash.phone_number_action = this.serialize(snapshot, options);
    },


    attrs: {
      nextAction: { serialize: true }
    }
  });
});