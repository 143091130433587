define('mvp-web/components/critical-sales-data', ['exports', 'mvp-web/mixins/contextual-pipeline-until'], function (exports, _contextualPipelineUntil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend(_contextualPipelineUntil.default, {
    store: service(),
    isLoaded: computed.alias('group.isFullyLoaded'),
    isNotFullyLoaded: computed.not('group.isFullyLoaded')
  });
});