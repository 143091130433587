define('mvp-web/models/at-workflow-action', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    atWorkflowActionId: (0, _attr.default)(),
    atActionId: (0, _attr.default)(),
    atActionTime: (0, _attr.default)(),
    atActionTimeValue: (0, _attr.default)(),
    actionTemplate: (0, _attr.default)(),
    additionalOptions: (0, _attr.default)(),
    totalMessagesSent: (0, _attr.default)(),
    otherResponseCount: (0, _attr.default)(),
    stopResponseCount: (0, _attr.default)(),
    noResponseCount: (0, _attr.default)(),
    emailsOpenedCount: (0, _attr.default)(),
    haltOnFailure: (0, _attr.default)('boolean', { defaultValue: false }),
    atWorkflowName: (0, _attr.default)(),

    isActionDaysBefore: computed.equal('atActionTime', 'days_before_date'),
    isActionDaysAfter: computed.equal('atActionTime', 'days_after_date'),

    daysBeforeOptions: Array.from({ length: 45 }, function (_, i) {
      return i + 1;
    }).reverse(),
    commercialDaysBeforeOptions: Array.from({ length: 90 }, function (_, i) {
      return i + 1;
    }).reverse(),
    daysAfterOptions: Array.from({ length: 45 }, function (_, i) {
      return i + 1;
    }),

    daysOptions: computed('atActionTime', function () {
      var options = this.get('isActionDaysBefore') ? this.get('daysBeforeOptions') : this.get('daysAfterOptions');

      return options;
    }),

    commercialDaysOptions: computed('atActionTime', function () {
      var options = this.get('isActionDaysBefore') ? this.get('commercialDaysBeforeOptions') : this.get('daysAfterOptions');

      return options;
    }),

    emailOpenedRate: computed('totalMessagesSent', 'emailsOpenedCount', function () {
      var totalMessagesSent = this.get('totalMessagesSent');
      var emailsOpenedCount = this.get('emailsOpenedCount');

      if (!totalMessagesSent || !emailsOpenedCount) return 0;

      return (emailsOpenedCount / totalMessagesSent * 100).toFixed(2);
    })
  });
});