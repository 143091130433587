define('mvp-web/routes/imports/customers', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: service(),

    setupController: function setupController(controller) {
      controller.send('resetLoaders');
      controller.get('loadWorkflows').perform();
      var organizationId = this.get('session.session.authenticated.organization_id');

      controller.setProperties({
        availableTags: this.store.findAll('tag'),
        organization: this.store.findRecord('organization', organizationId),
        referralSourceId: '',
        tags: []
      });
    }
  });
});