define('mvp-web/components/consolidate-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      run = Ember.run;
  exports.default = Component.extend({
    consolidationTagId: computed('consolidationTag', function () {
      return this.get('consolidationTag.id');
    }),

    consolidatedTagsIds: computed('selectedTags.[]', function () {
      return this.get('selectedTags').map(function (tag) {
        return tag.id;
      });
    }),

    nonSelectedTags: computed('selectedTag', function () {
      var selected = this.get('selectedTags').map(function (t) {
        return t.id;
      });
      return this.get('tags').filter(function (tag) {
        return tag.id && !selected.includes(tag.id);
      });
    }),

    visibleTags: computed('tagsFilter', 'selectedTags.[]', function () {
      var selected = this.get('selectedTags').map(function (t) {
        return t.id;
      });
      var filter = this.get('tagsFilter') || '';
      return this.get('tags').filter(function (tag) {
        return tag.id && (filter.length <= 2 || tag.get('name').includes(filter) || selected.includes(tag.id));
      });
    }),

    isSelected: function isSelected(tag) {
      return this.get('selectedTags').includes(tag);
    },


    actions: {
      selectTags: function selectTags(tag, _ref) {
        var checked = _ref.target.checked;

        var selectedTags = checked ? Array.from(this.get('selectedTags')).concat([tag]) : Array.from(this.get('selectedTags')).filter(function (current) {
          return current.id !== tag.id;
        });
        this.set('selectedTags', selectedTags);
        var selected = this.get('selectedTags').map(function (t) {
          return t.id;
        });
        var nonSelected = this.tags.filter(function (tag) {
          return !selected.includes(tag.id);
        });
        this.set('nonSelectedTags', nonSelected);
      },
      createTag: function createTag(input) {
        var name = String(input || '').trim();
        this.set('consolidationTag', { getProperties: function getProperties() {
            return { name: name };
          }, name: name });
      },
      filterTags: function filterTags(_ref2) {
        var _this = this;

        var value = _ref2.target.value;

        if (this.updateTimeout) clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(function () {
          return _this.set('tagsFilter', value);
        }, 100);
      },
      openModal: function openModal(event) {
        event.preventDefault();
        run(function () {
          return Ember.$('#consolidate-tags-confirm-modal').modal('show');
        });
      },


      closeModal: function closeModal() {
        return run(function () {
          return Ember.$('#consolidate-tags-confirm-modal').modal('hide');
        });
      },

      consolidateTags: function consolidateTags() {
        var consolidationTag = this.get('consolidationTag');
        var consolidatedTags = this.get('selectedTags');
        this.sendAction('consolidateTags', consolidationTag, consolidatedTags);
        run(function () {
          return Ember.$('#consolidate-tags-confirm-modal').modal('hide');
        });
      }
    }
  });
});