define('mvp-web/controllers/phone-numbers/voicemails', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Controller = Ember.Controller,
      getOwner = Ember.getOwner,
      service = Ember.inject.service;
  exports.default = Controller.extend({
    features: service(),
    featureFlag: service(),

    voipEnabled: computed.and('featureFlag.setupLoaded', 'features.voip'),

    actions: {
      refreshModel: function refreshModel() {
        var route = getOwner(this).lookup('route:phone-numbers.index');
        return route.refresh();
      }
    }
  });
});