define('mvp-web/controllers/organizations/edit', ['exports', 'mvp-web/mixins/errors', 'mvp-web/helpers/format-phone'], function (exports, _errors, _formatPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Controller = Ember.Controller;
  exports.default = Controller.extend(_errors.default, {
    notifications: service('notification-messages'),

    showAutoAttendantForm: false,
    showExtensionForm: false,
    validationCode: '',

    actions: {
      cancel: function cancel() {
        this.setProperties({
          showAutoAttendantForm: false,
          showExtensionForm: false
        });
      },
      toggleForm: function toggleForm(form) {
        if (form === 'auto attendant') {
          this.setProperties({
            showAutoAttendantForm: true,
            showExtensionForm: false
          });
        } else {
          this.setProperties({
            showAutoAttendantForm: false,
            showExtensionForm: true
          });
        }
      },
      verifyWithAttendant: function verifyWithAttendant() {
        var _this = this;

        var organizationSetting = this.get('model.organizationSetting.content');

        organizationSetting.save().then(function () {
          _this.send('verify');
        }, function (error) {
          _this.get('notifications').error(_this.decodeJsonApiErrors(error, organizationSetting), { htmlContent: true });
        });
      },
      verify: function verify() {
        var _this2 = this;

        this.get('model').verify().then(function (data) {
          _this2.set('validationCode', data.validation_code);
        }, function (error) {
          _this2.get('notifications').error(_this2.decodeJsonApiErrors(error), { htmlContent: true });
        });
      },
      unverify: function unverify() {
        var _this3 = this;

        this.get('model').unverify().then(function (data) {
          var phoneNumber = (0, _formatPhone.formatPhone)([data.phone_number]);

          _this3.get('notifications').success('The phone number ' + phoneNumber + ' has been disassociated with outgoing text messages.');
        }, function (error) {
          _this3.get('notifications').error(_this3.decodeJsonApiErrors(error), { htmlContent: true });
        });
      }
    }
  });
});