define('mvp-web/components/quote-templates-forms/call-log', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    staticData: service(),
    features: service(),
    session: service(),
    nextActions: computed.alias('staticData.staticData.callLogTemplateActions'),
    statuses: computed.alias('staticData.staticData.callLogTemplateStatuses'),

    displayRingCentralNextActionAlert: computed('template', 'template.nextAction', function () {
      var nextAction = this.get('template.nextAction');
      var enableRingcentralNextAction = this.get('session.currentUser.enableRingcentralNextAction');
      var actionableRingCentralNextActions = ['Call Back Today', 'Call Tomorrow'];
      return this.get('features.ringcentral') && enableRingcentralNextAction && !actionableRingCentralNextActions.includes(nextAction);
    }),

    actions: {
      selectStatus: function selectStatus(selectedOption) {
        this.get('template').set('status', selectedOption);
      },
      selectNextAction: function selectNextAction(selectedOption) {
        this.get('template').set('nextAction', selectedOption);
      }
    }
  });
});