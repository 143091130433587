define('mvp-web/components/dashboard/performance-statistics/sales-activity/group-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    groupPath: computed('group', function () {
      var transitionStatePlural = this.get('group.transitionStatePlural');
      return transitionStatePlural + '.edit';
    })
  });
});