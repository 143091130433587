define('mvp-web/adapters/tag', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    consolidate: function consolidate(payload) {
      return this.ajax(this.get('namespace') + '/tags/consolidate', 'PATCH', { data: payload });
    }
  });
});