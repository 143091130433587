define('mvp-web/models/organization-setting', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    autoAttendantDuration: (0, _attr.default)('number'),
    hostedNumberState: (0, _attr.default)(),
    organization: (0, _relationships.belongsTo)('organization'),
    outgoingCallerIdVerified: (0, _attr.default)('boolean'),
    phoneNumberExtension: (0, _attr.default)('string'),
    verifyWithTwilio: (0, _attr.default)('boolean'),
    defaultFeeForVehicle: (0, _attr.default)(),
    defaultFeeForHome: (0, _attr.default)(),
    defaultFeeForUmbrella: (0, _attr.default)(),
    defaultFeeForSpecialty: (0, _attr.default)(),
    disableMessagingFeature: (0, _attr.default)('boolean'),
    homeClaimFallOff: (0, _attr.default)('number'),
    autoClaimFallOff: (0, _attr.default)('number'),
    otherClaimFallOff: (0, _attr.default)('number'),
    workflowSettings: (0, _attr.default)(),
    enableAutomaticLeadsRebilling: (0, _attr.default)('boolean'),
    mvpLeadNotificationEmails: (0, _attr.default)('string'),
    stallDays: (0, _attr.default)('number'),
    ringcentralSms: (0, _attr.default)('boolean'),
    textingProvider: (0, _attr.default)('string'),
    restrictDashboard: (0, _attr.default)('boolean'),
    hostedNumberKey: computed('hostedNumberState', function () {
      return 'hosted-number-state-' + this.get('hostedNumberState').dasherize();
    })
  });
});