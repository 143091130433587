define('mvp-web/adapters/quote', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    convertToCanceled: function convertToCanceled(_ref) {
      var groupId = _ref.groupId,
          canceledQuotesAttributes = _ref.canceledQuotesAttributes;

      return this.ajax('/api/v1/quotes/cancel', 'PUT', {
        data: {
          group_id: groupId,
          canceled_quotes_attrs: canceledQuotesAttributes
        }
      });
    }
  });
});