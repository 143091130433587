define('mvp-web/models/contact-history', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'rollbar'], function (exports, _model, _attr, _relationships, _emberCpValidations, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    state: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'State'
    }),
    member: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Member'
    })]
  });

  var computed = Ember.computed;
  exports.default = _model.default.extend(Validations, {
    contactedAt: (0, _attr.default)('utc'),
    duration: (0, _attr.default)(),
    state: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    notes: (0, _attr.default)('string'),
    contact: (0, _relationships.belongsTo)('contact', { polymorphic: true }),
    sender: (0, _relationships.belongsTo)('sender', { polymorphic: true }),
    receiver: (0, _relationships.belongsTo)('receiver', { polymorphic: true }),
    user: (0, _relationships.belongsTo)('user', { polymorphic: true }),
    member: (0, _relationships.belongsTo)('member', { polymorphic: true }),

    contactType: Ember.computed('contact', function () {
      try {
        var modelName = this.get('contact.content').constructor.modelName;

        var contactType = 'Phone';
        switch (modelName) {
          case 'phone-call':
            contactType = 'Phone';
            break;
          case 'message-statuses/email':
            contactType = 'Email';
            break;
          case 'messages/text':
            contactType = 'Text';
            break;
          case 'messages/attachment':
            contactType = 'Text';
            break;
          case 'messages/quote-comparison':
            contactType = 'Text';
            break;
          case 'mms-message':
            contactType = 'MMS';
            break;
        }

        if (this.get('state') === 'na') {
          contactType = 'n/a';
        }

        return contactType;
      } catch (e) {
        _rollbar.default.error(e);
        return '';
      }
    }),

    hasNotes: Ember.computed('notes', 'contact.notes', function () {
      return Ember.isPresent(this.get('notes')) || Ember.isPresent(this.get('contact.notes'));
    }),

    teamMember: computed('sender', 'receiver', function () {
      if (this.get('contact.user')) return this.get('contact.user');
      if (this.get('sender._internalModel.modelName') === 'member') return this.get('receiver');

      return this.get('sender');
    }),

    householdMember: computed('sender', 'receiver', function () {
      if (this.get('contact.member')) return this.get('contact.member');
      if (this.get('sender._internalModel.modelName') === 'member') return this.get('sender');

      return this.get('receiver');
    }),

    noCallAttempt: Ember.computed.equal('state', 'na'),
    isThereContact: Ember.computed('contact', function () {
      return this.get('contact.content') != null;
    }),
    isPhoneCall: Ember.computed('contact', function () {
      return this.get('contact.content').constructor.modelName === 'phone-call';
    }),

    contactState: Ember.computed('state', function () {
      var isStateRead = this.get('state') === 'read';
      var isTypeSms = this.get('contactType') === 'Text';

      var isSmsRead = isStateRead && isTypeSms;

      return isSmsRead ? 'sent' : this.get('state');
    }),

    durationString: computed('duration', function () {
      var duration = this.get('duration');
      if (duration === null) return;
      var slicePoint = 0;
      if (Math.floor(duration / 3600) >= 1) {
        slicePoint = 11;
      } else if (Math.floor(duration / 60) >= 1) {
        slicePoint = 14;
      } else {
        return duration + 's';
      }
      var durationMilliseconds = duration * 1000;
      var formattedDuration = new Date(durationMilliseconds).toISOString().slice(slicePoint, 19); // HH:mm:ss

      return formattedDuration;
    })
  });
});