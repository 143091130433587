define('mvp-web/models/stats-user-mapping', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    userFullName: (0, _attr.default)(),
    groupIds: (0, _attr.default)('array', { defaultValue: function defaultValue() {
        return [];
      } })
  });
});