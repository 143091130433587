define('mvp-web/components/group-call-row', ['exports', 'mvp-web/mixins/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend(_errors.default, {
    notifications: service('notification-messages'),
    segment: service(),

    _trackCallError: function _trackCallError(status, phoneCallId, error) {
      this.get('segment').trackEvent('Error marking phone call as ' + status + ' for group.', {
        groupId: this.get('model.id'),
        userId: this.get('session.currentUser.id'),
        phoneCallId: phoneCallId,
        error: error.message
      });
    },


    actions: {
      cancelPhoneCall: function cancelPhoneCall() {
        var _this = this;

        var phoneCall = this.get('phoneCall');
        phoneCall.set('state', 'cancelled');
        phoneCall.save().then(function () {
          _this.get('segment').trackEvent('Canceled phone call for group.', {
            groupId: _this.get('group.id'),
            userId: _this.get('session.currentUser.id'),
            phoneCallId: phoneCall.get('id'),
            notes: phoneCall.get('notes')
          });
        }, function (error) {
          _this.get('notifications').error('There was a problem cancelling phone call: <div>' + _this.decodeJsonApiErrors(error) + '</div>', { htmlContent: true });
          _this._trackCallError('canceled', phoneCall.get('id'), error);
        });
      },
      markPhoneCallAnswered: function markPhoneCallAnswered() {
        var _this2 = this;

        var phoneCall = this.get('phoneCall');
        phoneCall.set('state', 'answered');
        phoneCall.save().then(function () {
          _this2.get('segment').trackEvent('Marked phone call as answered for group.', {
            groupId: _this2.get('group.id'),
            userId: _this2.get('session.currentUser.id'),
            phoneCallId: phoneCall.get('id'),
            notes: phoneCall.get('notes')
          });
        }, function (error) {
          _this2.get('notifications').error('There was a problem marking this call "answered": <div>' + _this2.decodeJsonApiErrors(error) + '</div>', { htmlContent: true });
          _this2._trackCallError('answered', phoneCall.get('id'), error);
        });
      },
      markPhoneCallUnanswered: function markPhoneCallUnanswered() {
        var _this3 = this;

        var phoneCall = this.get('phoneCall');
        phoneCall.set('state', 'unanswered');
        phoneCall.save().then(function () {
          _this3.get('segment').trackEvent('Marked phone call as unanswered for group.', {
            groupId: _this3.get('group.id'),
            userId: _this3.get('session.currentUser.id'),
            phoneCallId: phoneCall.get('id'),
            notes: phoneCall.get('notes')
          });
        }, function (error) {
          _this3.get('notifications').error('There was a problem marking this call "unanswered": <div>' + _this3.decodeJsonApiErrors(error) + '</div>', { htmlContent: true });
          _this3._trackCallError('unanswered', phoneCall.get('id'), error);
        });
      }
    }
  });
});