define('mvp-web/adapters/organization-invite', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    accept: function accept(id) {
      var url = this.buildURL('organization-invite', id) + '/accept';

      return this.ajax(url, 'PATCH');
    },
    reject: function reject(id) {
      var url = this.buildURL('organization-invite', id) + '/reject';

      return this.ajax(url, 'PATCH');
    }
  });
});