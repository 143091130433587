define('mvp-web/routes/organizations/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },


    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    model: function model(params) {
      return this.store.query('organization', params);
    },
    afterModel: function afterModel(model) {
      if (model) {
        model.forEach(function (m) {
          var user = m.get('users').findBy('id', m.get('primaryUserId'));
          m.set('primaryUser', user);
        });
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    }
  });
});