define('mvp-web/components/groups-table-critical-sales-block', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    claimsToDisplay: computed.filterBy('claims', 'shouldDisplay'),
    annualSavings: computed('firstPartyQuote.annualPremium', 'thirdPartyQuote.annualPremium', function () {
      return this.get('thirdPartyQuote.annualPremium') - this.get('firstPartyQuote.annualPremium');
    }),
    canCalculateAnnualSavings: computed.and('firstPartyQuote', 'hasThirdPartyQuoteWithPremium'),
    thirdPartyQuote: computed('index', 'group.thirdPartyQuotes', 'group.thirdPartyQuotes.[]', 'group.thirdPartyQuotes.@each.annualPremium', function () {
      this.get('group.thirdPartyQuotes');
      var index = this.get('index');
      return this.get('group.thirdParty' + this.get('quoteType').capitalize() + 'Quotes')[index];
    }),

    quoteTypeLabel: computed.alias('firstPartyQuote.quoteTypeLabel'),
    quoteType: computed.alias('firstPartyQuote.quoteType'),

    hasThirdPartyQuoteWithCarrierName: computed.notEmpty('thirdPartyQuote.selectedInsuranceCarrier.name'),
    hasThirdPartyQuoteWithPremium: computed.notEmpty('thirdPartyQuote.annualPremium'),

    shouldNotShowDateRows: computed.equal('quoteType', 'umbrella'),
    shouldShowDateRows: computed.not('shouldNotShowDateRows'),

    hasThirdPartyQuoteWithRenewalDate: computed.notEmpty('thirdPartyQuote.policyRenewalDate'),
    hasFirstPartyQuoteWithEffectiveDate: computed.notEmpty('firstPartyQuote.quoteDate'),
    hasSoldFirstPartyQuoteWithRenewalDate: computed.notEmpty('firstPartyQuote.policyRenewalDate')
  });
});