define('mvp-web/models/group-organization-transfer', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    createdAt: (0, _attr.default)('utc'),
    toOrganizationName: (0, _attr.default)('string'),
    deletedGroup: (0, _attr.default)('boolean'),
    groupStatus: (0, _attr.default)('string'),

    toOrganization: (0, _relationships.belongsTo)('organization'),
    fromOrganization: (0, _relationships.belongsTo)('organization'),
    group: (0, _relationships.belongsTo)('group'),
    user: (0, _relationships.belongsTo)('user'),
    primaryMember: (0, _relationships.belongsTo)('member'),
    address: (0, _relationships.belongsTo)('address'),

    formattedPhoneNumber: Ember.computed('primaryMember', function () {
      return this.get('primaryMember.formattedPhoneNumber');
    }),

    formattedMobileNumber: Ember.computed('primaryMember', function () {
      return this.get('primaryMember.formattedMobileNumber');
    })
  });
});