define('mvp-web/components/quote-forms/umbrella', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    actions: {
      selectLiabilityLimit: function selectLiabilityLimit(quote, selectedOption) {
        quote.setLiabilityLimit(selectedOption);
      }
    }
  });
});