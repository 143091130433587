define('mvp-web/helpers/display-int', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.displayInt = displayInt;
  function displayInt(params) {
    return Math.trunc(params[0]);
  }

  exports.default = Ember.Helper.helper(displayInt);
});