define('mvp-web/helpers/field-character-count', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fieldCharacterLength = fieldCharacterLength;
  exports.fieldCharacterMaxLength = fieldCharacterMaxLength;


  function _totalNewLine(text) {
    return text.split('\n').length - 1;
  }

  /**
   * Get the total character count in a field excluding new lines
   * @param {*} text
   * @returns {number}
   */
  function fieldCharacterLength(text) {
    var totalNewLine = _totalNewLine(text);

    return text.length - totalNewLine;
  }

  /**
   * Get the maximum input limit including new lines
   * @param {*} text
   * @param {*} maxLimit
   * @returns {number}
   */
  function fieldCharacterMaxLength(text, maxLimit) {
    var totalNewLine = _totalNewLine(text);

    return maxLimit + totalNewLine;
  }

  exports.default = Ember.Helper.helper(fieldCharacterLength);
});