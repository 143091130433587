define('mvp-web/initializers/rollbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'rollbar',
    initialize: function initialize() {
      var errorLogger = Ember.Logger.error;
      Ember.Logger.error = function () {
        var args = Array.prototype.slice.call(arguments),
            err = isError(args[0]) ? args[0] : new Error(stringify(args));

        if (window.Rollbar) {
          Rollbar.error.call(Rollbar, err);
        }
        errorLogger.apply(this, arguments);
      };
      var warnLogger = Ember.Logger.warn;
      Ember.Logger.warn = function () {
        if (window.Rollbar) {
          Rollbar.warning.apply(Rollbar, arguments);
        }
        warnLogger.apply(this, arguments);
      };
      var infoLogger = Ember.Logger.info;
      Ember.Logger.info = function () {
        if (window.Rollbar) {
          Rollbar.info.apply(Rollbar, arguments);
        }
        infoLogger.apply(this, arguments);
      };
      var debugLogger = Ember.Logger.debug;
      Ember.Logger.debug = function () {
        if (window.Rollbar) {
          Rollbar.debug.apply(Rollbar, arguments);
        }
        debugLogger.apply(this, arguments);
      };
    }
  };


  var stringify = function stringify(object) {
    return JSON ? JSON.stringify(object) : object.toString();
  };

  var isError = function isError(object) {
    return Ember.typeOf(object) === 'error';
  };
});