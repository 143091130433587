define('mvp-web/components/phone-numbers/add-phone-number/add-new-phone-number', ['exports', 'mvp-web/mixins/active-components', 'mvp-web/mixins/active-steps'], function (exports, _activeComponents, _activeSteps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend(_activeComponents.default, _activeSteps.default, {
    notifications: service('notification-messages'),
    store: service(),

    areaCode: '',
    availableNumbers: [],
    components: ['select-phone-number', 'checkout'],
    currentComponent: 'select-phone-number',
    phoneNumber: null,
    phoneNumbersToBuy: [],
    steps: [{
      number: 1,
      title: 'Select Number',
      status: 'active'
    }, {
      number: 2,
      title: 'Checkout',
      status: null
    }],

    checkoutActive: computed.equal('currentComponent', 'checkout'),

    selectPhoneNumberActive: computed.equal('currentComponent', 'select-phone-number'),

    _createPhoneNumber: function _createPhoneNumber(number) {
      var store = this.get('store');
      var phoneNumber = this.get('phoneNumber');
      var existingAttrs = {};

      if (phoneNumber) {
        existingAttrs = phoneNumber.toJSON();
        phoneNumber.destroyRecord();
      }
      var newPhoneNumber = store.createRecord('phone-number', _extends({}, existingAttrs, { number: number }));
      this.set('phoneNumber', newPhoneNumber);
    },
    _purchasingNumbersCompleted: function _purchasingNumbersCompleted() {
      this.sendAction('purchasingNumbersCompleted');

      this.get('notifications').success('<h6>Purchase Complete<p>New phone number added</p></h6>', {
        htmlContent: true,
        cssClasses: 'phone-number-notification success'
      });

      this._resetToBeginning();
    },
    _resetToBeginning: function _resetToBeginning() {
      this.set('areaCode', '');
      this.set('availableNumbers', []);
      this.set('currentComponent', 'select-phone-number');
      this.set('phoneNumber', null);
      this.set('phoneNumbersToBuy', []);
      this._setActiveStep(1);
    },


    actions: {
      backToSelectOptions: function backToSelectOptions() {
        this.sendAction('setActiveComponent', 'select-an-option');
      },
      backToSelectPhone: function backToSelectPhone() {
        this._setActiveStep(1);
        this._setActiveComponent('select-phone-number');
      },
      createPhoneNumber: function createPhoneNumber(number) {
        this._createPhoneNumber(number);
      },
      destroyPhoneNumber: function destroyPhoneNumber() {
        var phoneNumber = this.get('phoneNumber');
        if (phoneNumber) {
          phoneNumber.destroyRecord();
          this.set('phoneNumber', null);
        }
      },
      proceedToCheckout: function proceedToCheckout() {
        var phoneNumber = this.get('phoneNumber');
        this.set('phoneNumbersToBuy', [phoneNumber]);

        this._setActiveStep(2);
        this._setActiveComponent('checkout');
      },
      purchasingNumbersCompleted: function purchasingNumbersCompleted() {
        this._purchasingNumbersCompleted();
      },
      setAvailableNumbers: function setAvailableNumbers(availableNumbers) {
        this.set('availableNumbers', availableNumbers);
      }
    }
  });
});