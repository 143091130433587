define('mvp-web/components/fuzzy-time', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    timestamp: null,
    placement: 'none',
    alwaysFuture: false,

    fuzzyTimeDescription: computed('timestamp', function () {
      if (!this.get('timestamp') || ['Today', 'Yesterday'].includes(this.get('timestamp'))) return this.get('timestamp');

      // Backend will execute workflow actions as soon as possible after their scheduled time, but there is a window of
      // about 5 minutes for them to act. When using this component there, we will set alwaysFuture to instruct this to
      // show "in a few minutes" instead of "a few minutes ago" when it has gone past the scheduled time without yet executing.
      var timestamp = (0, _moment.default)(this.get('timestamp'));
      if (this.get('alwaysFuture') && timestamp.isBefore()) {
        return 'in a few minutes';
      }
      return timestamp.fromNow();
    })
  });
});