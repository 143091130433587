define('mvp-web/components/quote-templates-forms/specialty', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    store: service(),
    captiveInsuranceCarriers: computed(function () {
      return this.get('store').query('insurance-carrier', { captive: 'true' });
    }),

    policyTypes: computed.alias('staticData.staticData.specialtyQuotePolicyTypes'),

    paymentSelections: computed.filterBy('paymentOptions', 'appliesForSpecialty'),

    selectedPolicyType: computed('policyTypes', 'template.specialtyPolicyTypeId', function () {
      var policyTypes = this.get('policyTypes');

      if (policyTypes) return policyTypes[this.get('template.specialtyPolicyTypeId') - 1];

      return null;
    }),

    selectedPaymentOption: computed('paymentOptions', 'template.paymentOptionId', function () {
      var _this = this;

      var paymentOptions = this.get('paymentOptions');

      if (paymentOptions) {
        return paymentOptions.toArray().find(function (paymentOption) {
          return parseInt(paymentOption.get('id')) === parseInt(_this.get('template.paymentOptionId'));
        });
      }

      return null;
    }),

    selectedPaymentTerm: computed('paymentTerms', 'template.insuranceCoverageOptionId', function () {
      var _this2 = this;

      var paymentTerms = this.get('paymentTerms');

      if (paymentTerms) {
        return paymentTerms.toArray().find(function (paymentTerm) {
          return parseInt(paymentTerm.get('id')) === parseInt(_this2.get('template.insuranceCoverageOptionId'));
        });
      }

      return null;
    }),

    actions: {
      selectPolicyType: function selectPolicyType(selectedOption) {
        var specialtyPolicyTypeId = null;
        if (this.get('policyTypes')) specialtyPolicyTypeId = this.get('policyTypes').indexOf(selectedOption) + 1;
        this.get('template').set('specialtyPolicyTypeId', specialtyPolicyTypeId);
      },
      selectPaymentTerm: function selectPaymentTerm(selectedOption) {
        var option = null;
        if (selectedOption) option = parseInt(selectedOption.id);
        this.get('template').set('insuranceCoverageOptionId', option);
      },
      selectPaymentOption: function selectPaymentOption(selectedOption) {
        var option = null;
        if (selectedOption) option = parseInt(selectedOption.id);
        this.get('template').set('paymentOptionId', option);
      }
    }
  });
});