define('mvp-web/components/group-organization-transfer-dashboard/group-organization-transfer-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    session: service(),

    columnCount: 14,

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      },
      setRefundRequestLeadId: function setRefundRequestLeadId(exclusiveLeadId) {
        this.sendAction('setRefundRequestLeadId', exclusiveLeadId);
      }
    }
  });
});