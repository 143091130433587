define('mvp-web/validations/at-workflow-xdate-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: [(0, _validators.validatePresence)(true)],
    atWorkflowActions: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 1 })],
    'additionalOptions.renewal_date_types': [(0, _validators.validatePresence)(true)],
    'additionalOptions.renewal_date_target_party': [(0, _validators.validatePresence)(true)],
    'additionalOptions.delay_1_year_premium_difference': [(0, _validators.validateNumber)({ allowBlank: true, positive: true, gte: 0 })],
    'additionalOptions.delay_2_year_premium_difference': [(0, _validators.validateNumber)({ allowBlank: true, positive: true, gte: 0 })]
  };
});