define('mvp-web/models/vehicle-quote-template', ['exports', 'ember-data/attr', 'mvp-web/models/vehicle-quote', 'mvp-web/utils/magic-ids'], function (exports, _attr, _vehicleQuote, _magicIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      getProperties = Ember.getProperties;
  exports.default = _vehicleQuote.default.extend({
    // Payment Options defaults
    oneTimeFee: (0, _attr.default)('number'),
    nextPaymentDue: (0, _attr.default)('utc'),
    paymentOptionId: (0, _attr.default)('number'),

    // Coverage defaults
    collisionCoverage: (0, _attr.default)('number'),
    comprehensiveCoverage: (0, _attr.default)('number'),
    towingCoverage: (0, _attr.default)(),
    rentalCarCoverage: (0, _attr.default)(),

    endorsements: (0, _attr.default)('string'),
    showAnnualizedPremium: (0, _attr.default)('boolean'),

    selectedPaymentOption: computed('paymentOptionId', function () {
      var paymentOptionId = this.get('paymentOptionId');
      return paymentOptionId ? this.get('store').findRecord('payment-option', paymentOptionId) : null;
    }),

    defaultAttrs: computed(function () {
      return this._allowedAttrs(this.toJSON());
    }),

    paymentOptionAttrs: function paymentOptionAttrs() {
      return {
        isDefault: true,
        oneTimeFee: this.get('oneTimeFee'),
        nextPaymentDue: this.get('nextPaymentDue'),
        paymentOptionId: this.get('paymentOptionId') || _magicIds.default.payInFullId
      };
    },
    coverageAttrs: function coverageAttrs() {
      return getProperties(this, 'collisionCoverage', 'comprehensiveCoverage', 'rentalCarCoverage', 'towingCoverage');
    },
    _allowedAttrs: function _allowedAttrs(attrs) {
      return getProperties(attrs, 'billingDate', 'insuranceCarrierId', 'paymentTermId', 'policyEffectiveDate', 'policyEffectiveDateicyRenewalDate', 'policyTypeId', 'userDefinedCarrierData', 'selectedInsuranceCarrier', 'bodilyInjuryLiabilityCoverageId', 'medicalProtectionCoverageId', 'personalInjuryProtectionCoverageId', 'propertyDamageCoverageId', 'umUimLiabilityCoverageId', 'umUimPropertyDamageCoverageId', 'endorsements', 'showAnnualizedPremium');
    }
  });
});