define('mvp-web/helpers/join-as-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.joinAsString = joinAsString;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  /**
   * Combine separated strings into a single string
   * @param params
   * @returns {string}
   */

  function joinAsString(params) {
    var _params = _toArray(params),
        joinOperator = _params[0],
        words = _params.slice(1);

    return words.join(joinOperator);
  }

  exports.default = Ember.Helper.helper(joinAsString);
});