define('mvp-web/routes/workflows/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    model: function model(params) {
      var type = void 0;

      switch (params.type) {
        case 'automatic':
          type = 'API';
          break;
        case 'xdate':
          type = 'XDate';
          break;
        default:
          type = 'Custom';
          break;
      }

      return {
        name: undefined,
        atWorkflowActions: [],
        startingWorkflowActionId: undefined,
        assignAutomatically: params.type === 'automatic',
        type: type,
        includeStopMessaging: true,
        atWorkflowConditions: [],
        isNew: true,
        roundRobinType: 'users',
        allowEmailOnly: false
      };
    },
    setupController: function setupController(controller, model) {
      controller.set('atWorkflow', model);
      controller.setProperties({
        model: model
      });
    }
  });
});