define('mvp-web/components/test-email-footer-button', ['exports', 'rollbar'], function (exports, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',
    isEmailFooter: true,

    testEmailTypeText: computed('isEmailFooter', function () {
      if (this.get('isEmailFooter')) return 'Test Email Footer';

      return 'Test Email Signature';
    }),

    actions: {
      displayTestEmailFooter: function displayTestEmailFooter() {
        var _this = this;

        try {
          var model = this.get('model');
          if (this.get('isEmailFooter')) {
            model.set('testEmailFooterText', this.get('testEmailText'));
          } else {
            model.set('testEmailSignatureText', this.get('testEmailText'));
          }

          model.save().then(function () {
            _this.set('testEmailFooterDisplayed', true);
            Ember.$('#test-email-footer-modal').modal({
              backdrop: 'static',
              keyboard: false
            });
            Ember.$('#test-email-footer-modal').modal('show');
          });
        } catch (error) {
          _rollbar.default.error('Test Email Footer: ' + error);
        }
      },
      closeModal: function closeModal() {
        this.set('testEmailFooterDisplayed', false);
      }
    }
  });
});