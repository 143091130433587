define('mvp-web/controllers/customers/pipelined', ['exports', 'mvp-web/controllers/customers/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  exports.default = _index.default.extend({
    pipelined: true,
    includeCalled: true,
    status: null,
    queryParams: ['pipelined', 'includeCalled'],

    actions: {
      refreshModel: function refreshModel() {
        var route = getOwner(this).lookup('route:' + this.get('modelName').pluralize() + '.pipelined');
        return route.refresh();
      }
    }
  });
});