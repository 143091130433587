define('mvp-web/components/phone-numbers/add-phone-number', ['exports', 'mvp-web/mixins/active-components'], function (exports, _activeComponents) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_activeComponents.default, {
    components: ['select-an-option', 'add-new-phone-number'],
    currentComponent: 'select-an-option',

    addNewPhoneNumberActive: computed.equal('currentComponent', 'add-new-phone-number'),
    selectAnOptionActive: computed.equal('currentComponent', 'select-an-option'),

    actions: {
      purchasingNumbersCompleted: function purchasingNumbersCompleted() {
        this.sendAction('refreshModel');
        this.closeModal();
        this._setActiveComponent('select-an-option');
      }
    }
  });
});