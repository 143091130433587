define('mvp-web/components/mvp-button-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['d-inline-block'],
    size: 'md',
    background: 'grey',
    role: 'button-group',

    buttonGroupClass: computed('size', function () {
      var buttonClass = 'btn-group mvp-btn-group w-100';

      if (this.get('size')) buttonClass += ' mvp-btn-group-' + this.get('size');

      if (this.get('background')) buttonClass += ' mvp-btn-group-bg-' + this.get('background');

      return buttonClass;
    })
  });
});