define('mvp-web/components/sequence-page-numbers', ['exports', 'mvp-web/mixins/page-number'], function (exports, _pageNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend(_pageNumber.default, {
    notifications: service('notification-messages'),
    span: 3, // num of range to show

    // count computed property from mixin
    actions: {
      previous: function previous(pageNumber) {
        if (!this.get('model.meta.pagination.prev.number')) return;
        this.get('setPage').perform(pageNumber);
      },
      setCustomPage: function setCustomPage(pageNumber) {
        if (pageNumber === this.get('model.meta.pagination.self.number')) return;
        this.get('setPage').perform(pageNumber);
      },
      next: function next(pageNumber) {
        if (!this.get('model.meta.pagination.next.number')) return;
        this.get('setPage').perform(pageNumber);
      }
    }
  });
});