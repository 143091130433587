define('mvp-web/models/feature-flag', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    isEnabled: (0, _attr.default)('boolean')
  });
});