define('mvp-web/transforms/object', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function deserialize(value) {
      if (!Ember.$.isPlainObject(value)) {
        return {};
      }
      return JSON.parse(JSON.stringify(value));
    },
    serialize: function serialize(value) {
      if (!Ember.$.isPlainObject(value)) {
        return {};
      }
      return JSON.parse(JSON.stringify(value));
    }
  });
});