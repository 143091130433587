define('mvp-web/helpers/format-phone', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPhone = formatPhone;
  exports.formatPhoneDasherized = formatPhoneDasherized;
  exports.formatPartialPhoneDasherized = formatPartialPhoneDasherized;
  exports.dasherizedToRawUS = dasherizedToRawUS;
  exports.dasherizedToRaw = dasherizedToRaw;
  exports.validatePhoneDasherized = validatePhoneDasherized;
  exports.validateRawUsPhone = validateRawUsPhone;


  /**
   * Format phone numbers into friendly format
   * @param params
   * @returns {string}
   */

  function formatPhone(params) {
    var phone = params[0] || '';

    // credit: http://liljosh.com/javascript-format-phone-number-function/
    phone = phone.replace(/[^0-9]/g, '');
    phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return phone;
  }

  function formatPhoneDasherized(phoneNumber) {
    var formattedPhoneNumber = '' + phoneNumber;
    formattedPhoneNumber = formattedPhoneNumber.replace(/^\+[0-9][-]?/, '');
    formattedPhoneNumber = formattedPhoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');

    return formattedPhoneNumber;
  }

  function formatPartialPhoneDasherized(phoneNumber) {
    var formattedPhoneNumber = '' + phoneNumber;
    formattedPhoneNumber = formattedPhoneNumber.replace(/\D/g, '');
    var characters = formattedPhoneNumber.length;
    if (characters > 6) {
      formattedPhoneNumber = formattedPhoneNumber.replace(/(\d{3})(\d{3})(\d{1,4})/, '($1) $2-$3');
    } else if (characters > 3) {
      formattedPhoneNumber = formattedPhoneNumber.replace(/(\d{3})(\d{1,3})/, '($1) $2');
    }

    return formattedPhoneNumber;
  }

  function dasherizedToRawUS(phoneNumber) {
    return phoneNumber.replace(/^(\d{3})-(\d{3})-(\d{4})$/, '+1$1$2$3');
  }

  function dasherizedToRaw(phoneNumber) {
    return phoneNumber.replace(/\D/g, '');
  }

  function validatePhoneDasherized(phoneNumber) {
    return (/^\((\d{3})\) (\d{3})-(\d{4})$/.test(phoneNumber)
    );
  }

  function validateRawUsPhone(phoneNumber) {
    var formattedPhoneNumber = formatPhoneDasherized(phoneNumber);
    return (/^(\d{3})-(\d{3})-(\d{4})$/.test(formattedPhoneNumber)
    );
  }

  exports.default = Ember.Helper.helper(formatPhone);
});