define('mvp-web/components/group-calls', ['exports', 'mvp-web/mixins/power-select-highlighted', 'mvp-web/mixins/errors'], function (exports, _powerSelectHighlighted, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend(_errors.default, _powerSelectHighlighted.default, {
    notifications: service('notification-messages'),
    session: service(),
    currentUser: computed.alias('session.currentUser'),

    phoneCallStates: computed(function () {
      return ['scheduled', 'answered', 'unanswered'];
    }),

    sortedPhoneCallsToday: computed('group.phoneCallsToday', function () {
      return this.get('group.phoneCallsToday').sort(function (a, b) {
        return b.get('scheduledAt').isBefore(a.get('scheduledAt'));
      });
    }),

    actions: {
      selectMember: function selectMember(model, member) {
        model.set('member', member);
      },
      selectPhoneCallState: function selectPhoneCallState(model, selectedOption) {
        model.set('state', selectedOption);
      }
    }
  });
});