define('mvp-web/components/claims-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({

    claimsToDisplay: computed.filterBy('claims', 'shouldDisplay')

  });
});