define('mvp-web/serializers/existing-policy', ['exports', 'mvp-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return this._super('existing-policy');
    }
  });
});