define('mvp-web/components/header/redeployed-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['redeployed', 'p-2']

  });
});