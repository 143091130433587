define('mvp-web/routes/imports/history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend({
    session: service(),
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    model: function model() {
      this.store.adapterFor('leadsImport').set('organizationId', this.get('session.session.authenticated.organization_id'));

      // all this trouble so that latest record will always go to top of table
      // credit: http://stackoverflow.com/a/29282486/499700
      return this.store.findAll('leadsImport').then(function (data) {
        return Ember.ArrayProxy.extend({
          arrangedContent: Ember.computed.sort('content', 'props'),
          props: ['createdAt:desc']
        }).create({ content: data });
      });
    }
  });
});