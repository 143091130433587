define('mvp-web/mixins/quote-copyable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    // This method is to replace the clone method in modify-model
    // We would like to eventually remove that whole file
    duplicate: function duplicate() {
      var _this = this;

      var overrideQuoteAttrs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var overrideRelationshipAttributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var model = this;
      var attrs = model.toJSON();
      var root = model._internalModel.modelName;
      var hasManyRelationships = {};

      model.eachRelationship(function (key, relationship) {
        // Set the belongsTo relations in the attrs to hold the real thing
        if (relationship.kind === 'belongsTo') {
          attrs[key] = model.get(key);
        }

        // Pull hasMany relations to a separate hash
        if (relationship.kind === 'hasMany') {
          var hasManyModel = model.get(key);

          hasManyRelationships[key] = [];
          hasManyModel.forEach(function (object) {
            hasManyRelationships[key].pushObject(object);
          });

          delete attrs[key];
        }
      });

      var finalAttrs = _extends({}, attrs, overrideQuoteAttrs);
      var clonedModel = this.store.createRecord(root, finalAttrs);

      // Create duplicates for hasMany relations

      var _loop = function _loop(relationship) {
        hasManyRelationships[relationship].forEach(function (object) {
          var root = object._internalModel.modelName;
          var childAttrs = object.toJSON();

          object.eachRelationship(function (key, relationship) {
            if (relationship.kind === 'belongsTo') {
              childAttrs[key] = object.get(key);
            }
          });

          var finalChildAttrs = _extends({}, childAttrs, { quote: clonedModel }, overrideRelationshipAttributes[relationship] || {});
          var clonedChild = _this.store.createRecord(root, finalChildAttrs);

          clonedModel.get(relationship).addObject(clonedChild);
        });
      };

      for (var relationship in hasManyRelationships) {
        _loop(relationship);
      }

      return clonedModel;
    }
  });
});