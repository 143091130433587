define('mvp-web/models/stalled-quote', ['exports', 'mvp-web/models/all-quote'], function (exports, _allQuote) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _allQuote.default.extend({
    selectCheckboxId: computed('id', function () {
      return 'select-checkbox-' + this.get('id');
    })
  });
});