define('mvp-web/services/duplicate-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Service = Ember.Service,
      run = Ember.run;
  exports.default = Service.extend({
    session: service(),
    websocket: service(),
    consumer: computed.alias('websocket.consumer'),
    currentUser: computed.alias('session.currentUser'),

    initialSessionId: null,
    isSingleUserSubscription: computed.alias('session.currentUserOrganization.subscriptionPlan.singleUser'),
    isMultipleSignInDetected: false,
    isToBeLoggedOut: computed.and('isSingleUserSubscription', 'isMultipleSignInDetected'),

    checkForDuplicateSession: function checkForDuplicateSession() {
      var consumer = this.get('consumer');
      var currentUser = this.get('currentUser');
      var self = this;

      if (!currentUser) return;

      currentUser.then(function (record) {
        consumer.subscriptions.create('LoginCheckChannel', {
          connected: function connected() {
            self.set('initialSessionId', null);
            this.perform('check_login_details');
          },
          received: function received(data) {
            if (self.get('initialSessionId') == null) {
              self.set('initialSessionId', data);
            }

            self.set('isMultipleSignInDetected', data !== self.get('initialSessionId'));

            if (self.get('isToBeLoggedOut')) {
              run(function () {
                Ember.$('#logout-warning-modal').modal({
                  backdrop: 'static',
                  keyboard: false,
                  show: true
                });
                Ember.$('#logout-warning-modal').modal('show');
              });
            }
          }
        });
      });
    }
  });
});