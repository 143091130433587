define('mvp-web/validations/update-password-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    oldPassword: [(0, _validators.validatePresence)(true)],
    newPassword: [(0, _validators.validatePresence)(true)],
    newPasswordConfirmation: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({ on: 'newPassword', message: 'New password confirmation does not match new password.' })]
  };
});