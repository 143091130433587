define('mvp-web/adapters/holiday', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    getHolidays: function getHolidays() {
      var url = this.buildURL('holiday');
      return this.ajax(url, 'GET');
    }
  });
});