define('mvp-web/initializers/modify-model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var alreadyRun = false;

  exports.default = {
    name: 'modify-model',
    initialize: function initialize() {
      if (alreadyRun) {
        return;
      }
      alreadyRun = true;

      _emberData.default.Model.reopen({
        cloneAttributes: function cloneAttributes(targetModel) {
          var overrideAttrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
          var overrideRelationshipAttributes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
          var childrenForTargetModel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

          var model = this;
          var sourceAttrs = model.toJSON();
          var keysToIgnore = childrenForTargetModel.slice(0);
          var newAttrs = _extends({}, sourceAttrs, overrideAttrs, overrideRelationshipAttributes);

          keysToIgnore.push('id');
          keysToIgnore.forEach(function (key) {
            delete newAttrs[key];
          });

          targetModel.setProperties(newAttrs);

          this.eachRelationship(function (key, relationship) {
            if (relationship.kind === 'belongsTo') {
              // keep certain attr references on target model instead of copying from source model
              if (childrenForTargetModel.indexOf(key) >= 0) {
                targetModel.set(key, targetModel.get(key));
              } else {
                targetModel.set(key, model.get(key));
              }
            }

            if (relationship.kind === 'hasMany') {
              var hasManyModels = model.get(key);
              var i = 0;

              hasManyModels.forEach(function (hasManyModel) {
                var mAttrs = hasManyModel.toJSON();
                var destM = targetModel.get(key).objectAt(i);
                delete mAttrs.id;

                if (hasManyModel) {
                  if (destM) {
                    hasManyModel.cloneAttributes(destM, mAttrs, childrenForTargetModel);
                  } else {
                    var clonedModel = hasManyModel.clone({}, true);
                    targetModel.get(key).pushObject(clonedModel);
                  }
                }
                i++;
              });
            }
          });

          return targetModel;
        },
        clone: function clone(overrideQuoteAttrs) {
          var overrideRelationshipAttributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
          var copyHasManyRelations = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

          var model = this;
          var attrs = model.toJSON();
          var classType = model.constructor;
          var root = Ember.String.decamelize(classType.toString().split(':')[1]);
          var overrideAttrs = _extends({}, overrideObjects, overrideRelationshipAttributes);
          var hasManyObjects = {};
          var overrideObjects = {};

          /*
           * Create new objects or references for the new model to associate with
           */
          this.eachRelationship(function (key, relationship) {
            if (relationship.kind === 'belongsTo') {
              attrs[key] = model.get(key);
            }

            if (copyHasManyRelations) {
              if (relationship.kind === 'hasMany') {
                var hasManyModel = model.get(key);
                delete attrs[key];

                if (hasManyModel.get('content').length > 0) {
                  hasManyObjects[key] = [];

                  hasManyModel.forEach(function (object) {
                    hasManyObjects[key].pushObject(object);
                  });
                }
              }
            }
          });

          /*
           * Need to dissociate the new record from the old.
           */
          delete attrs.id;

          /*
           * Apply overrideAttrs if provided.
           */
          if (Ember.typeOf(overrideAttrs) === 'object') {
            for (var key in overrideAttrs) {
              if (overrideAttrs && overrideAttrs.hasOwnProperty(key) && Ember.typeOf(overrideAttrs[key]) === 'object') {
                overrideObjects[key] = overrideAttrs[key];
                delete overrideAttrs[key];
              }
            }

            Ember.setProperties(attrs, overrideAttrs);
          }

          var clonedModel = this.store.createRecord(root, attrs);

          /*
           * Create hasMany relations
           */
          if (copyHasManyRelations) {
            var _loop = function _loop(k) {
              if (hasManyObjects && hasManyObjects.hasOwnProperty(k)) {
                hasManyObjects[k].forEach(function (object) {
                  var deepClonedObject = object.clone(overrideObjects[k]);
                  clonedModel.get(k).addObject(deepClonedObject);
                });
              }
            };

            for (var k in hasManyObjects) {
              _loop(k);
            }
          }

          return clonedModel;
        },


        /*
         * This is necessary because certain models are being saved as nested resources to do away with unnecessary
         * API calls. As a side affect, Ember has no idea that a new resource was created, leaving the old resource
         * around, which shows up on the UI as a duplicate element which will confuse the user.
         * */
        purgeNestedModels: function purgeNestedModels() {
          var model = this;

          this.eachRelationship(function (key, relationship) {
            var nestedModel = model.get(key + '.content');

            if (relationship.kind === 'hasMany' && nestedModel.length > 0) {
              var modelsToDelete = [];
              var modelsToIterate = [];

              // NOTE: This is a two-step process because we need to get a handle of the records to be deleted before we lose the reference
              nestedModel.filter(function (m) {
                if (m.get('isNew')) {
                  modelsToDelete.push(m);
                } else {
                  modelsToIterate.push(m);
                }
              });

              modelsToDelete.forEach(function (m) {
                m.purgeNestedModels();
                m.deleteRecord();
              });

              modelsToIterate.forEach(function (m) {
                m.purgeNestedModels();
              });
            }
          });

          return model;
        }
      });
    }
  };
});