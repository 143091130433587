define('mvp-web/helpers/selection-name-label', ['exports', 'lodash/collection'], function (exports, _collection2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.selectionNameLabel = selectionNameLabel;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * returns string label given an array selection & selected option
   * @param params {array}, {object} or {string}, {string}
   * @returns {object} or {string}
   */

  function selectionNameLabel(params) {
    var _params = _slicedToArray(params, 3),
        selectionList = _params[0],
        selection = _params[1],
        selectionLabel = _params[2];

    var match = null;
    var label = selectionLabel || null;

    if (selectionList && selectionList.constructor === Array) {
      match = _collection2.default.find(selectionList, function (selectionObject) {
        return selection && (selectionObject.id === selection || selectionObject.code === selection || selectionObject.name === selection);
      });
    } else if (selectionList && selectionList.constructor === Object) {
      match = selectionList;
    }

    return match && label ? match[label] : match;
  }

  exports.default = Ember.Helper.helper(selectionNameLabel);
});