define('mvp-web/models/phone-number-actions/voicemail-action', ['exports', 'ember-data/attr', 'ember-cp-validations', 'ember-data/relationships', 'mvp-web/models/phone-number-action'], function (exports, _attr, _emberCpValidations, _relationships, _phoneNumberAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    type: [(0, _emberCpValidations.validator)('inclusion', {
      in: ['PhoneNumberActions::VoicemailAction']
    })],
    userIds: [(0, _emberCpValidations.validator)('length', {
      min: 1
    })],
    'options.voicemail_recording_id': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Voicemail Message'
    })]
  });

  exports.default = _phoneNumberAction.default.extend(Validations, {
    userIds: (0, _attr.default)(),
    users: (0, _relationships.hasMany)('user')
  });
});