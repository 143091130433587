define('mvp-web/controllers/customers/edit', ['exports', 'mvp-web/controllers/groups/edit'], function (exports, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _edit.default.extend({
    detailsPath: 'customers.edit',
    transitionStateIsProspect: false,
    transitionStateIsCustomer: true
  });
});