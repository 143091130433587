define('mvp-web/components/quote-forms/life', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var service = Ember.inject.service,
      run = Ember.run,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),
    segment: service(),
    session: service(),

    actions: {
      addMember: function addMember() {
        var memberAttrs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var store = this.get('store');

        memberAttrs = _extends({ isPrimaryMember: false, isQuoteOriginMember: false }, memberAttrs);
        var newMember = store.createRecord('member', memberAttrs);
        this.get('members').pushObject(newMember);

        run.scheduleOnce('afterRender', function () {
          Ember.$('.member-info table').find('tr:last td button.trigger-btn').trigger('click');
        });

        this.get('segment').trackEvent('Added member to group.', {
          groupId: this.get('groupId'),
          userId: this.get('session.currentUser.id')
        });
      }
    }
  });
});