define('mvp-web/components/quote-forms/commercial/third-party-quote', ['exports', 'mvp-web/mixins/power-select-highlighted', 'mvp-web/utils/magic-ids'], function (exports, _powerSelectHighlighted, _magicIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    store: service(),
    magicIds: _magicIds.default,

    insuranceCarriers: computed(function () {
      return this.get('store').query('insurance-carrier', { for: 'commercial' });
    }),

    carrierLabel: computed(function () {
      return this.get('group.isCustomer') ? 'Prior Carrier' : 'Current Carrier';
    }),

    actions: {
      attachmentUploaded: function attachmentUploaded(uploadedFile) {
        this.get('quote').setAttachment(uploadedFile);
      },
      removeAttachment: function removeAttachment(e) {
        this.get('quote').removeAttachment();
      }
    }
  });
});