define('mvp-web/services/exclusive-dashboard-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    order: [{ label: 'Newest Leads First', value: 'desc' }, { label: 'Oldest Leads First', value: 'asc' }]
  });
});