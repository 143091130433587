define('mvp-web/services/session', ['exports', 'ember-simple-auth/services/session'], function (exports, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = _session.default.extend({
    store: service(),

    currentUser: computed('isAuthenticated', function () {
      if (this.get('isAuthenticated')) {
        return this.get('store').findRecord('user', this.get('session.authenticated.id'));
      }
    }),

    currentUserOrganization: computed('isAuthenticated', function () {
      if (this.get('isAuthenticated')) {
        var organizationId = this.get('session.authenticated.organization_id');
        return this.get('store').findRecord('organization', organizationId);
      }
    })
  });
});