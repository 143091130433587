define('mvp-web/components/messages-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      observer = Ember.observer;
  exports.default = Ember.Component.extend({
    store: service(),

    classNames: ['chat-window-conversation'],
    classNameBindings: ['isDropping:drop'],

    firstMessagesLoad: true,
    isScrolledToBottom: true,
    scrollIntervalId: null,

    init: function init() {
      this._super.apply(this, arguments);
      this._loadMessages();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.get('isScrolledToBottom')) {
        this._scrollToBottom();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._stopPollingForScroll();
    },


    messagesDidChange: observer('messages.[]', function () {
      if (this.get('firstMessagesLoad')) {
        this._startPollingForScroll();
        this.set('firstMessagesLoad', false);
      }
    }),

    _loadMessages: function _loadMessages() {
      this.get('messages').set('isLoading', true);
      var options = {
        chatRoomId: this.get('thread.id'),
        page: this.get('messages.nextPage')
      };
      this.get('store').query('message', options).then(this._messagesDidLoad.bind(this));
    },
    _messagesDidLoad: function _messagesDidLoad(recordArray) {
      var el = this.$()[0];
      el.scrollTop = el.scrollHeight - this.get('previousScrollHeightMinusTop');

      var meta = recordArray.get('meta');
      this.get('messages').setProperties({
        isLoading: false,
        currentPage: meta.current_page,
        prevPage: meta.prev_page,
        nextPage: meta.next_page
      });

      this.get('messages.content').addObjects(recordArray);
    },
    _onScroll: function _onScroll() {
      var e = this.$()[0];
      var isAtBottom = e.scrollHeight - e.scrollTop === e.clientHeight;
      this.set('isScrolledToBottom', isAtBottom);

      if (this.get('messages.isLoading')) {
        return;
      }

      if (this.get('messages.nextPage') === null) {
        return;
      }

      if (e.scrollTop === 0) {
        this.set('previousScrollHeightMinusTop', e.scrollHeight - e.scrollTop);
        this._loadMessages();
      }
    },
    _scrollToBottom: function _scrollToBottom() {
      this.$().scrollTop(9999);
    },
    _startPollingForScroll: function _startPollingForScroll() {
      this.scrollIntervalId = setInterval(this._onScroll.bind(this), 250);
    },
    _stopPollingForScroll: function _stopPollingForScroll() {
      clearInterval(this.scrollIntervalId);
    }
  });
});