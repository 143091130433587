define('mvp-web/components/organization/email-local-part', ['exports', 'mvp-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    notifications: service('notification-messages'),

    domain: computed.alias('staticData.staticData.leadsEmailDomain'),

    fullEmailAddress: computed('apiToken', function () {
      return '' + this.get('apiToken') + this.get('domain');
    }),

    everQuoteIntegrationUrl: computed('apiToken', function () {
      return _environment.default.defaultHost + '/ext/v1/organization/' + this.get('apiToken') + '/ever_quote/api_leads';
    }),

    actions: {
      notifyUser: function notifyUser() {
        this.get('notifications').success('Copied organization internet lead e-mail address to clipboard');
      },
      notifyUserCopiedToken: function notifyUserCopiedToken() {
        this.get('notifications').success('Copied organization token to clipboard');
      }
    }
  });
});