define('mvp-web/models/at-condition', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    atDimensionId: (0, _attr.default)(),
    operator: (0, _attr.default)(),
    value: (0, _attr.default)()

  });
});