define('mvp-web/models/user-setting', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    userId: (0, _attr.default)(),
    settings: (0, _attr.default)()
  });
});