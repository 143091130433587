define('mvp-web/validations/at-workflow-xdate-actions-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    atActionId: [(0, _validators.validatePresence)(true)],
    actionTemplate: [(0, _validators.validatePresence)(true)],
    atActionTimeValue: [(0, _validators.validatePresence)(true)],
    'additionalOptions.send_time': [(0, _validators.validatePresence)(true)]
  };
});