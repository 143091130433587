define('mvp-web/routes/new-leads/index', ['exports', 'mvp-web/routes/leads/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _index.default.extend({
    prepareParams: function prepareParams() {
      return _extends({}, this._super.apply(this, arguments), {
        new_leads: true // eslint-disable-line camelcase
      });
    },
    model: function model(params) {
      var baseParams = this.prepareParams(params);

      return {
        previousRegularTask: this.get('getRegularModel.lastSuccessful'),
        currentRegularTask: this.get('getRegularModel').perform(baseParams),
        currentPriorityTask: this.get('getPriorityModel').perform(baseParams),
        previousPriorityTask: this.get('getPriorityModel.lastSuccessful')
      };
    }
  });
});