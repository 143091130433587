define('mvp-web/components/letter-avatar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    abbreviatedName: computed('firstName', 'lastName', function () {
      return '' + this.get('firstName').charAt(0) + this.get('lastName').charAt(0);
    }),

    hasName: computed.and('firstName', 'lastName')
  });
});