define('mvp-web/models/vehicles-lookup', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    owner: (0, _attr.default)('object'),
    registration: (0, _attr.default)('object'),
    title: (0, _attr.default)('object'),
    lienholder: (0, _attr.default)('object'),

    modelYear: (0, _attr.default)(),
    make: (0, _attr.default)(),
    model: (0, _attr.default)(),

    bodyType: (0, _attr.default)(),
    tonange: (0, _attr.default)(),
    VIN: (0, _attr.default)(),
    classType: (0, _attr.default)(),
    emptyWeight: (0, _attr.default)(),
    grossWeight: (0, _attr.default)(),

    dmvDate: (0, _attr.default)(),

    fullName: computed('modelYear', 'make', 'model', function () {
      return (this.get('modelYear') || '') + ' ' + (this.get('make') || '') + ' ' + (this.get('model') || '');
    }),

    toVehicleAttributes: function toVehicleAttributes() {
      return {
        VIN: this.get('VIN'),
        ownerFullName: this.get('owner.full_name'),
        licensePlate: this.get('registration.plate_number'),
        address: this.get('owner.address'),
        lienholderName: this.get('lienholder.full_name'),
        lienholderAddress: this.get('lienholder.address'),
        customYear: this.get('modelYear'),
        customMake: this.get('make'),
        customModel: this.get('model'),
        dmvDate: this.get('dmvDate'),
        extraData: {
          modelYear: this.get('modelYear'),
          make: this.get('make'),
          model: this.get('model'),
          owner: this.get('owner'),
          registration: this.get('registration'),
          title: this.get('title'),
          lienholder: this.get('lienholder'),
          bodyType: this.get('bodyType'),
          tonange: this.get('tonange'),
          classType: this.get('classType'),
          emptyWeight: this.get('emptyWeight'),
          grossWeight: this.get('grossWeight')
        }
      };
    }
  });
});