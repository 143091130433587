define('mvp-web/models/at-workflow-group-cursor', ['exports', 'ember-data/attr', 'mvp-web/models/at-workflow-histories'], function (exports, _attr, _atWorkflowHistories) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _atWorkflowHistories.default.extend({
    atWorkflowGroupCursorId: (0, _attr.default)(),
    runAt: (0, _attr.default)(),
    finished: (0, _attr.default)('boolean'),
    skip: (0, _attr.default)('boolean'),
    actionTemplate: (0, _attr.default)()
  });
});