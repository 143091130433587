define('mvp-web/components/discover-drivers-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    classNames: ['btn', 'btn-xs', 'discover-drivers-button', 'btn-default'],
    tagName: 'a',

    click: function click() {
      var _this = this;

      this.sendAction('onClick');
      if (this.get('isBrowserOpened')) {
        return;
      }

      var conditions = {
        city: this.get('address.city'),
        postalCode: this.get('address.postalCode'),
        street: this.get('address.street1'),
        usState: this.get('address.usState')
      };

      this.sendAction('setIsLookingForDrivers', true);

      this.get('store').query('driver', { address: conditions }).then(function (drivers) {
        _this.sendAction('action', drivers);
      }).catch(function (e) {
        _this.sendAction('onError');
      }).finally(function () {
        _this.sendAction('setIsLookingForDrivers', false);
      });
    }
  });
});