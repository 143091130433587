define('mvp-web/models/homes-lookup', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    baths: (0, _attr.default)(),
    fireplaces: (0, _attr.default)(),
    parkingSpaces: (0, _attr.default)(),
    storiesNumber: (0, _attr.default)(),
    squareFeet: (0, _attr.default)(),
    buildYear: (0, _attr.default)(),
    hasPool: (0, _attr.default)('boolean'),
    dmvDate: (0, _attr.default)(),
    hasElectricHeating: (0, _attr.default)('boolean'),
    hasGasHeating: (0, _attr.default)('boolean'),
    totalMarketValue: (0, _attr.default)(),

    toHomeAttributes: function toHomeAttributes() {
      return this.getProperties('baths', 'fireplaces', 'parkingSpaces', 'storiesNumber', 'squareFeet', 'buildYear', 'hasPool', 'dmvDate', 'hasElectricHeating', 'hasGasHeating', 'totalMarketValue');
    }
  });
});