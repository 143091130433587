define('mvp-web/components/vehicles-browser-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'tr',

    classNameBindings: ['isDisabled:text-muted'],

    isDisabled: computed('vehicle.VIN', 'vehicles', function () {
      var _this = this;

      var vehicles = this.get('vehicles');
      return vehicles.toArray().some(function (vehicle) {
        return vehicle.get('VIN') === _this.get('vehicle.VIN');
      });
    }),

    actions: {
      convertToVehicle: function convertToVehicle(vehicle) {
        this.sendAction('onConvertToVehicle', vehicle);
        this.toggleProperty('isDisabled');
      }
    }
  });
});