define('mvp-web/components/quote-templates-forms/home', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    store: service(),

    captiveInsuranceCarriers: computed(function () {
      return this.get('store').query('insurance-carrier', { captive: 'true' });
    }),

    amountTypes: [{ name: 'dollar', label: '($) Dollar' }, { name: 'percent', label: '(%) Percent' }],

    actions: {
      selectWindHailAmountType: function selectWindHailAmountType(selectedOption) {
        this.get('template').set('windHailDeductibleAmountType', selectedOption.name);
      },
      selectHurricaneAmountType: function selectHurricaneAmountType(selectedOption) {
        this.get('template').set('hurricaneDeductibleAmountType', selectedOption.name);
      },
      selectAllOtherAmountType: function selectAllOtherAmountType(selectedOption) {
        this.get('template').set('allOtherDeductibleAmountType', selectedOption.name);
      },
      selectEarthquakeAmountType: function selectEarthquakeAmountType(selectedOption) {
        this.get('template').set('earthquakeDeductibleAmountType', selectedOption.name);
      },
      selectSeparateStructuresAmountType: function selectSeparateStructuresAmountType(selectedOption) {
        this.get('template').set('separateStructuresAmountType', selectedOption.name);
      },
      selectPersonalPropertyAmountType: function selectPersonalPropertyAmountType(selectedOption) {
        this.get('template').set('personalPropertyAmountType', selectedOption.name);
      },
      selectLossOfUseAmountType: function selectLossOfUseAmountType(selectedOption) {
        this.get('template').set('lossOfUseAmountType', selectedOption.name);
      },
      selectGuestMedicalCoverage: function selectGuestMedicalCoverage(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('template').set('guestMedicalCoverageId', id);
      },
      selectLivingExpenseTerm: function selectLivingExpenseTerm(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('template').set('livingExpenseTermId', id);
      },
      selectPersonalLiabilityCoverage: function selectPersonalLiabilityCoverage(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('template').set('personalLiabilityCoverageId', id);
      }
    }
  });
});