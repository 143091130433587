define('mvp-web/components/quote-comparison', ['exports', 'lodash/collection', 'mvp-web/config/environment', 'mvp-web/mixins/errors'], function (exports, _collection2, _environment, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      on = Ember.on,
      run = Ember.run,
      Component = Ember.Component,
      RSVP = Ember.RSVP;
  exports.default = Component.extend(_errors.default, {
    notifications: service('notification-messages'),
    organizationSetting: service(),

    hasQuoteComparison: false,
    iframeHeight: 870,
    isIframeLoading: true,
    isSmsTextable: false,
    quoteComparisonId: '',

    loadQuoteComparison: on('init', function () {
      this.send('getQuoteComparison');
    }),

    isMessagingEnabled: computed.alias('organizationSetting.isMessagingEnabled'),

    isTextDisabled: computed('_members.canReceiveMessage', 'model.membersToSendQuoteComparisonsTo', function () {
      return this.get('model.membersToSendQuoteComparisonsTo.length') === 0 || !_collection2.default.some(this.get('model.membersToSendQuoteComparisonsTo'), function (member) {
        return member.canReceiveMessage === true;
      });
    }),
    isEmailDisabled: computed('_members.email', 'model.membersToSendQuoteComparisonsTo', function () {
      return this.get('model.membersToSendQuoteComparisonsTo.length') === 0 || !_collection2.default.some(this.get('model.membersToSendQuoteComparisonsTo'), function (member) {
        return member.hasEmailAddress === true;
      });
    }),
    reachableMembers: computed('_members.mobileNumber', '_members.email', function () {
      return this.get('model.members.content').map(function (member) {
        var mobileNumber = member.get('mobileNumber');
        var validEmail = member.get('validEmail');
        var isDisabled = !mobileNumber && !validEmail;

        return {
          disabled: isDisabled,
          hasMobileNumber: !!mobileNumber,
          hasEmailAddress: validEmail,
          hasSmsConsent: member.get('hasSmsConsent'),
          canReceiveMessage: member.get('canReceiveMessage'),
          id: member.id,
          name: member.get('fullName')
        };
      });
    }),

    _getQuoteComparison: function _getQuoteComparison() {
      var _this2 = this;

      var _this = this;
      var model = this.get('model');

      new RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: _environment.default.apiEndpointVersion + '/groups/' + model.id + '/quote_comparison_broker'
        }).then(function (quoteComparisonBroker) {
          run(function () {
            resolve(quoteComparisonBroker);
            var iframe = document.getElementById('quote-comparisons');
            var url = _environment.default.defaultHost + '/compare/' + quoteComparisonBroker.data.attributes.url_token + '?cachebuster=' + Date.now();
            iframe.setAttribute('height', _this.get('iframeHeight'));
            iframe.src = url;

            _this.setProperties({
              hasQuoteComparison: true,
              isIframeLoading: false,
              quoteComparisonId: quoteComparisonBroker.id
            });
          });
        }, function (xhr, status, error) {
          run(function () {
            _this.get('notifications').error('Quote Comparison error: <div>' + _this2.decodeJsonApiErrors(error) + '</div>', { htmlContent: true });
            reject(xhr.responseJSON);
          });
        });
      });
    },


    _members: computed('members.[]', function () {
      return this.get('model.members');
    }),

    _sendQuoteComparison: function _sendQuoteComparison(medium) {
      var _this3 = this;

      var _this = this;
      var model = this.get('model');
      var memberIds = model.get('membersToSendQuoteComparisonsTo').map(function (member) {
        return member.id;
      });
      var endpoint = medium === 'text' ? 'text_quote_comparison' : 'email_quote_comparison';

      new RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          data: { member_ids: memberIds }, // eslint-disable-line camelcase
          type: 'post',
          url: _environment.default.apiEndpointVersion + '/groups/' + model.id + '/' + endpoint
        }).then(function (response) {
          run(function () {
            resolve(response);
            _this.get('notifications').success('Quote successfully ' + medium + 'ed to prospect');
          });
        }, function (xhr, status, error) {
          run(function () {
            _this.get('notifications').error('There was a problem texting the prospect: <div>' + _this3.decodeJsonApiErrors(error) + '</div>', { htmlContent: true });
            reject();
          });
        });
      });
    },


    actions: {
      getQuoteComparison: function getQuoteComparison() {
        this._getQuoteComparison();
      },
      membersToSendQuoteComparisonsTo: function membersToSendQuoteComparisonsTo(memberIds) {
        this.set('model.membersToSendQuoteComparisonsTo', memberIds);
      },
      textQuoteComparison: function textQuoteComparison() {
        this._sendQuoteComparison('text');
      },
      emailQuoteComparison: function emailQuoteComparison() {
        this._sendQuoteComparison('email');
      },
      copiedComparisonLinkToClipboard: function copiedComparisonLinkToClipboard() {
        this.get('notifications').success('Copied Comparison URL to clipboard');
      }
    }
  });
});