define('mvp-web/components/date-range-picker-reports', ['exports', 'ember-cli-daterangepicker/components/date-range-picker', 'moment'], function (exports, _dateRangePicker, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dateRangePicker.default.reopen({
    ranges: {
      'Last 30 Days': [(0, _moment.default)().subtract(29, 'days'), (0, _moment.default)()],
      'Last 60 Days': [(0, _moment.default)().subtract(59, 'days'), (0, _moment.default)()],
      'Last 90 Days': [(0, _moment.default)().subtract(89, 'days'), (0, _moment.default)()],
      'Last 6 Months': [(0, _moment.default)().subtract(6, 'month').endOf('month'), (0, _moment.default)().subtract(1, 'month').startOf('month')],
      'Last 12 Months': [(0, _moment.default)().subtract(12, 'month').endOf('month'), (0, _moment.default)().subtract(1, 'month').startOf('month')],
      'Year to Date': [(0, _moment.default)().startOf('year'), (0, _moment.default)()],
      'All Time': [(0, _moment.default)({ year: 1970, month: 1, day: 1 }), (0, _moment.default)()] // use start of unix epoch, functionally works but doesn't mess w/ backend
    }
  });
});