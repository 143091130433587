define('mvp-web/adapters/lead', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    requestsToStore: ['/api/v1/leads'], // url
    requests: [],

    ajaxOptions: function ajaxOptions(url, type, hash) {
      var _this = this;

      var ajaxOptions = this._super(url, type, hash);

      if (this.get('requestsToStore').includes(url)) {
        var defaultBeforeSend = function defaultBeforeSend() {};

        if (typeof ajaxOptions.beforeSend === 'function') {
          defaultBeforeSend = ajaxOptions.beforeSend;
        }

        ajaxOptions.beforeSend = function (xhr, settings) {
          defaultBeforeSend(xhr, settings);

          _this.get('requests').push({
            url: url,
            type: type,
            xhr: xhr
          });
        };
      }

      return ajaxOptions;
    },
    cancelRequest: function cancelRequest(url, type) {
      var requests = this.get('requests');

      requests.forEach(function (request) {
        if (request.url === url && request.type === type && request.xhr.readyState !== 4) {
          request.xhr.abort();
        }
      });

      requests = requests.filter(function (request) {
        return request.url !== url;
      });

      this.set('requests', requests);
    }
  });
});