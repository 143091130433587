define('mvp-web/transforms/simple-decimal', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized % 1 > 0) {
        return serialized;
      }
      return parseInt(serialized) || '';
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});