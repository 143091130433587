define('mvp-web/controllers/customers/specialty', ['exports', 'mvp-web/controllers/customers/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  exports.default = _index.default.extend({
    includeCalled: false,
    crossSell: 'specialty',

    actions: {
      refreshModel: function refreshModel() {
        var route = getOwner(this).lookup('route:' + this.get('modelName').pluralize() + '.speciality');
        return route.refresh();
      }
    }
  });
});