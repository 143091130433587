define('mvp-web/services/import-columns', ['exports', 'mvp-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var run = Ember.run,
      RSVP = Ember.RSVP,
      Service = Ember.Service;
  exports.default = Service.extend({
    columns: Ember.Object.create({}),

    loadColumns: function loadColumns() {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: _environment.default.apiEndpointVersion + '/bootstrap_data/import_columns'
        }).then(function (data) {
          run(function () {
            _this.get('columns').setProperties(data);
            resolve(_this.get('columns'));
          });
        }, function (xhr) {
          reject(xhr.responseJSON);
        });
      });
    }
  });
});