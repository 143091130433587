define('mvp-web/components/mvp-cancel-quotes-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // tagName: '',

    cancelQuotesButtonLabel: 'Cancel Quote',

    idDisabled: false,

    actions: {
      displayCancelQuotesModal: function displayCancelQuotesModal() {
        this.sendAction('toggleCancelQuotesModal');
      }
    }
  });
});