define('mvp-web/models/vehicle-claim', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'moment', 'ember-cp-validations'], function (exports, _relationships, _attr, _model, _moment, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isEmpty = Ember.isEmpty;


  var Validations = (0, _emberCpValidations.buildValidations)({
    member: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Driver'
    }),
    claimDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Date of Incident'
    })
  });

  exports.default = _model.default.extend(Validations, {
    claimDate: (0, _attr.default)('utc'),
    isAtFault: (0, _attr.default)('boolean', { defaultValue: false }),
    isDisputed: (0, _attr.default)('boolean', { defaultValue: false }),
    notes: (0, _attr.default)(),
    claimType: (0, _attr.default)({ defaultValue: 'violation' }),
    member: (0, _relationships.belongsTo)('member'),
    vehicle: (0, _relationships.belongsTo)('vehicle'),
    shouldDisplay: true,

    displayLine: Ember.computed('claimDate', 'member', 'isAtFault', function () {
      var faultStatus = this.get('isAtFault') ? 'AF' : 'NAF';
      var claimDate = this.get('claimDate');
      var yearAndMonth = claimDate ? '' + claimDate.format('MM/YYYY, ') : '';
      var displayShortName = this.get('vehicle.shortName') ? this.get('vehicle.shortName') + ', ' : '';
      return '' + yearAndMonth + displayShortName + faultStatus;
    }),

    organizationSetting: null, // transient

    vehicleClaimFallOff: computed.reads('organizationSetting.autoClaimFallOff'),

    claimFallOffDate: computed('organizationSetting', function () {
      var claimDate = this.get('claimDate');
      var vehicleClaimFallOff = this.get('vehicleClaimFallOff');

      return claimDate ? claimDate.clone().add(vehicleClaimFallOff, 'months') : null;
    }),

    claimFallOffLabel: computed('claimFallOffDate', function () {
      var claimFallOffDate = this.get('claimFallOffDate');

      if (isEmpty(claimFallOffDate)) return null;

      return 'Vehicle (' + claimFallOffDate.format('MM/DD/YYYY') + ')';
    }),

    claimFallOffDateDiffFromNow: computed('claimFallOffDate', function () {
      var claimFallOffDate = this.get('claimFallOffDate');

      if (isEmpty(claimFallOffDate)) return null;

      return (0, _moment.default)().diff(this.get('claimFallOffDate'), 'days');
    })
  });
});