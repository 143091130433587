define('mvp-web/utils/address-matcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = closestAddressMatches;
  function closestAddressMatches(store, street1, usState) {
    var adapter = store.adapterFor('address');
    // only use street + state to maximize range and reduce confusion even if zip/city fields are populated
    var payload = { street1: street1, usState: usState };

    return adapter.closestMatches(payload);
  }
});