define('mvp-web/components/groups-tabs/leads', ['exports', 'mvp-web/components/groups-tabs/group-tab'], function (exports, _groupTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _groupTab.default.extend({
    totalLeads: computed('count.totalLeads', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.totalLeads');
    }),

    hotLeads: computed('count.hotLeads', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.hotLeads');
    }),

    newLeads: computed('count.newLeads', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.newLeads');
    }),

    myLeads: computed('count.myLeads', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.myLeads');
    }),

    missedLeadsAppointments: computed('count.missedLeadsAppointments', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.missedLeadsAppointments');
    }),

    pipelinedLeads: computed('count.pipelinedLeads', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.pipelinedLeads');
    }),

    referralLeads: computed('count.referralLeads', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.referralLeads');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // 3 is the count ID for lead stats
      this.get('fetchCount').perform(3);
    }
  });
});