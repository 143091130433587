define('mvp-web/components/test-email-footer', ['exports', 'mvp-web/config/environment', 'mvp-web/mixins/errors'], function (exports, _environment, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      on = Ember.on,
      run = Ember.run,
      Component = Ember.Component;
  exports.default = Component.extend(_errors.default, {
    notifications: service('notification-messages'),

    iframeHeight: 870,
    isIframeLoading: true,

    loadTestEmailFooter: on('init', function () {
      run.scheduleOnce('afterRender', this, function () {
        this.send('getCustomEmailFooterText');
      });
    }),

    _getCustomEmailFooterText: function _getCustomEmailFooterText() {
      var _this = this;
      var model = this.get('model');
      var testRoute = model.constructor.modelName === 'organization' ? 'test-organization-email-footer' : 'test-user-email-signature';

      var iframe = document.getElementById('test-email-footer');
      var url = _environment.default.defaultHost + '/' + testRoute + '/' + model.id;
      iframe.setAttribute('height', _this.get('iframeHeight'));
      iframe.src = url;
    },


    actions: {
      getCustomEmailFooterText: function getCustomEmailFooterText() {
        this._getCustomEmailFooterText();
      },
      iframeIsLoaded: function iframeIsLoaded() {
        this.setProperties({
          isIframeLoading: false
        });
      }
    }
  });
});