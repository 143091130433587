define('mvp-web/components/phone-numbers/edit-phone-number/phone-number-actions/forward-to-phone-number', ['exports', 'mvp-web/helpers/format-phone'], function (exports, _formatPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    forwardNumber: computed.alias('phoneNumberAction.options.to_number'),

    forwardNumberValid: computed('forwardNumber', function () {
      return (0, _formatPhone.validatePhoneDasherized)(this.get('forwardNumber'));
    })
  });
});