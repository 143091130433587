define('mvp-web/transforms/date-without-zone', ['exports', 'ember-data/transform', 'moment'], function (exports, _transform, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    serialize: function serialize(value) {
      return value ? (0, _moment.default)(value).format('YYYY-MM-DD') : null;
    },
    deserialize: function deserialize(value) {
      return value ? (0, _moment.default)(value).format('YYYY-MM-DD') : null;
    }
  });
});