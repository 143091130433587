define('mvp-web/components/quote-forms/shared/insurance-carrier-select', ['exports', 'mvp-web/utils/magic-ids', 'mvp-web/mixins/power-select-highlighted'], function (exports, _magicIds, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    store: service(),
    withValidation: false,
    disabled: false,

    isValid: computed('model.validations.attrs.insuranceCarrierId.isValid', function () {
      if (!this.get('withValidation')) return true;

      return this.get('model.validations.attrs.insuranceCarrierId.isValid');
    }),

    isCustomCarrier: computed.equal('model.insuranceCarrierId', _magicIds.default.otherCarrierId),

    actions: {
      selectInsuranceCarrier: function selectInsuranceCarrier(selectedCarrier) {
        var carrierId = Number(selectedCarrier.id);
        var carrierName = selectedCarrier.get('name');

        this.get('model').setProperties({
          insuranceCarrierId: carrierId,
          selectedInsuranceCarrier: {
            id: carrierId,
            name: carrierName
          },
          userDefinedCarrierData: {
            insuranceCarrierName: null
          }
        });
      }
    }
  });
});