define('mvp-web/components/dashboard/performance-statistics/quote-activity/agent-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;


  function computedAggregateFor(attribute) {
    return computed('groups', 'groups.[]', function () {
      return this.get('groups').reduce(function (acc, currentValue) {
        return acc + parseInt(currentValue.get(attribute));
      }, 0);
    });
  }

  exports.default = Component.extend({
    subrowHidden: true,

    groups: computed('statistics.[]', 'userMapping', function () {
      var userId = this.get('userMapping.id');
      var stats = this.get('statistics');
      return stats.filter(function (stat) {
        return stat.get('userId') == userId;
      });
    }),

    totalQlob: computedAggregateFor('newQlob'),
    totalRequoted: computedAggregateFor('requoted'),
    totalQuoted: computedAggregateFor('premiumQuoted'),
    homeQuoted: computedAggregateFor('homeQuoted'),
    vehicleQuoted: computedAggregateFor('vehicleQuoted'),
    umbrellaQuoted: computedAggregateFor('umbrellaQuoted'),
    lifeQuoted: computedAggregateFor('lifeQuoted'),
    specialtyQuoted: computedAggregateFor('specialtyQuoted'),
    commercialQuoted: computedAggregateFor('commercialQuoted'),

    userName: computed.alias('userMapping.userFullName'),

    actions: {
      toggleGroupsVisibility: function toggleGroupsVisibility() {
        this.toggleProperty('subrowHidden');
      }
    }
  });
});