define('mvp-web/components/enrichment-notes-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    attributeName: computed('key', 'index', function () {
      var key = this.get('key');
      var attributeName = key.split('_').map(function (k) {
        return k[0].toUpperCase() + k.substring(1);
      }).join(' ');
      if (this.get('index')) {
        return attributeName.concat(' ', this.get('index'));
      }

      return attributeName;
    })
  });
});