define('mvp-web/validations/at-workflow-actions-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    atActionId: [(0, _validators.validatePresence)(true)],
    atActionTime: [(0, _validators.validatePresence)(true)],
    atActionTimeValue: [(0, _validators.validatePresence)(true)],
    timeValue: [(0, _validators.validateNumber)({
      integer: true, positive: true, lte: 1000, gte: 1
    })],
    actionTemplate: [(0, _validators.validatePresence)(true)]
  };
});