define('mvp-web/services/test-static-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('staticData', {
        addCustomerPolicyTypeNames: ['Homeowner'],
        emailLeadsDomainRegex: '@agencymvp.com'
      });
    }
  });
});