define('mvp-web/components/dashboard/performance-statistics/sold-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['__dashboard_performance-statistics_sold-value', 'text-center'],
    missingPremium: false,

    isSoldByAgent: computed.gt('premiumSold', 0),
    anySavings: computed.gt('savings', 0),

    otherAgentActivity: computed('totalPremiumSold', 'savings', function () {
      var totalPremiumSold = this.get('totalPremiumSold');
      var saved = this.get('anySavings') ? '(' + this.get('savings') + ')' : '';

      return 'Sold outside chosen time range or by other agent: ' + totalPremiumSold + ' ' + saved;
    })
  });
});