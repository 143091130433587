define('mvp-web/components/quote-forms/life/life-member', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    age: computed('member.dob', function () {
      return (0, _moment.default)().diff(this.get('member.dob'), 'years');
    }),

    lifeQuotes: computed('member.lifeQuotes', 'isFirstParty', function () {
      var isFirstParty = this.get('isFirstParty');

      return isFirstParty ? this.get('member.firstPartyLifeQuotes') : this.get('member.thirdPartyLifeQuotes');
    }),

    // for deleting all life quotes - make a task, then destroy each in a loop. ugh.

    actions: {
      removeMemberLifeQuote: function removeMemberLifeQuote(quote) {
        var lifeQuotes = this.get('lifeQuotes');
        lifeQuotes.removeObject(quote);
      },
      selectPaymentOption: function selectPaymentOption(quote, selectedOption) {
        quote.setPaymentOption(selectedOption);
      },
      selectPolicyType: function selectPolicyType(quote, selectedOption) {
        quote.setPolicyType(selectedOption);
      }
    }
  });
});