define('mvp-web/components/phone-numbers/phone-number-generic-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      closeModal: function closeModal() {
        var _this = this;

        Ember.run(function () {
          return Ember.$('#' + _this.get('modalId')).modal('hide');
        });
      }
    }
  });
});