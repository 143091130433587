define('mvp-web/components/mvp-exclusive-dashboard/exclusive-dashboard-filters', ['exports', 'mvp-web/mixins/power-select-highlighted', 'mvp-web/mixins/errors'], function (exports, _powerSelectHighlighted, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend(_errors.default, _powerSelectHighlighted.default, {
    store: service(),
    notifications: service('notification-messages'),
    session: service(),
    exclusiveDashboardFilter: service(),

    isFilterExpanded: false,

    _order: null,
    dateLimit: { days: 730 },

    actions: {
      toggleFilterView: function toggleFilterView() {
        this.set('isFilterExpanded', !this.get('isFilterExpanded'));
      },
      handleDateRangeChanged: function handleDateRangeChanged(dateFrom, dateTo) {
        this.setProperties({ dateFrom: dateFrom, dateTo: dateTo });
      },
      setOrder: function setOrder(option) {
        this.setProperties({ _order: option, order: option.value });
      }
    }
  });
});