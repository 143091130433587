define('mvp-web/components/workflow-tab-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'tr',
    indexRow: computed('index', function () {
      return this.get('index') + 1;
    }),
    atWorkflowName: computed('atWorkflowHistory', function () {
      return this.get('atWorkflowHistory.atWorkflowName');
    })
  });
});