define('mvp-web/mixins/resize-aware', ['exports', 'ember-resize/mixins/resize-aware'], function (exports, _resizeAware) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _resizeAware.default;
    }
  });
});