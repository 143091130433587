define('mvp-web/components/group-members-section', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed,
      copy = Ember.copy;
  exports.default = Ember.Component.extend({
    session: service(),
    members: computed.alias('group.members'),
    isCalifornia: computed.equal('session.currentUserOrganization.usState', 'CA'),
    isNotCalifornia: computed.not('isCalifornia'),
    referrals: computed.alias('group.referralGroups'),

    primaryMemberDOB: computed('group.primaryMember.dob', function () {
      return (0, _moment.default)(copy(this.get('group.primaryMember.dob')));
    }),

    primaryMemberFirstName: computed('group.primaryMember.firstName', function () {
      return copy(this.get('group.primaryMember.firstName'));
    }),

    primaryMemberLastName: computed('group.primaryMember.lastName', function () {
      return copy(this.get('group.primaryMember.lastName'));
    }),

    primaryMemberDriversLicense: computed('group.primaryMember.driversLicense', function () {
      return copy(this.get('group.primaryMember.driversLicense'));
    }),

    primaryAddressStreet: computed('group.address.street1', function () {
      return copy(this.get('group.address.street1'));
    }),

    primaryAddressPostalCode: computed('group.address.postalCode', function () {
      return copy(this.get('group.address.postalCode'));
    }),

    actions: {
      toggleDriversFinder: function toggleDriversFinder() {
        this.toggleProperty('isDriversFinderOpen');
      },
      setIsLookingForDrivers: function setIsLookingForDrivers(value) {
        this.set('isLookingForDrivers', value);
      },
      openViewReferralsPopover: function openViewReferralsPopover() {
        Ember.$('#viewReferralsPopOver').offcanvas('show');
      }
    }
  });
});