define('mvp-web/controllers/group-organization-transfer-dashboard/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Controller = Ember.Controller,
      observer = Ember.observer,
      getOwner = Ember.getOwner;
  exports.default = Controller.extend({
    session: service(),
    globalLoader: service(),

    modelName: 'group-organization-transfer',
    queryParams: ['page', 'size', 'date_from', 'date_to', 'order', 'selected_child_organization_ids'],

    page: 1,
    size: 25,

    regularModel: computed.or('model.{currentRegularTask,previousRegularTask}.value'),

    regularModelIsLoading: computed.reads('model.currentRegularTask.isRunning'),
    regularModelIsCanceling: computed.reads('model.currentRegularTask.isCanceling'),
    regularModelIsFinished: computed.reads('model.currentRegularTask.isFinished'),
    regularModelHasCache: computed.notEmpty('model.previousRegularTask.value'),

    modelIsCanceling: computed.reads('regularModelIsCanceling'),
    modelIsFinished: computed.reads('regularModelIsFinished'),

    modelLoadingObserver: observer('regularModelIsFinished', function () {
      if (!this.get('modelIsCanceling') && this.get('modelIsFinished')) {
        return this.get('globalLoader.deactivate').perform();
      }
    }),

    user: computed('session', function () {
      return this.get('session.currentUser');
    }),

    actions: {
      refreshModel: function refreshModel() {
        var route = getOwner(this).lookup('route:group-organization-transfer-dashboard.index');
        return route.refresh();
      }
    }
  });
});