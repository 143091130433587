define('mvp-web/services/form-options-bootstrap', ['exports', 'rollbar'], function (exports, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Logger = Ember.Logger,
      RSVP = Ember.RSVP,
      Service = Ember.Service;
  exports.default = Service.extend({
    store: service(),
    promise: Ember.Object.create({ group: null, user: null }),
    loaded: Ember.Object.create({ group: false, user: false }),
    formOptionsBootstrap: Ember.Object.create({}),

    loadData: function loadData() {
      var _this = this;

      var target = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'group';

      var promise = this.get('promise.' + target);

      if (promise) return promise;

      if (this.get('loaded.' + target)) return RSVP.hash(this.get('formOptionsBootstrap'));

      return this.get('promise').set(target, new RSVP.Promise(function (resolve, reject) {
        var adapter = _this.get('store').adapterFor('bootstrap-data');

        adapter.fetchBootstrapData(target).then(function (data) {
          for (var key in data) {
            if (data && data.hasOwnProperty(key)) {
              _this.get('formOptionsBootstrap').set(key, data[key]);
            }
          }

          _this.get('loaded').set(target, true);
          _this.get('promise').set(target, null);

          resolve(_this.get('formOptionsBootstrap'));
        }, function (error) {
          _rollbar.default.error(error);
          Logger.error(error);

          reject(error);
        });
      }));
    }
  });
});