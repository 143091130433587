define('mvp-web/mixins/errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  exports.default = Ember.Mixin.create({
    genericMessage: 'Whoops. An error occurred. No additional detail was provided.',

    /**
     * Provides decoded error messages wrapped in `UL` and `LI` elements
     * @param errorsObject, a JSON API compliant error object; generally returned from the server
     * @param model, an optional Ember model in case the `errorsObject` is not available
     * @returns string
     */
    decodeJsonApiErrors: function decodeJsonApiErrors(errorsObject, model) {
      try {
        if (errorsObject.responseJSON && !isEmpty(errorsObject.responseJSON.errors)) {
          errorsObject.errors = errorsObject.responseJSON.errors;
        }

        if (errorsObject && errorsObject.errors) {
          return this._decodeServerPayloadErrors(errorsObject);
        }if (model && model.errors) {
          return this._decodeEmberModelErrors(model);
        }

        return this.get('genericMessage');
      } catch (error) {
        return this.get('genericMessage');
      }
    },


    // private methods
    _decodeEmberModelErrors: function _decodeEmberModelErrors(model) {
      var errors = '';
      var modelErrors = model.errors || [];

      if (modelErrors.length > 0) {
        if (modelErrors.length === 1) {
          return '' + modelErrors[0].message;
        }
        modelErrors.forEach(function (error) {
          errors += '<li>' + error.message + '</li>';
        });

        return '<ul>' + errors + '</ul>';
      }
      return this.get('genericMessage');
    },
    _decodeServerPayloadErrors: function _decodeServerPayloadErrors(errorsObject) {
      var errors = '';

      if (errorsObject && errorsObject.errors.length === 1) {
        var error = errorsObject.errors[0];
        return '' + error.detail;
      }
      var errorsObjectErrors = errorsObject ? errorsObject.errors : { errors: [] };

      errorsObjectErrors.forEach(function (error) {
        errors += '<li>' + error.detail.capitalize() + '</li>';
      });

      return '<ul>' + errors + '</ul>';
    },
    _getAttrName: function _getAttrName(source) {
      var _source = source ? source.pointer.split('/') : [];
      return _source && _source.length > 0 ? _source[_source.length - 1].replace('_', ' ').replace('.', ' ').capitalize() : '';
    }
  });
});