define('mvp-web/serializers/task-sequence', ['exports', 'mvp-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      organization: { serialize: true },
      user: { serialize: true }
    }
  });
});