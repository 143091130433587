define('mvp-web/components/form-labeled-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['_form-labeled-input'],
    isValid: computed.empty('errors')
  });
});