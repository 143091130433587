define('mvp-web/transforms/local-date-time', ['exports', 'ember-data/transform', 'moment', 'rollbar'], function (exports, _transform, _moment, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    serialize: function serialize(value) {
      if (value && _moment.default.isMoment(value)) {
        return value.format('YYYY-MM-DD');
      }
      return value;
    },
    deserialize: function deserialize(value) {
      try {
        return value ? (0, _moment.default)(value).local() : null;
      } catch (e) {
        var msg = 'Date deserialization problem for value: ' + JSON.stringify(value) + ('\nOriginal error: ' + JSON.stringify(e));
        _rollbar.default.error({
          message: 'Error in transforms/local-date-time.js',
          msg: msg
        });
        return null;
      }
    }
  });
});