define('mvp-web/components/groups-page-numbers', ['exports', 'mvp-web/mixins/page-number'], function (exports, _pageNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_pageNumber.default, {
    span: 3, // num of range to show

    // count computed property from mixin
    actions: {
      setPageWithScroll: function setPageWithScroll(pageNumber) {
        window.scrollTo(0, 0);
        this.get('setPage')(pageNumber);
      }
    }
  });
});