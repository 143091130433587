define('mvp-web/models/phone-number-actions/forward-action', ['exports', 'ember-cp-validations', 'mvp-web/models/phone-number-action'], function (exports, _emberCpValidations, _phoneNumberAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    type: [(0, _emberCpValidations.validator)('inclusion', {
      in: ['PhoneNumberActions::ForwardAction']
    })],
    'options.to_number': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'To Number'
    }), (0, _emberCpValidations.validator)('phone-number-format')]
  });

  exports.default = _phoneNumberAction.default.extend(Validations, {});
});