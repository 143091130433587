define('mvp-web/initializers/segmentio', ['exports', 'ember-cli-segment/initializer'], function (exports, _initializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'segment',
    initialize: _initializer.default
  };
});