define('mvp-web/components/at-workflow-form/organization-number-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    roundRobinConfiguration: {},
    currentCount: computed('roundRobinConfiguration.[]', function () {
      var config = this.get('roundRobinConfiguration');
      var id = this.get('organizationId');
      if (config.numbered === null || config.numbered === undefined) return 0;
      return config.numbered[id];
    }),
    nameToDisplay: null
  });
});