define('mvp-web/instance-initializers/static-data', ['exports', 'mvp-web/config/environment', 'mvp-web/services/static-data', 'mvp-web/services/test-static-data'], function (exports, _environment, _staticData, _testStaticData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var serviceClass = _environment.default.environment === 'test' ? _testStaticData.default : _staticData.default;

    application.register('service:static-data', serviceClass, { singleton: true });
    application.inject('route', 'staticData', 'service:static-data');
    application.inject('controller', 'staticData', 'service:static-data');
    application.inject('component', 'staticData', 'service:static-data');
  }

  exports.default = {
    name: 'static-data',
    initialize: initialize
  };
});