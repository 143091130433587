define('mvp-web/helpers/contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contains = contains;
  function contains(collection, element) {
    return collection.indexOf(element) !== -1;
  }

  exports.default = Ember.Helper.helper(contains);
});