define('mvp-web/validations/customer-new-validations', ['exports', 'ember-changeset-validations/validators', 'ember-validators'], function (exports, _validators, _emberValidators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validateEitherPhoneNumber = function validateEitherPhoneNumber(alternativeKey) {
    return function (key, newValue, oldValue, changes, content) {
      var isThisValid = validatePhoneNumber(newValue, key);
      var isAlternativeValid = validatePhoneNumber(Ember.get(content.changeset, alternativeKey), alternativeKey);

      // In case both attributes were invalid and one becomes valid, changeset
      // will not rerun validations for the alternative key, thus the error will
      // remain on this attribute. For that reason we need to force changeset to
      // revalidate the alternative key.
      if (!isAlternativeValid && isThisValid) {
        Ember.set(content.changeset, alternativeKey, Ember.get(content.changeset, alternativeKey));
      }

      if (isThisValid || isAlternativeValid) {
        return true;
      }

      return 'Either home or phone number must be a valid phone number';
    };
  };

  var validatePhoneNumber = function validatePhoneNumber(value, key) {
    return (0, _emberValidators.validate)('format', value, { type: 'phone' }, null, key) === true && (0, _emberValidators.validate)('presence', value, { presence: true }, null, key) === true;
  };

  exports.default = {
    memberFirstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2 })],
    memberLastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2 })],
    memberMobileNumber: [validateEitherPhoneNumber('memberHomeNumber')],
    memberHomeNumber: [validateEitherPhoneNumber('memberMobileNumber')],
    street1: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 4 })],
    city: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2 })],
    usState: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2 })],
    postalCode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 3 })],
    policies: [(0, _validators.validatePresence)(true)]
  };
});