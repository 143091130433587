define('mvp-web/services/organization-setting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Service.extend({
    session: service(),

    settings: computed.alias('session.currentUserOrganization.organizationSetting'),
    workflowSettings: computed.alias('settings.workflowSettings'),
    allowTeamMemberWorkflowEdit: computed.alias('workflowSettings.allow_team_member_workflow_edit'),
    pipelineGroupOnXDateWorkflowAssignment: computed.alias('workflowSettings.pipeline_group_on_x_date_workflow_assignment'),

    isMessagingEnabled: computed('settings', function () {
      if (!this.get('settings')) return false;

      return !this.get('settings.disableMessagingFeature');
    })
  });
});