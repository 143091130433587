define('mvp-web/mixins/embedded-relationships', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var get = Ember.get,
      set = Ember.set,
      Mixin = Ember.Mixin,
      _Ember$String = Ember.String,
      camelize = _Ember$String.camelize,
      underscore = _Ember$String.underscore;
  exports.default = Mixin.create({
    // if serializer has an attribute defined with `true` as its value,
    // add '_attributes' to the outgoing payload
    keyForAttribute: function keyForAttribute(attr, method) {
      if (method === 'serialize' && this.hasSerializeRecordsOption(attr)) {
        attr = this.transformKeyForRails(attr);
      }

      return this._super(attr, method);
    },


    // find keys for relationships to serialize
    keyForRelationship: function keyForRelationship(key, typeClass, method) {
      var newKey = underscore(key);

      if (method === 'serialize' && typeClass === 'belongsTo') {
        return newKey + '_id';
      }

      if (method === 'serialize' && this.hasSerializeRecordsOption(key)) {
        return this.keyForAttribute(key, method);
      }
      return newKey;
    },
    normalize: function normalize(typeClass, hash, prop) {
      var normalizedHash = this._super(typeClass, hash, prop);
      return this._extractEmbeddedRecords(this, this.store, typeClass, normalizedHash);
    },
    removeEmbeddedForeignKey: function removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, json) {
      if (relationship.kind === 'belongsTo') {
        var parentRecord = snapshot.type.inverseFor(relationship.key, this.store);
        if (parentRecord) {
          var name = parentRecord.name;

          var embeddedSerializer = this.store.serializerFor(embeddedSnapshot.modelName);
          var parentKey = embeddedSerializer.keyForRelationship(name, parentRecord.kind, 'deserialize');
          if (json && parentKey) {
            delete json[parentKey];
          }
        }
      }
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var attr = relationship.key;
      if (this.noSerializeOptionSpecified(attr)) {
        this._super(snapshot, json, relationship);
        return;
      }

      var includeIds = this.hasSerializeIdsOption(attr);
      var includeRecords = this.hasSerializeRecordsOption(attr);
      var embeddedSnapshot = snapshot.belongsTo(attr);

      if (includeIds) {
        var serializedKey = this._getMappedKey(relationship.key, snapshot.type);
        if (serializedKey === relationship.key && this.keyForRelationship) {
          serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
        }

        if (!embeddedSnapshot) {
          json[serializedKey] = null;
        } else {
          json[serializedKey] = embeddedSnapshot.id;

          if (relationship.options.polymorphic) {
            this.serializePolymorphicType(snapshot, json, relationship);
          }
        }
      } else if (includeRecords) {
        this._serializeEmbeddedBelongsTo(snapshot, json, relationship);
      }
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var attr = relationship.key;
      if (this.noSerializeOptionSpecified(attr)) {
        this._super(snapshot, json, relationship);
        return;
      }

      if (this.hasSerializeIdsOption(attr)) {
        var serializedKey = this._getMappedKey(relationship.key, snapshot.type);
        if (serializedKey === relationship.key && this.keyForRelationship) {
          serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
        }

        json[serializedKey] = snapshot.hasMany(attr, { ids: true });
      } else if (this.hasSerializeRecordsOption(attr)) {
        this._serializeEmbeddedHasMany(snapshot, json, relationship);
      } else if (this.hasSerializeIdsAndTypesOption(attr)) {
        this._serializeHasManyAsIdsAndTypes(snapshot, json, relationship);
      }
    },


    // Helpers

    attrsOption: function attrsOption(attr) {
      var attrs = this.get('attrs');
      return attrs && (attrs[camelize(attr)] || attrs[attr]);
    },


    // checks config for attrs option to serialize records as objects containing id and types
    hasSerializeIdsAndTypesOption: function hasSerializeIdsAndTypesOption(attr) {
      var option = this.attrsOption(attr);
      return option && (option.serialize === 'ids-and-types' || option.serialize === 'id-and-type');
    },


    // checks config for attrs option to serialize ids
    hasSerializeIdsOption: function hasSerializeIdsOption(attr) {
      var option = this.attrsOption(attr);
      return option && (option.serialize === 'ids' || option.serialize === 'id');
    },


    // checks config for attrs option to serialize records
    hasSerializeRecordsOption: function hasSerializeRecordsOption(attr) {
      var option = this.attrsOption(attr);
      return option && option.serialize === true;
    },


    // checks config for attrs option to serialize records
    noSerializeOptionSpecified: function noSerializeOptionSpecified(attr) {
      var option = this.attrsOption(attr);
      return !(option && (option.serialize || option.embedded));
    },
    transformKeyForRails: function transformKeyForRails(key) {
      var keyName = underscore(key);
      var endsWithId = typeof ''.endsWith === 'function' ? keyName.endsWith('_id') : (0, _lodash.default)(keyName, '_id');

      if (keyName && endsWithId) {
        return keyName.replace(/(_id)$/, '_attributes');
      }
      return key + '_attributes';
    },


    // private methods

    _extractEmbeddedBelongsTo: function _extractEmbeddedBelongsTo(store, key, hash, relationshipMeta) {
      var relationshipHash = get(hash, 'data.relationships.' + key + '.data');

      if (!relationshipHash) {
        return;
      }

      var _normalizeEmbeddedRel = this._normalizeEmbeddedRelationship(store, relationshipMeta, relationshipHash),
          data = _normalizeEmbeddedRel.data,
          included = _normalizeEmbeddedRel.included;

      hash.included = hash.included || [];
      hash.included.push(data);
      if (included) {
        var _hash$included;

        (_hash$included = hash.included).push.apply(_hash$included, _toConsumableArray(included));
      }

      var belongsTo = { id: data.id, type: data.type };
      var relationship = { data: belongsTo };

      set(hash, 'data.relationships.' + key, relationship);
    },
    _extractEmbeddedHasMany: function _extractEmbeddedHasMany(store, key, hash, relationshipMeta) {
      var relationshipHash = get(hash, 'data.relationships.' + key + '.data');

      if (!relationshipHash) {
        return;
      }

      var hasMany = new Array(relationshipHash.length);

      for (var i = 0; i < relationshipHash.length; i++) {
        var item = relationshipHash[i];

        var _normalizeEmbeddedRel2 = this._normalizeEmbeddedRelationship(store, relationshipMeta, item),
            data = _normalizeEmbeddedRel2.data,
            included = _normalizeEmbeddedRel2.included;

        hash.included = hash.included || [];
        hash.included.push(data);
        if (included) {
          var _hash$included2;

          (_hash$included2 = hash.included).push.apply(_hash$included2, _toConsumableArray(included));
        }

        hasMany[i] = { id: data.id, type: data.type };
      }

      var relationship = { data: hasMany };
      set(hash, 'data.relationships.' + key, relationship);
    },
    _extractEmbeddedRecords: function _extractEmbeddedRecords(serializer, store, typeClass, partial) {
      var _this = this;

      typeClass.eachRelationship(function (key, relationship) {
        if (relationship.kind === 'hasMany') {
          _this._extractEmbeddedHasMany(store, key, partial, relationship);
        }
        if (relationship.kind === 'belongsTo') {
          _this._extractEmbeddedBelongsTo(store, key, partial, relationship);
        }
      });
      return partial;
    },


    /*
     Returns an array of embedded records serialized to JSON
     */
    _generateSerializedHasMany: function _generateSerializedHasMany(snapshot, relationship) {
      var hasMany = snapshot.hasMany(relationship.key);
      var manyArray = Ember.A(hasMany);
      var ret = new Array(manyArray.length);

      for (var i = 0; i < manyArray.length; i++) {
        var embeddedSnapshot = manyArray[i];
        var embeddedJson = embeddedSnapshot.serialize({ includeId: true });
        this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
        ret[i] = embeddedJson;
      }

      return ret;
    },
    _normalizeEmbeddedRelationship: function _normalizeEmbeddedRelationship(store, relationshipMeta, relationshipHash) {
      var modelName = relationshipMeta.type;
      if (relationshipMeta.options.polymorphic) {
        modelName = relationshipHash.type;
      }
      var modelClass = store.modelFor(modelName);
      var serializer = store.serializerFor(modelName);

      return serializer.normalize(modelClass, relationshipHash, null);
    },
    _serializeEmbeddedBelongsTo: function _serializeEmbeddedBelongsTo(snapshot, json, relationship) {
      var embeddedSnapshot = snapshot.belongsTo(relationship.key);
      var serializedKey = this._getMappedKey(relationship.key, snapshot.type);
      if (serializedKey === relationship.key && this.keyForRelationship) {
        serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
      }

      if (!embeddedSnapshot) {
        json[serializedKey] = null;
      } else {
        json[serializedKey] = embeddedSnapshot.id;
        json[this.transformKeyForRails(serializedKey)] = embeddedSnapshot.serialize({ includeId: true });
        this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, json[serializedKey]);

        if (relationship.options.polymorphic) {
          this.serializePolymorphicType(snapshot, json, relationship);
        }
      }
    },
    _serializeEmbeddedHasMany: function _serializeEmbeddedHasMany(snapshot, json, relationship) {
      var serializedKey = this._getMappedKey(relationship.key, snapshot.type);
      if (serializedKey === relationship.key && this.keyForRelationship) {
        serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
      }

      json[serializedKey] = this._generateSerializedHasMany(snapshot, relationship);
    },
    _serializeHasManyAsIdsAndTypes: function _serializeHasManyAsIdsAndTypes(snapshot, json, relationship) {
      var serializedKey = this.keyForAttribute(relationship.key, 'serialize');
      var hasMany = snapshot.hasMany(relationship.key);

      json[serializedKey] = Ember.A(hasMany).map(function (recordSnapshot) {
        return { id: recordSnapshot.id, type: recordSnapshot.modelName };
      });
    }
  });
});