define('mvp-web/components/edit-toolbar-mobile', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    classNames: ['mvp-edit-toolbar-mobile'],
    notifications: service('notification-messages'),
    sidenavService: service('sidenav'),

    isNotFullyLoaded: computed.not('group.isFullyLoaded'),

    isMobileToolbarExpanded: false,

    convertLabel: computed('isProspect', function () {
      return this.get('isProspect') ? 'Convert to Customer' : 'Convert to Prospect';
    }),

    showConvertToProspectButton: computed.alias('group.canBeConvertedToProspect'),

    showConvertToLeadButton: computed.not('hasOwner'),

    showMarkAsQuotedProspectButton: computed.alias('group.canBeConvertedToQuotedProspect'),

    showDeleteButton: computed.alias('group.canBeDeleted'),

    hasOwner: computed.alias('group.hasOwner'),

    isGroupNew: computed.alias('group.isNew'),

    showSoldOrCrossSellButton: computed.or('group.isQuotedProspect', 'group.isCustomer'),

    transitionState: computed.alias('group.transitionState'),

    actions: {
      toggleMobileMenu: function toggleMobileMenu() {
        this.toggleProperty('isMobileToolbarExpanded');
      }
    }
  });
});