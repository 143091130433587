define('mvp-web/models/all-quote', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var pluralize = Ember.String.pluralize,
      computed = Ember.computed;
  exports.default = _model.default.extend({
    updatedAt: (0, _attr.default)('utc'),
    quotedAt: (0, _attr.default)('date-without-zone'),
    soldAt: (0, _attr.default)('utc'),
    isFirstParty: (0, _attr.default)('boolean'),
    quoteType: (0, _attr.default)('string'),
    groupPrimaryMemberName: (0, _attr.default)('string'),
    groupPrimaryMemberPhoneNumber: (0, _attr.default)('string'),
    groupPrimaryMemberMobileNumber: (0, _attr.default)('string'),
    groupAddressLine: (0, _attr.default)('string'),
    groupTransitionState: (0, _attr.default)('string'),
    canMarkAsSold: (0, _attr.default)('boolean'),

    user: (0, _relationships.belongsTo)('user'),
    organization: (0, _relationships.belongsTo)('organization'),
    group: (0, _relationships.belongsTo)('group'),

    editGroupPath: computed('groupTransitionState', function () {
      var state = this.get('groupTransitionState');
      if (state == undefined) {
        return null;
      }
      return pluralize(state) + '.edit';
    }),

    groupId: computed('group', function () {
      return this.belongsTo('group').id();
    }),

    userId: computed('user', function () {
      return this.belongsTo('user').id();
    })
  });
});