define('mvp-web/models/home-coverage', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    homeQuote: (0, _relationships.belongsTo)('homeQuote'),
    dwellingCoverage: (0, _attr.default)('number'),
    guestMedicalCoverageId: (0, _attr.default)('number'),
    livingExpenseTermId: (0, _attr.default)('number'),
    lossOfUseCoverage: (0, _attr.default)('number'),
    personalLiabilityCoverageId: (0, _attr.default)('number'),
    personalPropertyCoverage: (0, _attr.default)('number'),
    separateStructuresCoverage: (0, _attr.default)('number'),
    showOnComparison: (0, _attr.default)('boolean'),

    quote: computed.alias('homeQuote')
  });
});