define('mvp-web/helpers/format-time', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.secondsToDurationString = secondsToDurationString;


  /**
   * Format a number of seconds into friendly format of h:mm:ss/mm:ss/:ss as applicable.
   * @param durationSeconds
   * @returns {string}
   */

  function secondsToDurationString(durationSeconds) {
    var duration = _moment.default.duration(durationSeconds, 'seconds');
    var h = duration.hours();
    var m = duration.minutes();
    var mm = m.toString().padStart(2, '0');
    var ss = duration.seconds().toString().padStart(2, '0');
    if (h > 0) {
      return h + ':' + mm + ':' + ss;
    }
    if (m > 0) {
      return mm + ':' + ss;
    }
    return ':' + ss;
  }

  exports.default = Ember.Helper.helper(secondsToDurationString);
});