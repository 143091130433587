define('mvp-web/models/task-sequence', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Name'
    })
  });

  exports.default = _model.default.extend(Validations, {
    organization: (0, _relationships.belongsTo)('organization'),
    tasks: (0, _relationships.hasMany)('task'),
    taskSequenceTemplates: (0, _relationships.hasMany)('taskSequenceTemplates'),
    taskTemplates: (0, _relationships.hasMany)('taskTemplates'),
    user: (0, _relationships.belongsTo)('user'),

    name: (0, _attr.default)(),
    taskSequenceTemplatesAttributes: (0, _attr.default)()
  });
});