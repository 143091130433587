define('mvp-web/initializers/promise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'promise',
    initialize: function initialize() {
      window.Promise = Ember.RSVP.Promise;
    }
  };
});