define('mvp-web/components/dnc-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showNumber: false,

    actions: {
      toggleNumber: function toggleNumber() {
        this.toggleProperty('showNumber');
      }
    }
  });
});