define('mvp-web/validations/claim-off-settings-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    homeClaimFallOff: [(0, _validators.validateNumber)({ integer: true, positive: true, gte: 1 })],
    autoClaimFallOff: [(0, _validators.validateNumber)({ integer: true, positive: true, gte: 1 })],
    otherClaimFallOff: [(0, _validators.validateNumber)({ integer: true, positive: true, gte: 1 })]
  };
});