define('mvp-web/models/sold-policy', ['exports', 'ember-data/attr', 'mvp-web/models/existing-policy'], function (exports, _attr, _existingPolicy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _existingPolicy.default.extend({
    tags: (0, _attr.default)(),

    soldPolicySource: computed('tags', function () {
      return this.get('tags.firstObject.name');
    })
  });
});