define('mvp-web/helpers/do-math', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.doMath = doMath;


  /**
   * Perform math operations given operator and operands
   * Param order is left operand, operator as string, right operand.
   * @param params, {number}, {string}, {number}
   * @returns {number}
   */

  function doMath(params) {
    var result = void 0;

    var l = params[0];
    var o = params[1];
    var r = params[2];

    switch (o) {
      case '+':
        result = l + r;
        break;
      case '-':
        result = l - r;
        break;
      case '*':
        result = l * r;
        break;
      case '/':
        result = l / r;
        break;
    }

    return result;
  }

  exports.default = Ember.Helper.helper(doMath);
});