define('mvp-web/models/vehicle', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'mvp-web/helpers/selection-name-label'], function (exports, _relationships, _attr, _model, _selectionNameLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    claims: computed.alias('vehicleClaims'),
    group: (0, _relationships.belongsTo)('group', { polymorphic: true, inverse: 'vehicles' }),
    makeId: (0, _attr.default)(),
    modelId: (0, _attr.default)(),
    vehicleClaims: (0, _relationships.hasMany)('vehicleClaim'),
    vehicleCoverages: (0, _relationships.hasMany)('vehicleCoverage'),
    yearId: (0, _attr.default)(),
    trimId: (0, _attr.default)(),
    ownerFullName: (0, _attr.default)(),
    VIN: (0, _attr.default)(),
    licensePlate: (0, _attr.default)(),
    address: (0, _attr.default)(),
    ownershipStatus: (0, _attr.default)(),
    annualMileage: (0, _attr.default)(),

    lienholderName: (0, _attr.default)(),
    lienholderAddress: (0, _attr.default)(),
    lienholderCity: (0, _attr.default)(),
    lienholderState: (0, _attr.default)(),
    lienholderZipcode: (0, _attr.default)(),

    customYear: (0, _attr.default)(),
    customMake: (0, _attr.default)(),
    customModel: (0, _attr.default)(),

    dmvDate: (0, _attr.default)(),

    // meta values for UI display only
    makeSelection: (0, _attr.default)('object'),
    modelSelection: (0, _attr.default)('object'),
    trimSelection: (0, _attr.default)('object'),
    yearSelection: (0, _attr.default)('object'),
    lienholderStateSelection: (0, _attr.default)('object'),
    extraData: (0, _attr.default)('object'),
    oldData: {},
    oldModelData: {},

    longName: computed('shortName', 'trimId', function () {
      return this.get('shortName') + ' ' + ((0, _selectionNameLabel.selectionNameLabel)([this.get('trimSelection'), this.get('trimId'), 'name']) || '');
    }),
    shortName: computed('yearId', 'makeId', 'modelId', 'customYear', 'customMake', 'customModel', function () {
      return (this.get('customYear') || (0, _selectionNameLabel.selectionNameLabel)([this.get('yearSelection'), this.get('yearId'), 'year']) || '') + '\n            ' + (this.get('customMake') || (0, _selectionNameLabel.selectionNameLabel)([this.get('makeSelection'), this.get('makeId'), 'name']) || '') + '\n            ' + (this.get('customModel') || (0, _selectionNameLabel.selectionNameLabel)([this.get('modelSelection'), this.get('modelId'), 'name']) || '');
    }),
    isOwned: computed('ownershipStatus', function () {
      return this.get('ownershipStatus') === 'Owned';
    }),
    isFinanced: computed('ownershipStatus', function () {
      return this.get('ownershipStatus') === 'Financed';
    }),
    isLeased: computed('ownershipStatus', function () {
      return this.get('ownershipStatus') === 'Leased';
    }),

    hasYear: computed.or('yearId', 'customYear'),

    leasedOrFinanced: computed.or('isFinanced', 'isLeased'),

    isCustomAdded: computed.empty('yearId'),

    hasVehicleCoverages: computed.notEmpty('vehicleCoverages'),

    setOldData: function setOldData() {
      this.set('oldData', {
        address: this.get('address'),
        city: this.get('city'),
        zipcode: this.get('zipcode'),
        usState: this.get('usState'),
        lienholderName: this.get('lienholderName'),
        lienholderAddress: this.get('lienholderAddress'),
        lienholderCity: this.get('lienholderCity'),
        lienholderState: this.get('lienholderState'),
        lienholderZipcode: this.get('lienholderZipcode')
      });
    },
    restoreOldData: function restoreOldData() {
      var oldData = this.get('oldData');
      this.setProperties({
        address: oldData.address,
        city: oldData.city,
        zipcode: oldData.zipcode,
        usState: oldData.usState,
        lienholderName: oldData.lienholderName,
        lienholderAddress: oldData.lienholderAddress,
        lienholderCity: oldData.lienholderCity,
        lienholderState: oldData.lienholderState,
        lienholderZipcode: oldData.lienholderZipcode
      });
    },
    cleanData: function cleanData() {
      this.setProperties({
        address: undefined,
        city: undefined,
        zipcode: undefined,
        usState: undefined,
        lienholderName: undefined,
        lienholderAddress: undefined,
        lienholderCity: undefined,
        lienholderState: undefined,
        lienholderZipcode: undefined
      });
    },
    cleanOldData: function cleanOldData() {
      this.set('oldData', {});
    },
    setAsAddedFromDb: function setAsAddedFromDb() {
      var storedModelData = this.get('oldModelData');
      var oldModelData = {
        customYear: this.get('customYear'),
        customModel: this.get('customModel'),
        customMake: this.get('customMake')
      };
      this.setProperties({
        customYear: null,
        customModel: null,
        customMake: null,
        yearId: storedModelData.yearId,
        modelId: storedModelData.modelId,
        makeId: storedModelData.makeId,
        makeSelection: storedModelData.makeSelection,
        modelSelection: storedModelData.modelSelection,
        yearSelection: storedModelData.yearSelection,
        oldModelData: oldModelData
      });
    },
    setAsAddedManually: function setAsAddedManually() {
      var storedModelData = this.get('oldModelData');
      var oldModelData = {
        yearId: this.get('yearId'),
        modelId: this.get('modelId'),
        makeId: this.get('makeId'),
        makeSelection: this.get('makeSelection'),
        modelSelection: this.get('modelSelection'),
        yearSelection: this.get('yearSelection')
      };
      this.setProperties({
        yearId: null,
        modelId: null,
        makeId: null,
        makeSelection: null,
        modelSelection: null,
        yearSelection: null,
        customYear: storedModelData.customYear,
        customModel: storedModelData.customModel,
        customMake: storedModelData.customMake,
        oldModelData: oldModelData
      });
    }
  });
});