define('mvp-web/components/group-organization-transfer-dashboard/group-organization-transfer-table-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    deletedDetailsString: 'household deleted',
    store: service(),

    transferredAt: computed('groupOrganizationTransfer.createdAt', function () {
      var createdAt = this.get('groupOrganizationTransfer.createdAt');
      if (createdAt === null || createdAt === undefined) {
        return '';
      }
      return this.get('groupOrganizationTransfer.createdAt').format('MM/DD/YYYY');
    }),

    groupStatusText: computed('groupOrganizationTransfer.deletedGroup', function () {
      if (this.get('groupOrganizationTransfer.deletedGroup')) return 'household deleted';

      return this.get('groupOrganizationTransfer.groupStatus');
    }),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});