define('mvp-web/adapters/user', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(type, id, snapshot, requestType) {
      if (requestType === 'createRecord') {
        return '/users/invitation';
      }
      var userId = id || '';
      return this.get('namespace') + '/users/' + userId;
    },
    resetSipPassword: function resetSipPassword(_ref) {
      var id = _ref.id,
          password = _ref.password,
          passwordConfirmation = _ref.passwordConfirmation;

      var url = this.buildURL('user') + '/' + id + '/reset_sip_password';
      var payload = {
        sip_password: password,
        sip_password_confirmation: passwordConfirmation
      };
      return this.ajax(url, 'POST', { data: payload });
    },
    updateAvailability: function updateAvailability(_ref2) {
      var id = _ref2.id,
          voipStatus = _ref2.voipStatus;

      var url = this.buildURL('user') + '/' + id + '/update_availability';
      var payload = { voip_status: voipStatus };
      return this.ajax(url, 'PATCH', { data: payload });
    },
    updateOutboundPhone: function updateOutboundPhone(_ref3) {
      var id = _ref3.id,
          outboundPhoneNumberId = _ref3.outboundPhoneNumberId;

      var url = this.buildURL('user') + '/' + id + '/update_outbound_phone';
      var payload = { outbound_phone_number_id: outboundPhoneNumberId };
      return this.ajax(url, 'PATCH', { data: payload });
    },
    deactivate: function deactivate(_ref4) {
      var id = _ref4.id,
          assignExistingGroups = _ref4.assignExistingGroups,
          userIds = _ref4.userIds;

      var url = this.buildURL('user', id) + '/deactivate';
      var payload = { assign_existing_groups: assignExistingGroups, user_ids: userIds };
      return this.ajax(url, 'PUT', { data: payload });
    },
    testSmtp: function testSmtp(_ref5) {
      var id = _ref5.id,
          recipient = _ref5.recipient,
          smtpEmail = _ref5.smtpEmail,
          smtpUsername = _ref5.smtpUsername,
          smtpDomain = _ref5.smtpDomain,
          smtpPassword = _ref5.smtpPassword;

      var url = this.buildURL('user', id) + '/test_smtp';
      var payload = {
        smtp_email: smtpEmail,
        smtp_domain: smtpDomain,
        smtp_username: smtpUsername,
        smtp_password: smtpPassword,
        recipient: recipient
      };
      return this.ajax(url, 'POST', { data: payload });
    },
    updatePassword: function updatePassword(_ref6) {
      var id = _ref6.id,
          oldPassword = _ref6.oldPassword,
          newPassword = _ref6.newPassword,
          newPasswordConfirmation = _ref6.newPasswordConfirmation;

      var url = this.buildURL('user', id) + '/update_password';
      var payload = {
        old_password: oldPassword,
        new_password: newPassword,
        new_password_confirmation: newPasswordConfirmation
      };

      return this.ajax(url, 'PATCH', { data: payload });
    }
  });
});