define('mvp-web/components/groups-table/group-customer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    displayCallback: true,

    columnCount: computed('isCommercial', function () {
      var columns = 12; // Default number of columns

      if (this.get('isCommercial')) columns++;

      return columns;
    }),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});