define('mvp-web/components/existing-policies/form', ['exports', 'ember-changeset-validations', 'ember-changeset', 'mvp-web/mixins/power-select-highlighted', 'mvp-web/validations/policy-validations'], function (exports, _emberChangesetValidations, _emberChangeset, _powerSelectHighlighted, _policyValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    store: service(),
    tagName: 'div',
    classNames: ['row'],

    changeset: computed('policy', function () {
      var policy = this.get('policy');
      var changeset = new _emberChangeset.default(policy, (0, _emberChangesetValidations.default)(_policyValidations.default), _policyValidations.default);
      policy.changeset = changeset;

      return changeset;
    }),

    willRender: function willRender() {
      this._super.apply(this, arguments);
      this.get('policy').set('currentPremium', this.get('changeset.currentPremium'));
    },


    policyTypeNames: computed.alias('staticData.staticData.addCustomerPolicyTypeNames'),

    policyTerms: computed(function () {
      return ['6 months', '12 months'];
    }),

    policyTypeNamesMatcher: function policyTypeNamesMatcher(policyTypeName, term) {
      return policyTypeName.toLowerCase().indexOf(term.toLowerCase());
    },


    actions: {
      updateRenewalDate: function updateRenewalDate(selectedDate) {
        this.set('changeset.renewalDate', selectedDate);
        this.set('policy.renewalDate', selectedDate);
      },
      selectPolicyTypeName: function selectPolicyTypeName(selectedOption) {
        this.set('changeset.policyType', selectedOption);
        this.get('policy').set('policyTypeName', selectedOption);
      },
      remove: function remove() {
        this.sendAction('removePolicy', this.get('index'));
      },
      save: function save() {
        this.sendAction('save');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });
});