define('mvp-web/controllers/reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Controller = Ember.Controller;
  exports.default = Controller.extend({
    store: service(),
    websocket: service(),
    consumer: computed.alias('websocket.consumer'),
    init: function init() {
      this._super.apply(this, arguments);
      this._subscribeToReportsChannel();
    },
    _subscribeToReportsChannel: function _subscribeToReportsChannel() {
      var store = this.get('store');

      this.get('consumer').subscriptions.create('ReportChannel', {
        received: function received(report) {
          store.pushPayload(report);
        }
      });
    }
  });
});