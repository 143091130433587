define('mvp-web/components/mvp-exclusive-dashboard/account-balance', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',
    session: service(),

    isLoading: true,
    hasFailedAccountBalanceRetrieval: false,
    accountBalance: 0.00,
    formattedAccountBalance: computed('session.currentUserOrganization.leadBalanceCents', function () {
      var balance = this.get('session.currentUserOrganization.leadBalanceCents');
      return balance ? balance.toFixed(2) / 100 : 0.00;
    })
  });
});