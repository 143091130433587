define('mvp-web/components/dashboard/performance-statistics/call-activity/agent-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    leadsCalled: computed.alias('userStatistics.lead'),
    apiLeadsCalled: computed.alias('userStatistics.apiLead'),
    hotLeadsCalled: computed.alias('userStatistics.hotLead'),
    prospectsAwaitingCalled: computed.alias('userStatistics.prospectAwaiting'),
    prospectsQuotedCalled: computed.alias('userStatistics.prospectQuoted'),
    crossSellCalled: computed.alias('userStatistics.crossSell'),
    totalHouseholdsContacted: computed.alias('userStatistics.totalHouseholdsContacted'),
    totalCallAttempts: computed.alias('userStatistics.totalCallAttempts'),
    totalTexts: computed.alias('userStatistics.totalTexts'),
    userName: computed.alias('userStatistics.userName')
  });
});