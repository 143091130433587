define('mvp-web/components/phone-call-form', ['exports', 'moment', 'mvp-web/mixins/errors', 'mvp-web/mixins/contextual-pipeline-until'], function (exports, _moment, _errors, _contextualPipelineUntil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var service = Ember.inject.service,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend(_errors.default, _contextualPipelineUntil.default, {
    // Services
    notifications: service('notification-messages'),
    store: service(),
    session: service(),

    // Properties
    actionDate: undefined,
    actionTime: undefined,
    actionType: undefined,
    notes: undefined,
    selectedMember: undefined,

    isScheduleAction: computed.equal('actionType', 'schedule'),
    isPipelineAction: computed.equal('actionType', 'pipeline'),

    actionDateTime: computed('actionDate', 'actionTime', function () {
      var date = this.get('actionDate');
      var time = this.get('actionTime');
      if (!date || !time) {
        return undefined;
      }
      var dateTime = (0, _moment.default)(date.format('YYYY-MM-DD') + 'T' + time.format('hh:mm aa'), 'YYYY-MM-DDThh:mm aa');
      return dateTime;
    }),

    validate: function validate() {
      if (this.get('isScheduleAction') && !this.get('actionDateTime')) {
        return { isValid: false, error: 'Whoops! You need to specify both a date and a time.' };
      }if (this.get('isPipelineAction') && !this.get('actionDate')) {
        return { isValid: false, error: 'Whoops! You need to specify a date.' };
      }if (!this.get('notes') || this.get('notes').length === 0) {
        return { isValid: false, error: 'Whoops! You forgot to add a note.' };
      }
      return { isValid: true, error: null };
    },


    // Actions
    actions: {
      createPhoneCall: function createPhoneCall(state) {
        var _this = this;

        var validation = this.validate();

        if (!validation.isValid) {
          this.get('notifications').warning(validation.error);
          return;
        }

        var member = this.get('selectedMember') || this.get('group.quoteOriginMember');

        var newCall = this.get('store').createRecord('phoneCall', {
          calledAt: (0, _moment.default)(),
          group: this.get('group'),
          notes: this.get('notes'),
          user: this.get('session.currentUser'),
          member: member,
          scheduledAt: (0, _moment.default)(),
          state: state
        });
        newCall.save().then(function () {
          _this.get('notifications').success('Phone call successfully created.');

          if (_this.get('isScheduleAction')) {
            newCall = _this.get('store').createRecord('phoneCall', {
              group: _this.get('group'),
              scheduledAt: _this.get('actionDateTime'),
              user: _this.get('session.currentUser'),
              member: member,
              state: 'scheduled'
            });
            newCall.save().then(function () {
              _this.get('notifications').success('Successfully scheduled a new call.');
            }).catch(function (error) {
              _this.get('notifications').error(_this.decodeJsonApiErrors(error, newCall), { htmlContent: true });
            });
          } else if (_this.get('isPipelineAction')) {
            var group = _this.get('group');
            var hideUntilAttribute = _this.get('hideUntilAttribute');
            var actionDate = _this.get('actionDate');
            group.set(hideUntilAttribute, actionDate);
            group.setPipeline(_defineProperty({}, hideUntilAttribute, actionDate.format())).then(function () {
              _this.get('notifications').success('Successfully pipelined the group.');
            }).catch(function (error) {
              _this.get('notifications').error(_this.decodeJsonApiErrors(error), { htmlContent: true });
            });
          }
          _this.set('notes', '');
        }).catch(function (error) {
          _this.get('notifications').error(_this.decodeJsonApiErrors(error, newCall), { htmlContent: true });
        });
      },
      markPhoneCallAnswered: function markPhoneCallAnswered() {
        this.send('createPhoneCall', 'answered');
      },
      markPhoneCallUnanswered: function markPhoneCallUnanswered() {
        this.send('createPhoneCall', 'unanswered');
      },
      selectActionType: function selectActionType(typeName) {
        if (event.target.checked) {
          this.set('actionType', typeName);
        } else {
          this.set('actionType', undefined);
        }
      },
      selectMember: function selectMember(member) {
        this.set('selectedMember', member);
      },
      toggleAction: function toggleAction() {
        this.set('actionEnabled', !this.get('actionEnabled'));
      }
    }
  });
});