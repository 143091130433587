define('mvp-web/mixins/active-steps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    _setActiveStep: function _setActiveStep(stepNumber) {
      var updatedSteps = this.get('steps').reduce(function (accumulator, step) {
        var newStep = _extends({}, step);
        if (step.number < stepNumber) {
          newStep.status = 'done';
        } else if (step.number === stepNumber) {
          newStep.status = 'active';
        } else {
          newStep.status = null;
        }

        accumulator.push(newStep);

        return accumulator;
      }, []);

      this.set('steps', updatedSteps);
    },


    actions: {
      setActiveStep: function setActiveStep(stepNumber) {
        this._setActiveStep(stepNumber);
      }
    }
  });
});