define('mvp-web/components/group-organization-transfer-dashboard/group-organization-transfer-dashboard-filters', ['exports', 'mvp-web/mixins/power-select-highlighted', 'mvp-web/mixins/errors'], function (exports, _powerSelectHighlighted, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      computed = Ember.computed,
      observer = Ember.observer;
  exports.default = Component.extend(_errors.default, _powerSelectHighlighted.default, {
    store: service(),
    notifications: service('notification-messages'),
    session: service(),
    groupOrganizationTransferDashboardFilter: service(),

    isFilterExpanded: false,

    _order: null,
    dateLimit: { days: 730 },

    selectedChildOrganizations: [],
    // need to set on init ^
    selectedChildOrganizationIdsDidChange: observer('selectedChildOrganizations.[]', function () {
      var childOrganizationIds = this.get('selectedChildOrganizations').map(function (co) {
        return co.get('id');
      });
      this.set('selectedChildOrganizationIds', childOrganizationIds);
    }),

    childOrganizations: computed('session.currentUserOrganization.childOrganizations', function () {
      return this.get('session.currentUserOrganization.childOrganizations');
    }),

    actions: {
      toggleFilterView: function toggleFilterView() {
        this.set('isFilterExpanded', !this.get('isFilterExpanded'));
      },
      handleDateRangeChanged: function handleDateRangeChanged(dateFrom, dateTo) {
        this.setProperties({ dateFrom: dateFrom, dateTo: dateTo });
      },
      setOrder: function setOrder(option) {
        this.setProperties({ _order: option, order: option.value });
      },
      filterChildOrganizations: function filterChildOrganizations(childOrganizations) {
        this.sendAction('setPage', 1);
        this.set('selectedChildOrganizations', childOrganizations);
      }
    }
  });
});