define('mvp-web/controllers/leads/new', ['exports', 'mvp-web/controllers/groups/new'], function (exports, _new) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _new.default.extend({
    detailsPath: 'leads.new',
    transitionStateIsProspect: false,
    transitionStateIsCustomer: false,
    cancelModal: null,
    queryParams: ['memberId'],
    memberId: null,

    actions: {
      cancel: function cancel() {
        this.set('cancelModal', Ember.$('#cancel-lead-modal'));
        this.get('cancelModal').modal('show');
      },
      cancelLead: function cancelLead() {
        this.send('cancelGroupAndLeaveChanges');

        var cancelModal = this.get('cancelModal');
        if (cancelModal) cancelModal.modal('hide');

        history.back();
      }
    }
  });
});