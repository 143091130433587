define('mvp-web/mixins/power-select-highlighted', ['exports', 'ember-basic-dropdown/utils/calculate-position'], function (exports, _calculatePosition2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone,
      isPresent = Ember.isPresent;
  exports.default = Ember.Mixin.create({
    calculatePosition: function calculatePosition() {
      var epsValues = _calculatePosition2.default.apply(undefined, arguments);

      var _ref = [].concat(Array.prototype.slice.call(arguments)),
          triggerElement = _ref[0];

      var DROPDOWN_MAX_WIDTH = 300;

      var _triggerElement$getBo = triggerElement.getBoundingClientRect(),
          triggerWidth = _triggerElement$getBo.width,
          triggerLeft = _triggerElement$getBo.left;

      var viewportWidth = self.document.body.clientWidth || self.window.innerWidth;

      var adjustedTriggerWidth = DROPDOWN_MAX_WIDTH - DROPDOWN_MAX_WIDTH * 0.1;

      if (isNone(epsValues.style)) {
        var style = {
          width: DROPDOWN_MAX_WIDTH + 'px'
        };

        if (triggerWidth > DROPDOWN_MAX_WIDTH || adjustedTriggerWidth < triggerWidth) {
          style.width = triggerWidth + 'px';
        }

        epsValues.style = style;
      }

      if (isPresent(epsValues.style)) {
        if (triggerWidth > DROPDOWN_MAX_WIDTH || adjustedTriggerWidth < triggerWidth) {
          epsValues.style.width = triggerWidth;
        } else {
          epsValues.style.width = DROPDOWN_MAX_WIDTH;
        }
      }

      var leftVisible = Math.min(viewportWidth, triggerLeft + DROPDOWN_MAX_WIDTH) - Math.max(0, triggerLeft);
      var rightVisible = Math.min(viewportWidth, triggerLeft + triggerWidth) - Math.max(0, triggerLeft + triggerWidth - DROPDOWN_MAX_WIDTH);

      if (DROPDOWN_MAX_WIDTH > leftVisible && rightVisible > leftVisible) {
        epsValues.horizontalPosition = 'right';
      }

      return epsValues;
    },


    actions: {
      selectHighlighted: function selectHighlighted(selection, e) {
        if (e && e.keyCode === 9) {
          var valueToSet = selection.highlighted ? selection.highlighted : selection.selected;
          selection.actions.choose(valueToSet, e);
          return;
        }

        if (e && e.keyCode !== 9) {
          Ember.set(selection, 'selected', undefined);
          return;
        }

        if (selection.selected) {
          selection.actions.choose(selection.selected, e);
        }
      }
    }
  });
});