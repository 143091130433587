define('mvp-web/mixins/contextual-pipeline-until', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Mixin.create({
    isCommercialFocus: computed.equal('quoteTypeFocus', 'commercial'),

    pipelineUntilLabel: computed('isCommercialFocus', function () {
      if (this.get('isCommercialFocus')) {
        return 'Commercial Pipeline until';
      }

      return 'Pipeline until';
    }),

    hideUntilAttribute: computed('isCommercialFocus', function () {
      if (this.get('isCommercialFocus')) {
        return 'commercialHideUntil';
      }

      return 'hideUntil';
    }),

    hideUntilAttributes: computed(function () {
      var attributes = [];
      if (this.get('group.hasCommercialQuotes') || this.get('group.hasCommercials')) attributes.push('commercialHideUntil');
      if (this.get('group.hasNonCommercialQuotes')) attributes.push('hideUntil');

      return attributes;
    })
  });
});