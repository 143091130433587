define('mvp-web/components/sold-policies', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    soldPolicies: computed('group.id', function () {
      var groupId = this.get('group.id');

      // eslint-disable-next-line camelcase
      return this.get('store').query('sold-policy', { group_id: groupId });
    })
  });
});