define('mvp-web/models/commercial', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'ember-cp-validations'], function (exports, _relationships, _attr, _model, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    businessName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Business Name'
    }),
    bestContact: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Best Contact'
    }),
    businessPhone: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Best Phone'
    }), (0, _emberCpValidations.validator)('format', {
      type: 'phone',
      allowBlank: false,
      message: 'Must be a valid phone number'
    })],
    establishedYear: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 }),
    fullTimeEmployeesCount: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 }),
    partTimeEmployeesCount: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 }),
    estimatedAnnualPayroll: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 }),
    annualSalesRevenue: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 }),
    buildingSquareFeet: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 }),
    buildingTenantImprovements: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 }),
    buildingCostSign: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 }),
    buildingContents: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 }),
    locationsCount: (0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, gte: 0 })
  });

  exports.default = _model.default.extend(Validations, {
    group: (0, _relationships.belongsTo)('group', { polymorphic: true, inverse: 'commercials', async: false }),
    address: (0, _relationships.belongsTo)('address'),
    commercialClaims: (0, _relationships.hasMany)('commercialClaim'),
    bestContact: (0, _attr.default)(),
    businessName: (0, _attr.default)(),
    businessPhone: (0, _attr.default)(),
    einTinSsNumber: (0, _attr.default)(),
    sicCode: (0, _attr.default)(),
    websiteAddress: (0, _attr.default)(),
    dbaName: (0, _attr.default)(),
    entityType: (0, _attr.default)('string', { defaultValue: 'Corporation' }),
    establishedYear: (0, _attr.default)('number'),
    fullTimeEmployeesCount: (0, _attr.default)('number'),
    partTimeEmployeesCount: (0, _attr.default)('number'),
    estimatedAnnualPayroll: (0, _attr.default)('number'),
    annualSalesRevenue: (0, _attr.default)('number'),
    buildingType: (0, _attr.default)(),
    buildingSquareFeet: (0, _attr.default)('number'),
    buildingTenantImprovements: (0, _attr.default)('number'),
    buildingCostSign: (0, _attr.default)('number'),
    buildingContents: (0, _attr.default)('number'),
    buildingOwnership: (0, _attr.default)('string', { defaultValue: 'Own' }),
    locationsCount: (0, _attr.default)('number'),
    operationsDescription: (0, _attr.default)(),
    notes: (0, _attr.default)(),
    title: (0, _attr.default)()
  });
});