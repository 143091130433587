define('mvp-web/mixins/home-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    homeProperties: computed('model.homes.@each.address', 'model.homes.[]', 'group.homes.@each.address', 'group.homes.[]', function () {
      var homes = this.get('model.homes.content') || this.get('group.homes.content');

      if (homes) {
        return homes.map(function (home) {
          return home.get('address.content');
        });
      }
    })
  });
});