define('mvp-web/adapters/stalled-quote', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    retrieveStalledQuoteCount: function retrieveStalledQuoteCount() {
      return this.ajax('/api/v1/stalled_quotes/count', 'GET');
    }
  });
});