define('mvp-web/models/task-sequence-template', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-cp-validations'], function (exports, _model, _attr, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    dueDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Due Date'
    })
  });

  exports.default = _model.default.extend(Validations, {
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    dueDate: (0, _attr.default)('string', { defaultValue: 'd' }),

    timeUnit: computed('dueDate', function () {
      // return non-numeric characters in duedate
      var value = this.get('dueDate').match(/([a-zA-Z]+)/g);

      return value ? value[0] : null;
    }),

    timeValue: computed('dueDate', function () {
      // return numeric characters in dueDate
      var value = this.get('dueDate').match(/([0-9]+)/g);

      return value ? value[0] : null;
    })
  });
});