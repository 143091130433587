define('mvp-web/models/metrics/premiums-sold', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _model.default.extend({
    byDate: (0, _attr.default)(),
    byLob: (0, _attr.default)(),
    byUser: (0, _attr.default)(),
    total: (0, _attr.default)(),

    agentsActivityChart: service(),

    byUserChart: null,

    createByUserChart: function createByUserChart(element) {
      var chart = this.get('agentsActivityChart').createChart({
        activitiesByUser: this.get('byUser'),
        element: element,
        isCurrency: true
      });

      this.set('byUserChart', chart);
    },
    refreshByUserChart: function refreshByUserChart() {
      this.get('agentsActivityChart').refresh(this.get('byUserChart'));
    }
  });
});