define('mvp-web/models/subscription', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    organization: (0, _relationships.belongsTo)('organization'),
    subscriptionEvent: (0, _relationships.hasMany)('subscriptionEvent'),
    subscriptionPlan: (0, _relationships.belongsTo)('subscriptionPlan'),
    isActive: (0, _attr.default)('boolean'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    canceledAt: (0, _attr.default)('date')
  });
});