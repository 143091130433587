define('mvp-web/components/mvp-form-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    hasNoError: computed.not('error'),
    showInvalid: false,
    classNames: ['form-group', 'mvp-form-group'],
    labelClass: '',

    tooltipTemplate: '<div class=\'tooltip mvp-form-group-tooltip has-error\' role=\'tooltip\'><div class=\'tooltip-arrow\'></div><div class=\'tooltip-inner\'></div></div>'

  });
});