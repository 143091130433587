define('mvp-web/components/mvp-checkbox', ['exports', 'mvp-web/mixins/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_errors.default, {
    classNames: ['mvp-checkbox'],
    customClasses: '',

    actions: {
      toggleStatus: function toggleStatus() {
        this.toggleProperty('isChecked');

        if (this.get('toggleAction')) {
          // this could probably be refactored but it would require other components passing a toggleAction to change to
          // expect an object payload or something like that, but this is the simplest refactor for backwards compatibility.
          if (this.get('togglePayload')) {
            this.sendAction('toggleAction', this.get('isChecked'), this.get('togglePayload'));
          } else {
            this.sendAction('toggleAction', this.get('isChecked'));
          }
        }
      }
    }
  });
});