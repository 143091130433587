define('mvp-web/components/message-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),
    session: service(),
    classNames: ['chat-msg-container'],
    classNameBindings: ['isSender:sender:receiver', 'isSending:sending', 'err'],

    messageComponentName: computed(function () {
      var modelName = this.get('message.constructor.modelName') || 'messages/text';
      return 'message-items/' + modelName;
    }),

    isSender: computed(function () {
      var sender = this.get('message.sender.content');

      if (Ember.isBlank(sender)) {
        return true;
      }

      var modelName = sender.constructor.modelName || sender.modelName;
      if (modelName.match(/member/)) {
        return false;
      }

      return true;
    }),

    isAutomated: computed.bool('message.isAutomated'),

    isSending: computed('message.createdAt', function () {
      return this.get('message.createdAt') === undefined;
    }),

    senderName: computed('isAutomated', function () {
      if (this.get('isAutomated')) return 'Workflow';

      return this.get('message.sender.firstName');
    }),

    err: computed.not('message.isValid')
  });
});