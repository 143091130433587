define('mvp-web/models/tag', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)(),
    taggings_count: (0, _attr.default)(), // eslint-disable-line camelcase
    org_taggings_count: (0, _attr.default)() // eslint-disable-line camelcase
  });
});