define('mvp-web/adapters/lead-open-api-import', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    requestRefund: function requestRefund(exclusiveLeadId, refundRequestComment) {
      var url = this.get('namespace') + '/refund_requests/' + exclusiveLeadId + '/create';
      var data = {
        data: {
          refund_request_comment: refundRequestComment
        }
      };

      return this.ajax(url, 'POST', data);
    }
  });
});