define('mvp-web/routes/groups/missed-appointments', ['exports', 'mvp-web/routes/groups/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    queryParams: {
      missed_appointments: { refreshModel: true, type: 'boolean' } // eslint-disable-line camelcase
    },

    model: function model(params) {
      var baseParams = this.prepareParams(params);

      return {
        previousRegularTask: this.get('getRegularModel.lastSuccessful'),
        currentRegularTask: this.get('getRegularModel').perform(baseParams)
      };
    }
  });
});