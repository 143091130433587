define('mvp-web/models/policy-type', ['exports', 'ember-data/relationships', 'ember-data', 'ember-data/attr'], function (exports, _relationships, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: (0, _attr.default)(),
    existingPolicies: (0, _relationships.hasMany)('existingPolicy')
  });
});