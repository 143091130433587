define('mvp-web/components/link-with-async-count', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['_link-with-async-count', 'd-inline', 'float-start'],

    title: computed(function () {
      return this.get('label');
    })
  });
});