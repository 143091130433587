define('mvp-web/helpers/display-parenthesise-int', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.displayParenthesiseInt = displayParenthesiseInt;
  function displayParenthesiseInt(params) {
    var number = params[0];
    if (params[1]) {
      return '(' + number + ')';
    }
    return number;
  }

  exports.default = Ember.Helper.helper(displayParenthesiseInt);
});