define('mvp-web/adapters/acxiom', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    enrichHousehold: function enrichHousehold(_ref) {
      var groupId = _ref.groupId;

      var url = this.get('namespace') + '/acxiom';

      return this.ajax(url, 'POST', {
        data: {
          group_id: groupId
        }
      });
    }
  });
});