define('mvp-web/adapters/address', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    closestMatches: function closestMatches(payload) {
      var url = this.buildURL('autocomplete_addresses');
      return this.ajax(url, 'GET', { data: payload });
    }
  });
});