define('mvp-web/serializers/vehicle', ['exports', 'mvp-web/serializers/application', 'mvp-web/mixins/embedded-relationships'], function (exports, _application, _embeddedRelationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRelationships.default, {
    attrs: {
      makeSelection: { serialize: false },
      modelSelection: { serialize: false },
      trimSelection: { serialize: false },
      yearSelection: { serialize: false },
      lienholderStateSelection: { serialize: false }
    }
  });
});