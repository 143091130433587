define('mvp-web/transforms/array', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray,
      A = Ember.A;
  exports.default = _transform.default.extend({
    deserialize: function deserialize(value) {
      if (isArray(value)) {
        return A(value);
      }
      return A();
    },
    serialize: function serialize(value) {
      if (isArray(value)) {
        return A(value);
      }
      return A();
    }
  });
});