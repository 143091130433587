define('mvp-web/components/phone-numbers/edit-phone-number/phone-number-actions/ivr-node', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),

    phoneNumberAction: computed.alias('node.phoneNumberAction'),
    phoneNumberActionNotEmpty: computed.notEmpty('node.phoneNumberAction'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    _createPhoneNumberAction: function _createPhoneNumberAction(phoneNumberAction) {
      return this.get('store').createRecord(phoneNumberAction.modelName, _extends({}, phoneNumberAction));
    },
    _setPhoneNumberAction: function _setPhoneNumberAction(phoneNumberAction) {
      if (this.get('phoneNumberActionNotEmpty') && this.get('phoneNumberAction.isNew')) {
        this.get('phoneNumberAction.content').destroyRecord();
      }

      this.set('phoneNumberAction', this._createPhoneNumberAction(phoneNumberAction));
    },


    actions: {
      changeMatchValue: function changeMatchValue(matchValue) {
        var node = this.get('node');
        var oldMatchValue = node.get('selectedMatchValue');
        var newMatchValue = { matchValue: '' };

        if (matchValue.matchValue != '') {
          newMatchValue = { matchValue: matchValue.matchValue, disabled: true };
        }

        node.set('matchValue', newMatchValue.matchValue);
        node.set('selectedMatchValue', newMatchValue);
        this.sendAction('changeMatchValue', { oldMatchValue: oldMatchValue, newMatchValue: newMatchValue });
      },
      removeNode: function removeNode() {
        var node = this.get('node');

        if (node.get('isRecordNew')) node.unloadRecord();
        if (node.get('phoneNumberAction.isNew')) node.get('phoneNumberAction.content').destroyRecord();

        this.sendAction('removeNode', node.get('id'));
      },
      setPhoneNumberAction: function setPhoneNumberAction(phoneNumberAction) {
        this._setPhoneNumberAction(phoneNumberAction);
      }
    }
  });
});