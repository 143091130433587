define('mvp-web/transforms/custom-date', ['exports', 'ember-data/transform', 'moment'], function (exports, _transform, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    serialize: function serialize(value) {
      if (value && _moment.default.isMoment(value)) {
        return value.format('YYYY/MM/DD');
      }
      return value;
    },
    deserialize: function deserialize(value) {
      return value ? (0, _moment.default)(value, 'YYYY/MM/DD') : null;
    }
  });
});