define('mvp-web/components/workflow-tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    showAction: computed.and('group.atWorkflowHistories.length', 'group.assignedWorkflow'),
    title: computed('group.isWorkflowLoading', 'group.assignedWorkflow', function () {
      var group = this.get('group');
      if (group.get('isWorkflowLoading')) return '';
      return group.get('assignedWorkflow') ? group.get('assignedWorkflow.name') : 'No Workflow';
    })

  });
});