define('mvp-web/models/payment-option', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isNone = Ember.isNone;
  exports.default = _model.default.extend({
    name: (0, _attr.default)(),
    payFrequency: (0, _attr.default)('number'),
    appliesFor: (0, _attr.default)(),
    disabledFields: (0, _attr.default)('array', { defaultValue: [] }),

    appliesForVehicle: computed('appliesFor', function () {
      return this.appliesForQuote('vehicle');
    }),

    appliesForHome: computed('appliesFor', function () {
      return this.appliesForQuote('home');
    }),

    appliesForLife: computed('appliesFor', function () {
      return this.appliesForQuote('life');
    }),

    appliesForSpecialty: computed('appliesFor', function () {
      return this.appliesForQuote('specialty');
    }),

    appliesForUmbrella: computed('appliesFor', function () {
      return this.appliesForQuote('umbrella');
    }),

    appliesForCommercial: computed('appliesFor', function () {
      return this.appliesForQuote('commercial');
    }),

    appliesForQuote: function appliesForQuote(quoteType) {
      if (isNone(this.get('appliesFor'))) return;

      return this.get('appliesFor').indexOf(quoteType) > -1;
    }
  });
});