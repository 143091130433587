define('mvp-web/services/group-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    customFilterOptions: [{ label: 'Email Address', value: 'hasEmailAddress' }, { label: 'Competitor Carrier', value: 'currentCarriers' }, { label: 'Your Carrier', value: 'firstPartyCarriers' }, { label: 'Renewal Date', value: 'renewalDate' }, { label: 'Zip Code', value: 'zipCode' }, { label: 'Date Of Birth', value: 'dateOfBirth' }, { label: 'Your Policy Renewal', value: 'policyRenewalDate' }, { label: 'Marketing Source', value: 'marketingSource' }, { label: 'State', value: 'usState' }],

    months: [{ label: 'January', value: 1 }, { label: 'February', value: 2 }, { label: 'March', value: 3 }, { label: 'April', value: 4 }, { label: 'May', value: 5 }, { label: 'June', value: 6 }, { label: 'July', value: 7 }, { label: 'August', value: 8 }, { label: 'September', value: 9 }, { label: 'October', value: 10 }, { label: 'November', value: 11 }, { label: 'December', value: 12 }],

    zipCodes: []
  });
});