define('mvp-web/components/toggle-scores-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    session: service(),

    // classNames: ['d-flex', 'justify-content-lg-end', 'mb-3'],
    // classNames: ['mb-3'],

    actions: {
      toggleScores: function toggleScores() {
        var scores = Ember.$('.calculated-scores');

        if (scores.hasClass('hidden')) {
          scores.removeClass('hidden');
        } else {
          scores.addClass('hidden');
        }
      }
    }
  });
});