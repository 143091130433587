define('mvp-web/components/confirm-bootstrap-popover', ['exports', 'mvp-web/mixins/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend(_errors.default, {
    notifications: service('notification-messages'),

    classNameBindings: ['isButton:popover-button', 'isButton:btn', 'isButton:btn-xs', 'isButton:btn-default'],
    closeButtonLabel: 'Proceed',
    customClassName: null,
    customEventName: null,
    model: null,
    isButton: true,
    placement: 'top',
    tagName: 'a',
    type: 'button',
    'max-width': '250',
    title: null,
    closeOriginalButton: 'Close',

    didInsertElement: function didInsertElement() {
      var component = this;
      var contents = this.$('.bootstrap-popover');

      component.set('originalButtonLabel', component.get('buttonLabel'));

      component.$().popover({
        container: 'body',
        content: contents,
        html: true,
        placement: component.get('placement'),
        title: component.get('title'),
        trigger: 'manual'

      }).on('show.bs.popover', function () {
        contents.removeClass('hide');
        component.set('buttonLabel', component.get('closeOriginalButton'));
        component.$().data('bs.popover').tip().css({ 'max-width': component.get('max-width') + 'px' }).addClass(component.get('customClassName'));
      }).on('hide.bs.popover', function () {
        component.set('buttonLabel', component.get('originalButtonLabel'));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$().popover('destroy');
    },
    click: function click() {
      this.$().popover('toggle');
    },


    actions: {
      cancel: function cancel() {
        if (this.model && this.model.id) {
          this.model.rollbackAttributes();
        }

        if (this.$()) {
          this.$().popover('hide');
        }
      },
      confirm: function confirm() {
        var model = this.model;


        if (this.customEventName) {
          this.sendAction(this.customEventName, model);
          this.$().popover('hide');
        } else {
          this.$().popover('hide');
        }
      }
    }
  });
});