define('mvp-web/components/quote-forms/shared/renewal-and-effective-date-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    tagName: '',
    showDateLabel: true
  });
});