define('mvp-web/models/quote/api/import', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    group: (0, _relationships.belongsTo)('group'),
    provider: (0, _attr.default)(),
    quoteType: (0, _attr.default)(),
    status: (0, _attr.default)(),
    allianceLink: (0, _attr.default)()
  });
});