define('mvp-web/components/groups-table/group-skeleton-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    tagName: 'tbody',

    noOfCols: computed('cols', function () {
      return new Array(this.get('cols'));
    }),

    noOfRows: computed('isSimple', function () {
      if (!this.get('isSimple')) return new Array(4);
      return new Array(1);
    })

  });
});