define('mvp-web/services/invalidate-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Service = Ember.Service;
  exports.default = Service.extend({
    session: service(),

    invalidate: function invalidate() {
      var session = this.get('session');

      if (!session.get('isAuthenticated')) return;

      Ember.$.ajax({
        url: '/users/logout',
        method: 'delete',
        success: function success() {
          session.invalidate();
        }
      });
    }
  });
});