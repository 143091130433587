define('mvp-web/adapters/at-workflow', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    headers: {
      Accept: 'application/json, text/javascript; api_version=2'
    },

    pathForType: function pathForType() {
      return 'at_workflows';
    }
  });
});