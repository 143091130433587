define('mvp-web/mixins/abstract-date-to-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    timeUnits: [{ id: 'y', name: 'year', dayValue: 365.0 }, { id: 'mo', name: 'month', dayValue: 30.0 }, { id: 'd', name: 'day', dayValue: 1.0 }, { id: 'h', name: 'hour', dayValue: 0.0416 }, { id: 'm', name: 'minute', dayValue: 0.000694 }],

    abstractDateToValue: function abstractDateToValue(taskSequenceTemplate) {
      var units = this.get('timeUnits');
      var timeUnit = units.find(function (unit) {
        return unit.id === taskSequenceTemplate.get('timeUnit');
      });

      return timeUnit.dayValue * parseFloat(taskSequenceTemplate.get('timeValue'));
    }
  });
});