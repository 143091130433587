define('mvp-web/models/message', ['exports', 'ember-data/attr', 'ember-data/relationships', 'mvp-web/models/contact'], function (exports, _attr, _relationships, _contact) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contact.default.extend({
    body: (0, _attr.default)('string'),
    chatRoom: (0, _relationships.belongsTo)('chat-room', { inverse: 'messages' }),
    createdAt: (0, _attr.default)('utc'),
    isAutomated: (0, _attr.default)('boolean'),
    readAt: (0, _attr.default)('utc'),
    sender: (0, _relationships.belongsTo)('sender', { polymorphic: true }),
    updatedAt: (0, _attr.default)('utc'),

    hasAttachment: false
  });
});