define('mvp-web/components/test-email-footer-catalyst', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      }
    }
  });
});