define('mvp-web/models/premium-estimations/scraping-request', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-cp-validations'], function (exports, _model, _attr, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'First Name'
    }),
    lastName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Last Name'
    }),
    postalCode: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Postal Code'
    }),
    dateOfBirth: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Date of Birth'
    }),
    insuranceCarrierId: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Insurance Carrier'
    }), (0, _emberCpValidations.validator)('carrier-scraping-key')],
    paymentTermId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Policy Term'
    })
  });

  exports.default = _model.default.extend(Validations, {
    policyNumber: (0, _attr.default)(),
    firstName: (0, _attr.default)(),
    lastName: (0, _attr.default)(),
    postalCode: (0, _attr.default)(),
    dateOfBirth: (0, _attr.default)(),
    policyRenewalDate: (0, _attr.default)('custom-date'),
    insuranceCarrierId: (0, _attr.default)('number'),
    paymentTermId: (0, _attr.default)(),
    policyTermInMonths: (0, _attr.default)('number'),
    createdAt: (0, _attr.default)('utc'),
    updatedAt: (0, _attr.default)('utc')
  });
});