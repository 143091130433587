define('mvp-web/models/count', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    awaitingQuotes: (0, _attr.default)(),
    crossSellCallAttemptsForToday: (0, _attr.default)(),
    crossSellHomeCustomers: (0, _attr.default)(),
    crossSellVehicleCustomers: (0, _attr.default)(),
    crossSellLifeCustomers: (0, _attr.default)(),
    crossSellUmbrellaCustomers: (0, _attr.default)(),
    crossSellSpecialtyCustomers: (0, _attr.default)(),
    crossSellCommercialCustomers: (0, _attr.default)(),
    homeCustomers: (0, _attr.default)(),
    hotLeads: (0, _attr.default)(),
    newLeads: (0, _attr.default)(),
    myLeads: (0, _attr.default)(),
    callAttemptsForToday: (0, _attr.default)(),
    leadCallAttemptsForToday: (0, _attr.default)(),
    prospectCallAttemptsForToday: (0, _attr.default)(),
    quoted: (0, _attr.default)(),
    totalCustomers: (0, _attr.default)(),
    totalLeads: (0, _attr.default)(),
    totalProspects: (0, _attr.default)(),
    vehicleCustomers: (0, _attr.default)(),

    pipelinedLeads: (0, _attr.default)(),
    pipelinedProspects: (0, _attr.default)(),
    pipelinedCustomers: (0, _attr.default)(),

    missedLeadsAppointments: (0, _attr.default)(),
    missedProspectsAppointments: (0, _attr.default)(),
    missedCustomersAppointments: (0, _attr.default)(),

    nextLeadAppointmentsName: (0, _attr.default)(),
    nextProspectAppointmentsName: (0, _attr.default)(),
    nextCustomerAppointmentsName: (0, _attr.default)(),

    nextLeadAppointmentsId: (0, _attr.default)(),
    nextProspectAppointmentsId: (0, _attr.default)(),
    nextCustomerAppointmentsId: (0, _attr.default)(),

    nextLeadAppointmentsTime: (0, _attr.default)(),
    nextProspectAppointmentsTime: (0, _attr.default)(),
    nextCustomerAppointmentsTime: (0, _attr.default)(),

    referralLeads: (0, _attr.default)(),

    totalHouseholdCalledToday: computed('leadCallAttemptsForToday', 'prospectCallAttemptsForToday', 'crossSellCallAttemptsForToday', function () {
      return this.get('leadCallAttemptsForToday') + this.get('prospectCallAttemptsForToday') + this.get('crossSellCallAttemptsForToday');
    }),

    totalCrossSellCount: computed('crossSellHomeCustomers', 'crossSellVehicleCustomers', 'crossSellLifeCustomers', 'crossSellUmbrellaCustomers', 'crossSellSpecialtyCustomers', 'crossSellCommercialCustomers', function () {
      return this.get('crossSellHomeCustomers') + this.get('crossSellVehicleCustomers') + this.get('crossSellLifeCustomers') + this.get('crossSellUmbrellaCustomers') + this.get('crossSellSpecialtyCustomers') + this.get('crossSellCommercialCustomers');
    })
  });
});