define('mvp-web/mixins/validation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    validate: function validate(model) {
      var pass = true;
      var attributes = Array.prototype.slice.call(arguments, 1) || [];

      attributes.forEach(function (attribute) {
        if (!model.get(attribute)) {
          model.get('errors').add(attribute, 'is required!');
          pass = false;
        }
      });

      return pass;
    }
  });
});