define('mvp-web/components/labeled-radio-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'label',
    classNames: ['btn', 'btn-default', 'btn-sm'],
    classNameBindings: ['active'],

    active: computed('groupValue', 'value', function () {
      return this.get('groupValue') === this.get('value');
    })
  });
});