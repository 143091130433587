define('mvp-web/components/dashboard/performance-statistics/csv-export', ['exports', 'moment', 'mvp-web/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;

  var dateFormat = 'YYYYMMDD';

  exports.default = Ember.Component.extend({
    classNames: ['__dashboard_performance-statistics_csv-export'],

    endDate: computed(function () {
      return (0, _moment.default)().format(dateFormat);
    }),

    startDate: computed(function () {
      return (0, _moment.default)().subtract(1, 'weeks').format(dateFormat);
    }),

    csvAbsolutePath: computed('startDate', 'endDate', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      var apiVersion = _environment.default.apiEndpointVersion;
      var uriString = apiVersion + '/stats_data_points.csv?filter[start_date]=' + startDate + '&filter[end_date]=' + endDate;

      return encodeURI(uriString);
    }),

    actions: {
      handleDateRangeChanged: function handleDateRangeChanged(startDate, endDate) {
        this.setProperties({ startDate: startDate, endDate: endDate });
      }
    }
  });
});