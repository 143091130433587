define('mvp-web/components/at-workflow-form/user-weight-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    user: null,
    roundRobinConfiguration: {},
    selectedValue: computed('roundRobinConfiguration.[]', function () {
      var config = this.get('roundRobinConfiguration');
      var id = this.get('userId');
      if (config.user_weighted === null || config.user_weighted === undefined) return null;
      return config.user_weighted[id];
    }),
    roundRobinWeightOptions: [],
    nameToDisplay: null
  });
});