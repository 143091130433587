define('mvp-web/components/groups-tabs/prospects', ['exports', 'mvp-web/components/groups-tabs/group-tab'], function (exports, _groupTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _groupTab.default.extend({
    totalProspects: computed('count.totalProspects', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.totalProspects');
    }),

    quoted: computed('count.quoted', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.quoted');
    }),

    awaitingQuotes: computed('count.awaitingQuotes', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.awaitingQuotes');
    }),

    missedProspectsAppointments: computed('count.missedProspectsAppointments', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.missedProspectsAppointments');
    }),

    pipelinedProspects: computed('count.pipelinedProspects', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.pipelinedProspects');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // 4 is the count ID for prospect stats
      this.get('fetchCount').perform(4);
    }
  });
});