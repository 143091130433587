define('mvp-web/validations/policy-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    renewalDate: [(0, _validators.validatePresence)(true)],
    policyTerm: [(0, _validators.validatePresence)(true)],
    currentPremium: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0 })],
    policyType: [(0, _validators.validatePresence)(true)]
  };
});