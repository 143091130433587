define('mvp-web/components/at-workflow-form/organization-weight-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    roundRobinConfiguration: {},
    selectedValue: computed('roundRobinConfiguration.[]', function () {
      var config = this.get('roundRobinConfiguration');
      var id = this.get('organizationId');
      if (config.weighted === null || config.weighted === undefined) return null;
      return config.weighted[id];
    }),
    roundRobinWeightOptions: [],
    nameToDisplay: null
  });
});