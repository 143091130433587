define('mvp-web/serializers/phone-number-actions/voicemail-action', ['exports', 'mvp-web/serializers/phone-number-action', 'mvp-web/mixins/embedded-relationships'], function (exports, _phoneNumberAction, _embeddedRelationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _phoneNumberAction.default.extend(_embeddedRelationships.default, {
    attrs: {
      users: { serialize: false }
    }
  });
});