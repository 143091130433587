define('mvp-web/routes/exports/history', ['exports', 'rollbar'], function (exports, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed,
      Route = Ember.Route;
  exports.default = Route.extend({
    session: service(),
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    model: function model() {
      var _this = this;

      this.store.adapterFor('organizationExport').set('organizationId', this.get('session.session.authenticated.organization_id'));

      return this.store.findAll('organizationExport').then(function (data) {
        return Ember.ArrayProxy.extend({
          arrangedContent: computed.sort('content', 'props'),
          props: ['createdAt:desc']
        }).create({ content: data });
      }).catch(function (error) {
        _rollbar.default.error(error);
        _this.replaceWith('/not-found');
      });
    },


    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});