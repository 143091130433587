define('mvp-web/adapters/message', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var chatRoomId = snapshot.record.get('chatRoom').get('id');
      return '/api/v1/chat_rooms/' + chatRoomId + '/create_message';
    },
    urlForQuery: function urlForQuery(query) {
      var chatRoomId = query.chatRoomId;

      return '/api/v1/chat_rooms/' + chatRoomId + '/messages';
    },
    urlForFindUserMessages: function urlForFindUserMessages(query) {
      var page = query.page,
          size = query.size,
          memberId = query.memberId;

      return this.ajax('' + ('/api/v1/chat_rooms' + '?page=') + page + '&size=' + size + '&user_ids=' + memberId, 'GET');
    },
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      var chatRoomId = snapshot.adapterOptions.chatRoomId;

      return '/api/v1/chat_rooms/' + chatRoomId + '/messages';
    },
    urlForFindUnreadReplies: function urlForFindUnreadReplies(query) {
      var page = query.page,
          size = query.size,
          isUnreadReply = query.isUnreadReply,
          memberId = query.memberId;

      return this.ajax('' + ('/api/v1/chat_rooms' + '?page=') + page + '&size=' + size + '&unread_replies=' + isUnreadReply + '&user_ids=' + memberId, 'GET');
    },
    removeUserFromGroupChat: function removeUserFromGroupChat(chatRoomId, memberId) {
      return this.ajax('/api/v1/chat_rooms/' + chatRoomId + '/users/' + memberId, 'DELETE');
    },
    addUserToGroupChat: function addUserToGroupChat(chatRoomId, memberId) {
      return this.ajax('/api/v1/chat_rooms/' + chatRoomId + '/users', 'POST', { data: { user_id: memberId } });
    }
  });
});