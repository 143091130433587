define('mvp-web/models/home-claim', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'moment', 'ember-cp-validations'], function (exports, _relationships, _attr, _model, _moment, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isEmpty = Ember.isEmpty;


  var Validations = (0, _emberCpValidations.buildValidations)({
    home: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Property Address'
    }),
    claimDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Date of Incident'
    })
  });

  exports.default = _model.default.extend(Validations, {
    claimDate: (0, _attr.default)('utc'),
    createdAt: (0, _attr.default)('utc'),
    home: (0, _relationships.belongsTo)('home'),
    isEvidenceNeeded: (0, _attr.default)('boolean', { defaultValue: false }),
    isDisputed: (0, _attr.default)('boolean', { defaultValue: false }),
    notes: (0, _attr.default)(),

    // virtual attributes
    selectedHome: computed('home', function () {
      var address = this.get('home.address');
      return address ? { id: address.get('id'), street1: address.get('street1') } : {};
    }),

    displayLine: computed('claimDate', 'home.address', 'home.address.displayLine', function () {
      var claimDate = this.get('claimDate');
      var yearAndMonth = claimDate ? '' + claimDate.format('MM/YYYY') : null;
      var addressString = this.get('home.address.displayLine');
      return yearAndMonth && addressString ? yearAndMonth + ', ' + addressString : null;
    }),

    shouldDisplay: computed.notEmpty('displayLine'),

    organizationSetting: null, // transient

    homeClaimFallOff: computed.reads('organizationSetting.homeClaimFallOff'),

    claimFallOffDate: computed('organizationSetting', function () {
      var claimDate = this.get('claimDate');
      var homeClaimFallOff = this.get('homeClaimFallOff');

      return claimDate ? claimDate.clone().add(homeClaimFallOff, 'months') : null;
    }),

    claimFallOffLabel: computed('claimFallOffDate', function () {
      var claimFallOffDate = this.get('claimFallOffDate');

      if (isEmpty(claimFallOffDate)) return null;

      return 'Home (' + claimFallOffDate.format('MM/DD/YYYY') + ')';
    }),

    claimFallOffDateDiffFromNow: computed('claimFallOffDate', function () {
      var claimFallOffDate = this.get('claimFallOffDate');

      if (isEmpty(claimFallOffDate)) return null;

      return (0, _moment.default)().diff(this.get('claimFallOffDate'), 'days');
    })
  });
});