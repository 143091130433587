define('mvp-web/helpers/humanized-boolean', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizedBoolean = humanizedBoolean;
  function humanizedBoolean(params) {
    return params[0] ? 'Yes' : 'No';
  }

  exports.default = Ember.Helper.helper(humanizedBoolean);
});