define('mvp-web/components/group-forms/group-vehicle', ['exports', 'lodash', 'mvp-web/config/environment', 'mvp-web/mixins/us-states', 'mvp-web/mixins/power-select-highlighted'], function (exports, _lodash, _environment, _usStates, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var computed = Ember.computed,
      service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend(_powerSelectHighlighted.default, _usStates.default, {
    ajax: service(),

    makeOptions: [],
    modelOptions: [],
    trimOptions: [],
    addCustomVehicle: Ember.computed.oneWay('vehicle.isCustomAdded'),

    ownershipOptions: ['Owned', 'Financed', 'Leased'],

    makeIsDisabled: computed('vehicle.yearSelection', function () {
      return _typeof(this.get('vehicle.yearSelection')) !== 'object';
    }),
    modelIsDisabled: computed('vehicle.makeSelection', function () {
      return _typeof(this.get('vehicle.makeSelection')) !== 'object';
    }),
    trimIsDisabled: computed('vehicle.modelSelection', function () {
      return _typeof(this.get('vehicle.modelSelection')) !== 'object';
    }),

    stateMatcher: function stateMatcher(state, term) {
      return state.match(term);
    },


    possibleOwners: computed('vehicle.group.members', 'vehicle.ownerFullName', function () {
      var possibleOwners = this.get('vehicle.group.members') || [];
      var actualOwner = this.get('vehicle.ownerFullName');

      var possibleOwnerNames = possibleOwners.map(function (element) {
        return (element.get('firstName') || '') + ' ' + (element.get('lastName') || '');
      });
      if (actualOwner) {
        possibleOwnerNames.push(actualOwner);
      }
      return _lodash.default.uniq(possibleOwnerNames).filter(function (el) {
        return el !== '' && el !== ' ';
      }); // ensure uniqueness
    }),

    focusComesFromOutside: function focusComesFromOutside(e) {
      // borrowed from documentation:
      // http://www.ember-power-select.com/docs/action-handling
      var blurredEl = e.relatedTarget;
      return !blurredEl || !blurredEl.classList.contains('ember-power-select-search-input');
    },


    actions: {
      didSelectVehicleMake: function didSelectVehicleMake(item) {
        this.sendAction('didSelectVehicleMake', this.get('vehicle'), item);
      },
      didSelectVehicleModel: function didSelectVehicleModel(item) {
        this.sendAction('didSelectVehicleModel', this.get('vehicle'), item);
      },
      didSelectVehicleTrim: function didSelectVehicleTrim(item) {
        this.sendAction('didSelectVehicleTrim', this.get('vehicle'), item);
      },
      didSelectVehicleYear: function didSelectVehicleYear(item) {
        this.sendAction('didSelectVehicleYear', this.get('vehicle'), item);
      },
      didSelectVehicleState: function didSelectVehicleState(item) {
        this.sendAction('didSelectVehicleState', this.get('vehicle'), item);
      },
      didSelectVehicleOwnershipStatus: function didSelectVehicleOwnershipStatus(item) {
        this.sendAction('didSelectVehicleOwnershipStatus', this.get('vehicle'), item);
      },
      didSelectVehicleOwner: function didSelectVehicleOwner(item) {
        this.sendAction('didSelectVehicleOwner', this.get('vehicle'), item);
      },
      handleVehicleFocus: function handleVehicleFocus(select, e) {
        if (this.focusComesFromOutside(e)) {
          select.actions.open();
        }
      },
      queryVehicleMakes: function queryVehicleMakes() {
        var _this = this;

        this.set('makeOptions', []);
        var yearId = this.get('vehicle.yearSelection.id');
        var data = { vehicle_year_id: yearId }; // eslint-disable-line camelcase
        var url = _environment.default.apiEndpointVersion + '/search_vehicle_makes';

        return this.get('ajax').request(url, { data: data }).then(function (response) {
          _this.set('makeOptions', response);
        });
      },
      queryVehicleModels: function queryVehicleModels() {
        var _this2 = this;

        this.set('modelOptions', []);
        var yearId = this.get('vehicle.yearSelection.id');
        var makeId = this.get('vehicle.makeSelection.id');
        var data = { vehicle_year_id: yearId, vehicle_make_id: makeId }; // eslint-disable-line camelcase
        var url = _environment.default.apiEndpointVersion + '/search_vehicle_models';

        return this.get('ajax').request(url, { data: data }).then(function (response) {
          _this2.set('modelOptions', response);
        });
      },
      queryVehicleTrims: function queryVehicleTrims() {
        var _this3 = this;

        this.set('trimOptions', []);
        var yearId = this.get('vehicle.yearSelection.id');
        var modelId = this.get('vehicle.modelSelection.id');
        var data = { vehicle_year_id: yearId, vehicle_model_id: modelId }; // eslint-disable-line camelcase
        var url = _environment.default.apiEndpointVersion + '/search_vehicle_trims';

        return this.get('ajax').request(url, { data: data }).then(function (response) {
          _this3.set('trimOptions', response);
        });
      },
      removeVehicle: function removeVehicle(vehicle) {
        this.sendAction('removeVehicle', vehicle);
      },
      saveVehicle: function saveVehicle(vehicleAttributes) {
        this.sendAction('saveVehicle', this.get('vehicle'), vehicleAttributes);
      },
      selectFromDb: function selectFromDb() {
        this.set('addCustomVehicle', false);
        this.get('vehicle').setAsAddedFromDb();
      },
      addManually: function addManually() {
        this.set('addCustomVehicle', true);
        this.get('vehicle').setAsAddedManually();
      },
      cancelHook: function cancelHook() {
        var isCustomAdded = this.get('vehicle.isCustomAdded');
        this.set('addCustomVehicle', isCustomAdded);
      }
    }
  });
});