define('mvp-web/components/dashboard/performance-statistics/missing-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    classNames: ['__dashboard_performance-statistics_missing-value']
  });
});