define('mvp-web/transforms/number-decimal', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    serialize: function serialize(value) {
      return value;
    },
    deserialize: function deserialize(value) {
      var decimals = Math.floor(value * 100) / 100;
      var str = decimals.toString();
      return str.split('.').length > 1 && str.split('.')[1].length === 1 ? str + '0' : decimals;
    }
  });
});