define('mvp-web/adapters/contact-history', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      return '/api/v1/groups/' + query.group_id + '/contact_history';
    }
  });
});