define('mvp-web/components/dashboard/performance-statistics/total-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['__dashboard_performance-statistics_total-card']
  });
});