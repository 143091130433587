define('mvp-web/models/ivr-node', ['exports', 'ember-data/attr', 'ember-data/model', 'ember-data/relationships'], function (exports, _attr, _model, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    messageType: (0, _attr.default)(),
    messageText: (0, _attr.default)(),
    matchValue: (0, _attr.default)(),
    maxInputDigits: (0, _attr.default)(),
    ivrRecordingId: (0, _attr.default)(),

    isRecording: computed('messageType', 'message_type', function () {
      return this.get('messageType') === 'recording' || this.get('message_type') === 'recording';
    }),
    isText: computed('messageType', 'message_type', function () {
      return this.get('messageType') === 'text' || this.get('message_type') === 'text';
    }),
    isAction: computed('messageType', 'message_type', function () {
      return this.get('messageType') === 'action' || this.get('message_type') === 'action';
    }),

    // Transient
    selectedMatchValue: (0, _attr.default)(),
    isRecordNew: (0, _attr.default)(),

    // children: hasMany('ivrNode', { inverse: null }),
    children: (0, _attr.default)(),
    phoneNumberAction: (0, _relationships.belongsTo)('phoneNumberAction'),
    nodes: (0, _relationships.hasMany)('ivrNode'),
    ivrRecording: (0, _relationships.belongsTo)('ivrRecording')
  });
});