define('mvp-web/components/browser-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    isUnsupportedBrowser: computed(function () {
      return typeof Object.assign !== 'function' || typeof ''.endsWith !== 'function';
    })
  });
});