define('mvp-web/models/task', ['exports', 'ember-data/model', 'ember-data/attr', 'moment', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _moment, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Task Subject'
    }),
    dueDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Due date'
    }),
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Task Details'
    })
  });

  var computed = Ember.computed;
  exports.default = _model.default.extend(Validations, {
    title: (0, _attr.default)(),
    description: (0, _attr.default)(),
    dueDate: (0, _attr.default)('local-date-time'),
    dueDateInOrgTimezone: (0, _attr.default)('string'),
    completedDateInOrgTimezone: (0, _attr.default)('string'),
    completedAt: (0, _attr.default)('utc'),
    completedDate: computed('completedAt', function () {
      var completedAt = this.get('completedAt');
      if (!completedAt) return;

      return (0, _moment.default)(completedAt).local();
    }),
    orgTimezone: (0, _attr.default)(),
    creator: (0, _attr.default)(),
    group: (0, _relationships.belongsTo)('group'),
    assignee: (0, _relationships.belongsTo)('user'),
    taskSequence: (0, _relationships.belongsTo)('taskSequence'),
    completed: (0, _attr.default)('boolean', { defaultValue: false }),
    groupId: (0, _attr.default)(),
    assigneeId: (0, _attr.default)(),
    taskSequenceId: (0, _attr.default)(),
    groupPrimaryMemberId: (0, _attr.default)(),
    groupPrimaryMemberName: (0, _attr.default)(),
    groupTransitionState: (0, _attr.default)()
  });
});