define('mvp-web/models/score', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    hotScore: (0, _attr.default)('number'),
    newScore: (0, _attr.default)('number'),
    includesHome: (0, _attr.default)('number'),
    amountSaved: (0, _attr.default)('number'),
    compClosingRatio: (0, _attr.default)('number'),
    commission: (0, _attr.default)('number'),
    referralSource: (0, _attr.default)('number'),
    renewalDate: (0, _attr.default)('number'),
    accidentFallOff: (0, _attr.default)('number'),
    sum: (0, _attr.default)('number'),
    updatedAt: (0, _attr.default)(),
    group: (0, _relationships.belongsTo)('group', { polymorphic: true, inverse: 'score' }),
    organization: (0, _relationships.belongsTo)('organization')
  });
});