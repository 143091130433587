define('mvp-web/components/tel-link', ['exports', 'mvp-web/helpers/format-phone'], function (exports, _formatPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    telFormattedNumber: computed('phoneNumber', function () {
      return 'tel:' + (0, _formatPhone.dasherizedToRawUS)(this.get('phoneNumber'));
    })
  });
});