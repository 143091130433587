define('mvp-web/components/export-history-table-row', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),
    isStreaming: false,
    websocket: service(),

    createdAt: computed('export.createdAt', function () {
      var dateValue = (0, _moment.default)(this.get('export.createdAt')).format('MMMM DD, YYYY @ h:mm A');
      return '' + dateValue;
    }),

    isFailed: computed('export.status', function () {
      return this.get('export.status') === 'failed';
    }),
    isPending: computed('export.status', function () {
      return this.get('export.status') === 'pending' || this.get('export.status') === 'processing';
    }),
    isSuccessful: computed('export.status', function () {
      return this.get('export.status') === 'completed';
    }),

    subscription: computed(function () {
      var store = this.get('store');
      var opts = {
        channel: 'OrganizationExportChannel',
        id: this.get('export.id')
      };

      return this.get('websocket.consumer').subscriptions.create(opts, {
        received: function received(res) {
          store.pushPayload(res);
        }
      });
    }),

    _removeSubscription: function _removeSubscription() {
      var subscription = this.get('subscription');
      this.get('websocket.consumer').subscriptions.remove(subscription);
    },


    processStatus: computed('export.status', function () {
      if (this.get('isFailed')) {
        return { class: 'label label-danger', title: 'Failed' };
      }
      if (this.get('isSuccessful')) {
        return { class: 'label label-success', title: 'Processed' };
      }
      if (this.get('isPending')) {
        return { class: 'label label-info', title: 'Pending' };
      }
      return { class: 'fa-circle-o', title: 'Indeterminate' }; // catch all
    }),

    actions: {
      // TODO: Refactor modals – https://trello.com/c/K5ETiWJz
      // Very very bad & un-Ember... But time crunch!
      showErrorsModal: function showErrorsModal() {
        var exceptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var errorsModal = Ember.$('#import-errors-modal');
        var details = '';

        exceptions.forEach(function (log) {
          for (var key in log) {
            var filteredMsg = log[key].split('\n')[0];
            details += '<div><span class="label label-info">Row #' + key + '</span> ' + filteredMsg + '</div><br/>';
          }
        });

        errorsModal.modal('show');
        errorsModal.find('.modal-data').html(details);
      }
    },
    didInsertElement: function didInsertElement() {
      if (!this.get('isPending')) return;

      this.get('subscription');
      this.set('isStreaming', true);
    },
    willClearRender: function willClearRender() {
      if (!this.get('isStreaming')) return;

      var subscription = this.get('subscription');
      this.get('websocket.consumer').subscriptions.remove(subscription);
    }
  });
});