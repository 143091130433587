define('mvp-web/validators/referral-group-contact-method', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReferralGroupContactMethod = _base.default.extend({
    validate: function validate(_value, _options, model, _attribute) {
      if (model.get('hasNoContact')) {
        return 'At least one contact method is required';
      }

      return true;
    }
  });

  ReferralGroupContactMethod.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = ReferralGroupContactMethod;
});