define('mvp-web/adapters/user-setting', ['exports', 'ember-local-storage/adapters/local'], function (exports, _local) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _local.default;
    }
  });
});