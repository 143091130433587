define('mvp-web/adapters/vehicle-coverage', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(type, id, snapshot) {
      var vehicleQuote = snapshot ? snapshot.belongsTo('vehicleQuote') : null;
      var vehicleQuoteId = vehicleQuote ? vehicleQuote.id : null;

      // The array order matters
      var pathObject = [{ resourceName: 'vehicleQuote', id: vehicleQuoteId }, { resourceName: 'vehicleCoverage', id: id }];

      return this.buildNestedUrl(pathObject);
    }
  });
});