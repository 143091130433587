define('mvp-web/components/quote-forms/umbrella/first-party-quote', ['exports', 'mvp-web/mixins/power-select-highlighted', 'mvp-web/mixins/quote-shareable', 'mvp-web/utils/magic-ids'], function (exports, _powerSelectHighlighted, _quoteShareable, _magicIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend(_powerSelectHighlighted.default, _quoteShareable.default, {
    store: service(),
    magicIds: _magicIds.default,
    isFirstParty: true,

    isSold: computed('quote.markedAsSold', function () {
      return this.get('quote.markedAsSold');
    }),

    carrierLabel: computed(function () {
      return this.get('group.isCustomer') ? 'Carrier' : 'Quote Carrier';
    }),

    actions: {
      addPaymentOption: function addPaymentOption(quote) {
        var paymentOption = this.get('store').createRecord('quotePaymentOption');
        quote.get('quotePaymentOptions').pushObject(paymentOption);
      }
    }
  });
});