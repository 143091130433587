define('mvp-web/components/phone-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    session: service(),

    tagName: '',

    isMobile: computed.equal('type', 'mobile'),
    isDesktop: computed.not('isMobile'),

    user: computed(function () {
      return this.get('session.currentUser');
    })
  });
});