define('mvp-web/components/quote-forms/home/home-claim-row', ['exports', 'moment', 'mvp-web/mixins/home-properties', 'mvp-web/mixins/power-select-highlighted'], function (exports, _moment, _homeProperties, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend(_homeProperties.default, _powerSelectHighlighted.default, {
    actions: {
      didSelectHomeClaimAddress: function didSelectHomeClaimAddress(address) {
        var home = this.get('model.homes.content').findBy('address.content', address);
        this.get('claim').set('home', home);
      },
      removeClaim: function removeClaim() {
        this.get('claim').destroyRecord();
      }
    },

    maxDate: computed(function () {
      return (0, _moment.default)();
    })
  });
});