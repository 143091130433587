define('mvp-web/models/workflow-setting', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    availability: (0, _attr.default)('object'),
    automationWorkflowAssignments: (0, _attr.default)('object', { defaultValue: { markAsQuoted: null, markAsSold: null } }),
    allowTeamMemberWorkflowEdit: (0, _attr.default)('boolean'),
    pipelineGroupOnXDateWorkflowAssignment: (0, _attr.default)('boolean')
  });
});