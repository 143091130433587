define('mvp-web/components/send-message-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    classNameBindings: ['disabled', 'componentClass'],

    tagName: 'a',

    chat: service(),
    store: service(),
    session: service(),
    organizationSetting: service(),

    disabled: computed.not('member.hasMobileNumber'),

    settings: computed.alias('organizationSetting.settings'),

    isMessagingEnabled: computed.alias('organizationSetting.isMessagingEnabled'),

    componentClass: computed('isMessagingEnabled', function () {
      if (this.get('isMessagingEnabled')) {
        return 'btn btn-xs btn-success inline lh-base';
      }

      return 'hide';
    }),

    isComponentDisabled: computed('disabled', 'isMessagingEnabled', function () {
      return this.get('disabled') || !this.get('isMessagingEnabled');
    }),

    click: function click() {
      if (this.get('isComponentDisabled')) {
        return;
      }

      this.sendMessage();
    },
    launchChatWindow: function launchChatWindow(chatRoom) {
      this.get('chat').openConversation(chatRoom);
    },
    sendMessage: function sendMessage() {
      if (this.get('member.isNew')) {
        return;
      }

      var self = this;
      var store = this.get('store');
      var filter = {
        groupId: this.get('member.group.id'),
        memberId: this.get('member.id')
      };

      var failure = function failure() {
        var chatRoom = store.createRecord('chat-room', {
          members: [self.get('member')]
        });
        chatRoom.save().then(self.launchChatWindow.bind(self));
      };

      store.queryRecord('chat-room', { filter: filter }).then(self.launchChatWindow.bind(this)).catch(failure);
    }
  });
});