define('mvp-web/helpers/parse-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parseElement = parseElement;


  // This function is designed to take an HTML element and parse it's text content, while excluding
  // any child elements that have a display property of "none".

  function parseElement(element) {
    if (element.style && element.style.display === 'none') {
      return '';
    }if (element.nodeType === Node.TEXT_NODE || element.childNodes.length === 0) {
      return element.textContent;
    }
    var combinedValue = '';
    Array.from(element.childNodes).forEach(function (child) {
      var content = parseElement(child);
      combinedValue += content;
    });
    return combinedValue;
  }

  exports.default = Ember.Helper.helper(parseElement);
});