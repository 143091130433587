define('mvp-web/routes/leads/referrals', ['exports', 'mvp-web/routes/leads/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _index.default.extend({
    priorityModelParams: function priorityModelParams(baseParams) {
      return {
        scheduledToday: true,
        page: 1,
        size: 25,
        hot: false,
        neutral: true,
        phone_filter: 'present',
        best_time: 0,
        quote_type_focus: baseParams.quote_type_focus,
        referral: true
      };
    },
    regularModelParams: function regularModelParams(baseParams) {
      return _extends({
        status: 'quoted',
        scheduledToday: false,
        referral: true
      }, baseParams);
    }
  });
});