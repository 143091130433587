define('mvp-web/models/leads-import', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    createdAt: (0, _attr.default)(),
    exceptionsLog: (0, _attr.default)('array'),
    fileName: (0, _attr.default)(),
    params: (0, _attr.default)('object'),
    referralSource: (0, _attr.default)(),
    stats: (0, _attr.default)('object'),
    tags: (0, _attr.default)('array', { defaultValue: function defaultValue() {
        return [];
      } }),
    leadsOwner: (0, _relationships.belongsTo)('user'),
    user: (0, _relationships.belongsTo)('user')
  });
});