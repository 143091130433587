define('mvp-web/services/static-data', ['exports', 'mvp-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('staticData', {});
      this.fetchStaticData();
    },
    fetchStaticData: function fetchStaticData() {
      var _this = this;

      Ember.$.ajax({ url: _environment.default.apiEndpointVersion + '/public_static_data' }).then(function (data) {
        _this.set('staticData', data);
      });
    }
  });
});