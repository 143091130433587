define('mvp-web/components/conversion-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('index') === 0) {
        this.toggleProperty('quote.isMarkedAsSold');
        this.get('toggleSold').perform(this.get('quote'));
      }
    }
  });
});