define('mvp-web/components/enrichment-notes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    renewalMonth: computed('group.enrichmentNotes.group_updates', function () {
      var renewalMonth = '';
      switch (this.get('group.enrichmentNotes.group_updates.renewal_month')) {
        case 1:
          renewalMonth = 'January';
          break;
        case 2:
          renewalMonth = 'February';
          break;
        case 3:
          renewalMonth = 'March';
          break;
        case 4:
          renewalMonth = 'April';
          break;
        case 5:
          renewalMonth = 'May';
          break;
        case 6:
          renewalMonth = 'June';
          break;
        case 7:
          renewalMonth = 'July';
          break;
        case 8:
          renewalMonth = 'August';
          break;
        case 9:
          renewalMonth = 'September';
          break;
        case 10:
          renewalMonth = 'October';
          break;
        case 11:
          renewalMonth = 'November';
          break;
        case 12:
          renewalMonth = 'December';
          break;
        default:
          renewalMonth = undefined;
      }

      return renewalMonth;
    })
  });
});