define('mvp-web/components/quote-forms/vehicle/vehicle-claim-row', ['exports', 'moment', 'mvp-web/mixins/power-select-highlighted'], function (exports, _moment, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    maxDate: computed(function () {
      return (0, _moment.default)();
    }),

    isAccident: computed.equal('claim.claimType', 'accident'),

    isViolation: computed.equal('isAccident', false)

  });
});