define('mvp-web/controllers/groups/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      on = Ember.on,
      Controller = Ember.Controller,
      observer = Ember.observer,
      getOwner = Ember.getOwner;
  exports.default = Controller.extend({
    formOptions: service('formOptionsBootstrap'),
    session: service(),
    globalLoader: service(),

    formOptionsPreset: null,
    queryParams: ['page', 'size', 'best_time', 'phone_filter', 'email_filter', 'no_calls_filter', 'tags', 'quote_type_focus', 'my_stalled_groups', 'cold', 'hot', 'neutral', 'workflow_ids', 'team_member_ids', 'current_carrier_ids', 'first_party_carrier_ids', 'renewal_months', 'policy_renewal_months', 'zip_codes', 'dob_from', 'dob_to', 'us_states'],

    best_time: '0', // eslint-disable-line camelcase
    phone_filter: 'present', // eslint-disable-line camelcase
    page: 1,
    quote_type_focus: 'other', // eslint-disable-line camelcase
    my_stalled_groups: false, // eslint-disable-line camelcase
    hot: true,
    cold: false,
    neutral: true,
    size: 25,
    workflow_ids: [], // eslint-disable-line camelcase
    team_member_ids: [], // eslint-disable-line camelcase

    priorityModel: computed.or('model.{currentPriorityTask,previousPriorityTask}.value'),
    regularModel: computed.or('model.{currentRegularTask,previousRegularTask}.value'),

    priorityModelIsLoading: computed.reads('model.currentPriorityTask.isRunning'),
    priorityModelIsCanceling: computed.reads('model.currentPriorityTask.isCanceling'),
    priorityModelIsFinished: computed.reads('model.currentPriorityTask.isFinished'),
    priorityModelHasCache: computed.notEmpty('model.previousPriorityTask.value'),

    regularModelIsLoading: computed.reads('model.currentRegularTask.isRunning'),
    regularModelIsCanceling: computed.reads('model.currentRegularTask.isCanceling'),
    regularModelIsFinished: computed.reads('model.currentRegularTask.isFinished'),
    regularModelHasCache: computed.notEmpty('model.previousRegularTask.value'),

    modelIsCanceling: computed.or('priorityModelIsCanceling', 'regularModelIsCanceling'),
    modelIsFinished: computed.reads('regularModelIsFinished'),

    modelLoadingObserver: observer('priorityModelIsFinished', 'regularModelIsFinished', function () {
      if (!this.get('modelIsCanceling') && this.get('modelIsFinished')) {
        return this.get('globalLoader.deactivate').perform();
      }
    }),

    user: computed('session', function () {
      return this.get('session.currentUser');
    }),

    initializeData: on('init', function () {
      var _this = this;

      this.get('formOptions').loadData().then(function (result) {
        _this.set('formOptionsPreset', result);
      });
    }),

    actions: {
      refreshModel: function refreshModel() {
        var route = getOwner(this).lookup('route:' + this.get('modelName').pluralize() + '.index');
        return route.refresh();
      }
    }
  });
});