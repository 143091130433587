define('mvp-web/models/deductible', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    amount: (0, _attr.default)('number', { defaultValue: 0 }),
    name: (0, _attr.default)(),
    deductibleableId: (0, _attr.default)(),
    deductibleableType: (0, _attr.default)(),
    quote: (0, _relationships.belongsTo)('quote', { polymorphic: true, inverse: 'deductibles' }),
    showOnComparison: (0, _attr.default)('boolean', { defaultValue: true })
  });
});