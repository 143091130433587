define('mvp-web/components/quote-forms/commercial/claim-row', ['exports', 'moment', 'mvp-web/mixins/home-properties', 'mvp-web/mixins/power-select-highlighted'], function (exports, _moment, _homeProperties, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend(_homeProperties.default, _powerSelectHighlighted.default, {
    maxDate: computed(function () {
      return (0, _moment.default)();
    }),

    actions: {
      selectCommercial: function selectCommercial(claim, commercial) {
        claim.set('commercial', commercial);
      },
      removeClaim: function removeClaim() {
        this.get('claim').destroyRecord();
      }
    }
  });
});