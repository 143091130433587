define('mvp-web/controllers/leads/index', ['exports', 'mvp-web/controllers/groups/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    detailsPath: 'leads.edit',
    transitionStateIsProspect: false,
    transitionStateIsCustomer: false,
    modelName: 'lead',
    external_quotes: false, // eslint-disable-line camelcase
    x_date_from: null, // eslint-disable-line camelcase
    x_date_to: null // eslint-disable-line camelcase
  });
});