define('mvp-web/controllers/my-leads/index', ['exports', 'mvp-web/controllers/leads/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  exports.default = _index.default.extend({
    detailsPath: 'leads.edit',

    actions: {
      refreshModel: function refreshModel() {
        var route = getOwner(this).lookup('route:my-leads.index');
        return route.refresh();
      }
    }
  });
});