define('mvp-web/routes/customers/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'mvp-web/form-objects/customer-new'], function (exports, _authenticatedRouteMixin, _customerNew) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    session: service(),
    globalLoader: service(),
    user: null,

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    beforeModel: function beforeModel() {
      var _this = this;

      return this.get('session.currentUser').then(function () {
        return _this.set('user', _this.get('session.currentUser'));
      });
      // here this.get returns a promise due to service:session returning
      // promise from store as a custom computed property
    },
    model: function model() {
      return _extends({}, _customerNew.default);
    }
  });
});