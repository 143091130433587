define('mvp-web/components/phone-numbers/voicemails/voicemail-recording-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    modalId: computed('voicemailRecording.id', function () {
      return 'voicemail-recording-modal-' + this.get('voicemailRecording.id');
    }),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});