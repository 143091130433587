define('mvp-web/components/dashboard/performance-statistics/quote-activity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;

  var allowedFilters = ['all', 'lead', 'prospect', 'customer'];

  exports.default = Ember.Component.extend({
    store: service(),
    groupTypeFilter: 'all',

    filteredStatistics: computed('statistics', 'groupTypeFilter', function () {
      var statistics = this.get('statistics');
      var groupTypeFilter = this.get('groupTypeFilter');

      if (groupTypeFilter === 'all') return statistics;

      return statistics.filter(function (stat) {
        return stat.get('transitionState') === groupTypeFilter;
      });
    }),

    actions: {
      changeGroupTypeFilter: function changeGroupTypeFilter(groupType) {
        if (!allowedFilters.includes(groupType)) return;

        this.set('groupTypeFilter', groupType);
      }
    }
  });
});