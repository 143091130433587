define('mvp-web/models/at-workflow-histories', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    action: (0, _attr.default)(),
    when: (0, _attr.default)(),
    status: (0, _attr.default)(),
    atWorkflowGroupCursor: (0, _attr.default)(),
    atWorkflowName: (0, _attr.default)()
  });
});