define('mvp-web/models/chat-room', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var computed = Ember.computed;
  exports.default = _model.default.extend({
    createdAt: (0, _attr.default)('utc'),
    lastMessageBody: (0, _attr.default)(),
    lastMessageCreatedAt: (0, _attr.default)('utc'),
    lastMessageId: (0, _attr.default)(),
    lastMessageReadAt: (0, _attr.default)('utc'),
    lastMessageSenderName: (0, _attr.default)(),
    members: (0, _relationships.hasMany)('member'),
    member: computed('members', function () {
      return this.get('members.firstObject');
    }),
    messages: (0, _relationships.hasMany)('message', { polymorphic: true }),
    unreadCount: (0, _attr.default)('number'),
    updatedAt: (0, _attr.default)('utc'),
    user: (0, _relationships.belongsTo)('user'),
    groupId: (0, _attr.default)(),
    group: (0, _relationships.belongsTo)('group'),
    allUsers: (0, _attr.default)(),
    groupTransitionState: (0, _attr.default)(),
    archived_at: (0, _attr.default)(),
    archive: (0, _attr.default)(),

    allUsersWithInitials: computed.map('allUsers', function (user, idx) {
      var initials = '' + user.first_name.charAt(0) + user.last_name.charAt(0);
      return _extends({}, user, { initials: initials });
    }),

    membersFullNames: computed.map('members', function (member, idx) {
      var fullName = member.get('fullName');

      if (idx !== 0) {
        fullName += ', ';
      }

      return fullName;
    }),

    hasUnreadMessages: computed.gt('unreadCount', 0),
    hasGroup: computed.notEmpty('groupId'),
    hasNoGroup: computed.empty('groupId'),
    editGroupPath: computed('groupTransitionState', function () {
      if (!this.get('groupTransitionState')) return;

      var transitionStatePlural = this.get('groupTransitionState').pluralize();

      return transitionStatePlural + '.edit';
    }),

    selectCheckboxId: computed('id', function () {
      return 'select-checkbox-' + this.get('id');
    })

  });
});