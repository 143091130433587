define('mvp-web/mixins/page-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    count: computed('model.meta.pagination.last.number', 'model.meta.pagination.self.number', function () {
      var currentNum = this.get('model.meta.pagination.self.number');
      var span = this.get('span');
      var total = this.get('model.meta.pagination.last.number') || this.get('model.meta.pagination.self.number');

      if (!total) {
        return [];
      }

      var count = new Array(total + 1).join('x').split('').map(function (e, i) {
        var num = i + 1;
        var obj = null;

        if (num >= 1 && num <= span || num > currentNum - span && num < currentNum + span || num <= total && num > total - span) {
          obj = { number: num, display: num };
        } else if (num === currentNum - span || num === currentNum + span) {
          obj = { number: num, display: '…' };
        }

        return obj;
      });

      return count.compact();
    })
  });
});