define('mvp-web/components/group-forms/possible-duplicates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['_possible-duplicates'],
    store: service(),

    currentGroupId: null,
    numberOfDuplicates: computed.alias('duplicates.length'),
    allRequiredParams: computed.and('params.street1', 'params.postalCode', 'params.usState'),

    params: computed('address.street1', 'address.postalCode', 'address.usState', function () {
      return {
        groupId: this.get('currentGroupId'),
        street1: this.get('address.street1'),
        postalCode: this.get('address.postalCode'),
        usState: this.get('address.usState')
      };
    }),

    duplicates: computed('params', function () {
      if (!this.get('allRequiredParams')) return [];

      return this.get('store').query('duplicatedGroup', this.get('params'));
    })
  });
});