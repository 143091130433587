define('mvp-web/components/mvp-datetimepicker', ['exports', 'ember-bootstrap-datetimepicker/components/bs-datetimepicker', 'moment'], function (exports, _bsDatetimepicker, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _bsDatetimepicker.default.extend({
    // these settings override ember-bootstrap-datepicker attributes
    inputGroupClass: '',
    isValid: computed.not('error'),
    isNotValid: computed.not('isValid'),
    showInvalid: true,

    icons: {
      time: 'fa fa-fw fa-clock-o',
      date: 'fa fa-fw fa-calendar',
      up: 'fa fa-fw fa-chevron-up',
      down: 'fa fa-fw fa-chevron-down',
      previous: 'fa fa-fw fa-chevron-left',
      next: 'fa fa-fw fa-chevron-right',
      today: 'fa fa-fw fa-calendar-o',
      clear: 'fa fa-fw fa-trash-o',
      close: 'fa fa-fw fa-remove'
    },

    displayDate: Ember.computed('date', function () {
      if (!this.get('date')) return null;

      return (0, _moment.default)(this.get('date')).format(this.get('format'));
    }),

    classes: Ember.computed('inputClassNames', function () {
      var basicClasses = 'form-control input-sm';
      var inputClassNames = this.get('inputClassNames');
      return inputClassNames ? basicClasses.concat(' ', inputClassNames) : basicClasses;
    })
  });
});