define('mvp-web/models/address', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'ember-cp-validations', 'mvp-web/utils/address-matcher'], function (exports, _relationships, _attr, _model, _emberCpValidations, _addressMatcher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    street1: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Street'
    }),
    postalCode: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Postal Code'
    }),
    city: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'City'
    }),
    usState: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'State'
    })
  });

  exports.default = _model.default.extend(Validations, {
    city: (0, _attr.default)(),
    homes: (0, _relationships.hasMany)('home'),
    commercials: (0, _relationships.hasMany)('commercial'),
    isVerified: (0, _attr.default)('boolean'),
    latitude: (0, _attr.default)(),
    longitude: (0, _attr.default)(),
    organization: (0, _relationships.belongsTo)('organization'),
    organizationBillingAddress: (0, _relationships.belongsTo)('organization', { inverse: 'billingAddress' }),
    postalCode: (0, _attr.default)('string', { defaultValue: '' }),
    street1: (0, _attr.default)(),
    usState: (0, _attr.default)('string'),
    usStateAbbr: (0, _attr.default)(),
    verifiedStr: (0, _attr.default)(),

    hasAllFieldsSet: computed.and('street1', 'postalCode', 'city', 'usState'),
    hasInvalidAddress: computed.not('hasAllFieldsSet'),
    isTexasBased: computed.equal('usState', 'Texas'),

    displayLine: computed('city', 'street1', function () {
      var city = this.get('city');
      var street1 = this.get('street1');
      return city && street1 ? city + ' ' + street1 : null;
    }),

    closestMatches: function closestMatches() {
      return (0, _addressMatcher.default)(this.store, this.get('street1'), this.get('usState'));
    },
    concatAddressStr: function concatAddressStr() {
      return ('' + this.get('city') + this.get('street1') + this.get('postalCode') + this.get('usState')).replace(/\s/g, '');
    },
    updateVerifiedStr: function updateVerifiedStr() {
      this.set('verifiedStr', this.concatAddressStr());
    }
  });
});