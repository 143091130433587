define('mvp-web/components/group-forms/attached-files', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    features: service(),
    session: service(),

    classNames: ['form-group', 'mb-0'],

    actions: {
      attachmentUploaded: function attachmentUploaded(uploadedFile) {
        this.get('group').setAttachment(uploadedFile);
      },
      removeAttachment: function removeAttachment(id) {
        this.get('group').removeAttachment(id);
      }
    }
  });
});