define('mvp-web/adapters/boberdoo', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    retrieveAccountBalance: function retrieveAccountBalance() {
      var url = this.get('namespace') + '/boberdoo/account_balance';

      return this.ajax(url, 'GET');
    },
    retrieveRefundReasons: function retrieveRefundReasons() {
      var url = this.get('namespace') + '/boberdoo/refund_reasons';

      return this.ajax(url, 'GET');
    },
    requestRefund: function requestRefund(exclusiveLeadId, refundReasonId, leadTypeId, refundRequestComment) {
      var url = this.get('namespace') + '/boberdoo/refund_request';
      var data = {
        data: {
          lead_open_api_import_id: exclusiveLeadId,
          refund_reason_id: refundReasonId,
          lead_type_id: leadTypeId,
          refund_request_comment: refundRequestComment
        }
      };

      return this.ajax(url, 'POST', data);
    }
  });
});