define('mvp-web/serializers/task', ['exports', 'mvp-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      creator: { serialize: true },
      assignee: { serialize: true },
      group: { serialize: true }
    }
  });
});