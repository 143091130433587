define('mvp-web/components/member-fullname-td', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'td',
    attributeBindings: ['title'],

    hasAccidentFallOffPoints: computed.readOnly('group.hasAccidentFallOffPoints'),
    isHot: computed.readOnly('group.isHot'),
    title: computed('hasAccidentFallOffPoints', function () {
      return this.get('hasAccidentFallOffPoints') ? this.get('group.fallOffDetails') : null;
    })

  });
});