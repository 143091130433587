define('mvp-web/controllers/exports/history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      refreshRoute: function refreshRoute() {
        this.sendAction('refreshModel');
      }
    }
  });
});