define('mvp-web/components/quote-forms/vehicle/vehicle-coverages-select', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {

    modelIdAppendage: computed('model', function () {
      return this.get('model.id') || '';
    }),

    actions: {
      selectLiabilityCoverage: function selectLiabilityCoverage(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('model').setBodilyInjuryLiabilityCoverage(id);
      },
      setBodilyInjuryCoverageFromExtension: function setBodilyInjuryCoverageFromExtension() {
        var possibleOptions = this.get('formOptions.liabilityCoverages');
        var coverageFromHtml = Ember.$('.bodily-injury-' + this.get('modelIdAppendage') + ' .ember-power-select-selected-item').text();
        var selectedOption = possibleOptions.find(function (option) {
          return option.name === coverageFromHtml;
        });
        if (selectedOption) this.get('model').setBodilyInjuryLiabilityCoverage(selectedOption.id);
      },
      selectPropertyDamageCoverage: function selectPropertyDamageCoverage(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('model').setPropertyDamageCoverage(id);
      },
      setPropertyDamageCoverageFromExtension: function setPropertyDamageCoverageFromExtension() {
        var possibleOptions = this.get('formOptions.propertyDamageCoverages');
        var coverageFromHtml = Ember.$('.property-damage-' + this.get('modelIdAppendage') + ' .ember-power-select-selected-item').text();
        var selectedOption = possibleOptions.find(function (option) {
          return option.name === coverageFromHtml;
        });
        if (selectedOption) this.get('model').setPropertyDamageCoverage(selectedOption.id);
      },
      selectUmUimLiabilityCoverage: function selectUmUimLiabilityCoverage(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('model').setUmUimLiabilityCoverage(id);
      },
      setUmUimLiabilityCoverageFromExtension: function setUmUimLiabilityCoverageFromExtension() {
        var possibleOptions = this.get('formOptions.liabilityCoverages');
        var coverageFromHtml = Ember.$('.um-uim-bodily-injury-' + this.get('modelIdAppendage') + ' .ember-power-select-selected-item').text();
        var selectedOption = possibleOptions.find(function (option) {
          return option.name === coverageFromHtml;
        });
        if (selectedOption) this.get('model').setUmUimLiabilityCoverage(selectedOption.id);
      },
      selectUmUimPropertyDamageCoverage: function selectUmUimPropertyDamageCoverage(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('model').setUmUimPropertyDamageCoverage(id);
      },
      setUmUimPropertyDamageCoverageFromExtension: function setUmUimPropertyDamageCoverageFromExtension() {
        var possibleOptions = this.get('formOptions.propertyDamageCoverages');
        var coverageFromHtml = Ember.$('.um-uim-property-damage-' + this.get('modelIdAppendage') + ' .ember-power-select-selected-item').text();
        var selectedOption = possibleOptions.find(function (option) {
          return option.name === coverageFromHtml;
        });
        if (selectedOption) this.get('model').setUmUimPropertyDamageCoverage(selectedOption.id);
      },
      selectPersonalInjuryProtectionCoverage: function selectPersonalInjuryProtectionCoverage(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('model').setPersonalInjuryProtectionCoverage(id);
      },
      setPersonalInjuryProtectionCoverageFromExtension: function setPersonalInjuryProtectionCoverageFromExtension() {
        var possibleOptions = this.get('formOptions.personalInjuryCoverages');
        var coverageFromHtml = Ember.$('.personal-injury-' + this.get('modelIdAppendage') + ' .ember-power-select-selected-item').text();
        var selectedOption = possibleOptions.find(function (option) {
          return option.name === coverageFromHtml;
        });
        if (selectedOption) this.get('model').setPersonalInjuryProtectionCoverage(selectedOption.id);
      },
      selectMedicalProtectionCoverage: function selectMedicalProtectionCoverage(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('model').setMedicalProtectionCoverage(id);
      },
      setMedicalProtectionCoverageFromExtension: function setMedicalProtectionCoverageFromExtension() {
        var possibleOptions = this.get('formOptions.medicalProtectionCoverages');
        var coverageFromHtml = Ember.$('.medical-protection-' + this.get('modelIdAppendage') + ' .ember-power-select-selected-item').text();
        var selectedOption = possibleOptions.find(function (option) {
          return parseInt(option.name) === parseInt(coverageFromHtml);
        });
        if (selectedOption) this.get('model').setMedicalProtectionCoverage(selectedOption.id);
      },
      handleKeyValues: function handleKeyValues(value, selection, e) {
        if (e && e.keyCode === 9) {
          var valueToSet = selection.highlighted || selection.selected;
          return selection.actions.choose(valueToSet, e);
        }

        // Remove value on backspace
        if (e && e.keyCode === 8) {
          this.get('model')[value](null);
        } else {
          return Ember.set(selection, 'selected', undefined);
        }

        if (selection.selected) {
          selection.actions.choose(selection.selected, e);
        }
      }
    }
  });
});