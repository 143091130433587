define('mvp-web/components/mvp-show-sold-or-cross-sell-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    soldOrCrossSellLabel: computed('group.canCrossSell', function () {
      return this.get('group.canCrossSell') ? 'Won Cross-Sell' : 'Mark as Sold';
    }),

    actions: {
      displayConversionWindow: function displayConversionWindow() {
        this.set('conversionWindowDisplayed', true);
      },
      closeModal: function closeModal() {
        this.set('conversionWindowDisplayed', false);
      },
      convertToCustomer: function convertToCustomer() {
        var result = this.sendAction('convertToCustomer');
        if (result) {
          this.closeModal();
        }
      }
    }
  });
});