define('mvp-web/serializers/commercial', ['exports', 'mvp-web/serializers/application', 'mvp-web/mixins/embedded-relationships'], function (exports, _application, _embeddedRelationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRelationships.default, {
    // Override EmbeddedRelationshipsMixin implementation. Needed to ensure organization_id is present in address_attributes hash.
    _serializeEmbeddedBelongsTo: function _serializeEmbeddedBelongsTo(snapshot, json, relationship) {
      var embeddedSnapshot = snapshot.belongsTo(relationship.key);
      var serializedKey = this._getMappedKey(relationship.key, snapshot.type);
      if (serializedKey === relationship.key && this.keyForRelationship) {
        serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
      }

      if (!embeddedSnapshot) {
        json[serializedKey] = null;
      } else {
        json[serializedKey] = embeddedSnapshot.id;
        json[this.transformKeyForRails(serializedKey)] = embeddedSnapshot.serialize({ includeId: true });
        // if organization present in embedded relationship, convert to organization_id so that it is picked up by rails.
        if (json[this.transformKeyForRails(serializedKey)].organization) {
          json[this.transformKeyForRails(serializedKey)].organization_id = json[this.transformKeyForRails(serializedKey)].organization;
          delete json[this.transformKeyForRails(serializedKey)].organization;
        }
        this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, json[serializedKey]);
        if (relationship.options.polymorphic) {
          this.serializePolymorphicType(snapshot, json, relationship);
        }
      }
    },


    attrs: {
      address: { serialize: true }
    }
  });
});