define('mvp-web/initializers/resize', ['exports', 'ember-resize/services/resize', 'mvp-web/config/environment'], function (exports, _resize, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];

    var resizeServiceDefaults = _environment.default.resizeServiceDefaults;
    var injectionFactories = resizeServiceDefaults.injectionFactories;


    application.register('config:resize-service', resizeServiceDefaults, { instantiate: false });
    application.register('service:resize', _resize.default);
    application.inject('service:resize', 'resizeServiceDefaults', 'config:resize-service');

    injectionFactories.forEach(function (factory) {
      application.inject(factory, 'resizeService', 'service:resize');
    });
  }

  exports.default = {
    name: 'resize',
    initialize: initialize
  };
});