define('mvp-web/routes/customers/index', ['exports', 'mvp-web/routes/groups/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    modelName: 'customer',
    queryParams: {
      includeCalled: { refreshModel: true, type: 'boolean' },
      crossSell: { refreshModel: true }
    }

  });
});