define('mvp-web/models/stats-call-activity-data-point', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    userName: (0, _attr.default)(),
    lead: (0, _attr.default)('number', { defaultValue: 0 }),
    hotLead: (0, _attr.default)('number', { defaultValue: 0 }),
    apiLead: (0, _attr.default)('number', { defaultValue: 0 }),
    prospectAwaiting: (0, _attr.default)('number', { defaultValue: 0 }),
    prospectQuoted: (0, _attr.default)('number', { defaultValue: 0 }),
    crossSell: (0, _attr.default)('number', { defaultValue: 0 }),
    totalHouseholdsContacted: (0, _attr.default)('number', { defaultValue: 0 }),
    totalCallAttempts: (0, _attr.default)('number', { defaultValue: 0 }),
    totalTexts: (0, _attr.default)('number', { defaultValue: 0 })
  });
});