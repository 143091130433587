define('mvp-web/components/phone-numbers/phone-number-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    phoneNumbersLoading: computed.reads('isLoading'),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});