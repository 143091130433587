define('mvp-web/routes/messages/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend({
    chat: service(),
    polling: service(),
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    model: function model(params) {
      var store = this.get('store');

      if (store.recordIsLoaded('chat-room', params.id)) {
        return store.peekRecord('chat-room', params.id);
      }

      return store.findRecord('chat-room', params.id);
    },
    afterModel: function afterModel(model) {
      var _this = this;

      this._super.apply(this, arguments);

      var id = model.get('id');
      this.get('polling').startPolling('refreshMessages' + id, function () {
        _this.get('store').findAll('message', {
          adapterOptions: { chatRoomId: id }
        });
      });
    },


    actions: {
      willTransition: function willTransition() {
        var chatRoom = this.controller.get('model');
        var id = chatRoom.get('id');
        this.get('polling').stopPolling('refreshMessages' + id);
      }
    }
  });
});