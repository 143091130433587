define('mvp-web/serializers/messages/text', ['exports', 'mvp-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'messages';
    }
  });
});