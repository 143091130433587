define('mvp-web/helpers/format-filesize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatFilesize = formatFilesize;
  function formatFilesize(size) {
    var exponent = Math.log(size) / Math.log(1024) | 0;
    var humanSize = +(size / Math.pow(1024, exponent)).toFixed(2);

    return humanSize + ' ' + ('kMGTPEZY'[exponent - 1] || '') + 'B';
  }

  exports.default = Ember.Helper.helper(formatFilesize);
});