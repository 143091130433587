define('mvp-web/routes/not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend({
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    redirect: function redirect() {
      var url = this.router.location.formatURL('/not-found');

      if (window.location.pathname !== url) {
        this.transitionTo('/not-found');
      }
    }
  });
});