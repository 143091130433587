define('mvp-web/models/phone-number-actions/ivr-action', ['exports', 'ember-data/attr', 'ember-cp-validations', 'ember-data/relationships', 'mvp-web/models/phone-number-action'], function (exports, _attr, _emberCpValidations, _relationships, _phoneNumberAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    type: [(0, _emberCpValidations.validator)('inclusion', {
      in: ['PhoneNumberActions::IvrAction']
    })]
  });

  exports.default = _phoneNumberAction.default.extend(Validations, {
    ivrSettings: (0, _attr.default)(),
    ivrTree: (0, _relationships.belongsTo)('ivrTree')
  });
});