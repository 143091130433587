define('mvp-web/routes/leads/index', ['exports', 'mvp-web/routes/groups/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    modelName: 'lead',
    queryParams: {
      x_date_from: { refreshModel: true }, // eslint-disable-line camelcase
      x_date_to: { refreshModel: true } // eslint-disable-line camelcase
    }

  });
});