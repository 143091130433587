define('mvp-web/routes/mvp-tests/buttongroup', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    features: service(),
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.get('features.mvpTests')) return;

      this.replaceWith('/not-found');
    }
  });
});