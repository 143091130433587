define('mvp-web/components/date-range-picker', ['exports', 'ember-cli-daterangepicker/components/date-range-picker', 'moment'], function (exports, _dateRangePicker, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dateRangePicker.default.reopen({
    ranges: {
      Today: [(0, _moment.default)(), (0, _moment.default)()],
      Yesterday: [(0, _moment.default)().subtract(1, 'day'), (0, _moment.default)().subtract(1, 'day')],
      'This Week': [(0, _moment.default)().startOf('isoWeek'), (0, _moment.default)()],
      'Last Week': [(0, _moment.default)().subtract(1, 'week').startOf('isoWeek'), (0, _moment.default)().subtract(1, 'week').endOf('isoWeek')],
      'Last 30 Days': [(0, _moment.default)().subtract(29, 'days'), (0, _moment.default)()],
      'This Month': [(0, _moment.default)().startOf('month'), (0, _moment.default)().endOf('month')],
      'Last Month': [(0, _moment.default)().subtract(1, 'month').startOf('month'), (0, _moment.default)().subtract(1, 'month').endOf('month')]
    }
  });
});