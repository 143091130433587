define('mvp-web/adapters/leads-import', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL() {
      var pathObject = [{ resourceName: 'organization', id: this.get('organizationId') }, { resourceName: 'leadsImport', id: null }];

      return this.buildNestedUrl(pathObject);
    }
  });
});