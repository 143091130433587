define('mvp-web/helpers/display-if-positive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.displayIfPositive = displayIfPositive;
  function displayIfPositive(params) {
    var displayChar = params[1] || '';
    return params[0] > 0 ? params[0] : displayChar;
  }

  exports.default = Ember.Helper.helper(displayIfPositive);
});