define('mvp-web/components/dashboard/performance-statistics/sales-activity/sales-activity-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['_sales-activity-table'],
    dataReady: false
  });
});