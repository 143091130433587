define('mvp-web/components/edit-toolbar', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      service = Ember.inject.service,
      $ = Ember.$,
      run = Ember.run;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    classNames: ['row edit-toolbar clearfix'],
    classNameBindings: ['isSticky', 'sidenav'],
    topPos: 99,
    isSticky: false,
    notifications: service('notification-messages'),
    sidenavService: service('sidenav'),
    sidenav: computed.reads('sidenavService.isOpen'),

    isNotFullyLoaded: computed.not('group.isFullyLoaded'),

    onWindowScroll: function onWindowScroll(e) {
      var scroll = $(e.currentTarget).scrollTop();
      var topPos = this.get('topPos');

      if (this.isDestroyed) {
        return;
      }
      this.set('isSticky', scroll > topPos);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var $editToolbar = $('.edit-toolbar');

      run(function () {
        _this._windowScroll = run.bind(_this, 'onWindowScroll');
        $(window).on('scroll', _this._windowScroll);

        if (_this.isDestroyed) {
          return;
        }
        _this.set('topPos', $editToolbar.offset().top);
      });
    },
    willRemoveElement: function willRemoveElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      run(function () {
        $(window).off('scroll', _this2._windowScroll);
      });
    },


    convertLabel: computed('isProspect', function () {
      return this.get('isProspect') ? 'Convert to Customer' : 'Convert to Prospect';
    }),

    showConvertToProspectButton: computed.alias('group.canBeConvertedToProspect'),

    showConvertToLeadButton: computed.not('hasOwner'),

    showMarkAsQuotedProspectButton: computed.alias('group.canBeConvertedToQuotedProspect'),

    showDeleteButton: computed.alias('group.canBeDeleted'),

    hasOwner: computed.alias('group.hasOwner'),

    isGroupNew: computed.alias('group.isNew'),

    showSoldOrCrossSellButton: computed.or('group.isQuotedProspect', 'group.isCustomer'),

    transitionState: computed.alias('group.transitionState')
  });
});