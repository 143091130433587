define('mvp-web/controllers/groups/edit', ['exports', 'mvp-web/mixins/group-controller-settings'], function (exports, _groupControllerSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_groupControllerSettings.default, {
    actions: {
      cancel: function cancel() {
        history.back();
      }
    }
  });
});