define('mvp-web/routes/prospects/new', ['exports', 'mvp-web/routes/groups/new'], function (exports, _new) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _new.default.extend({
    model: function model() {
      var address = this.store.createRecord('address', { usState: this.get('user.usState') });

      return this.store.createRecord('prospect', {
        address: address,
        user: this.get('user')
      });
    }
  });
});