define('mvp-web/models/phone-number-action', ['exports', 'ember-data/attr', 'ember-data/model', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _attr, _model, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    type: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Type'
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: ['PhoneNumberActions::ForwardAction', 'PhoneNumberActions::VoicemailAction', 'PhoneNumberActions::DialAction', 'PhoneNumberActions::IvrAction']
    })],
    label: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Label'
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 255
    })]
  });

  var validateNextActions = function validateNextActions(phoneNumberAction) {
    if (phoneNumberAction.get('isDialAction')) {
      return phoneNumberAction.get('validations.isValid') && validateNextActions(phoneNumberAction.get('nextAction'));
    }

    return phoneNumberAction.get('validations.isValid');
  };

  exports.default = _model.default.extend(Validations, {
    type: (0, _attr.default)(),
    label: (0, _attr.default)(),
    options: (0, _attr.default)('object'),

    organization: (0, _relationships.belongsTo)('organization'),
    nextAction: (0, _relationships.belongsTo)('phoneNumberAction', { polymorphic: true, inverse: null }),

    phoneNumbers: (0, _relationships.hasMany)('phoneNumber', { inverse: null }),

    modelName: computed.alias('constructor.modelName'),

    nextActionsValid: computed(function () {
      return validateNextActions(this);
    }),

    isForwardAction: computed('type', function () {
      var isForwardModel = this.get('modelName') === 'phone-number-actions/forward-action';
      var isForwardType = this.get('type') === 'PhoneNumberActions::ForwardAction';
      return isForwardModel || isForwardType;
    }),

    isVoicemailAction: computed('type', function () {
      var isVoicemailModel = this.get('modelName') === 'phone-number-actions/voicemail-action';
      var isVoicemailType = this.get('type') === 'PhoneNumberActions::VoicemailAction';
      return isVoicemailModel || isVoicemailType;
    }),

    isDialAction: computed('type', function () {
      var isDialModel = this.get('modelName') === 'phone-number-actions/dial-action';
      var isDialType = this.get('type') === 'PhoneNumberActions::DialAction';
      return isDialModel || isDialType;
    }),

    isIvrAction: computed('type', function () {
      var isIvrModel = this.get('modelName') === 'phone-number-actions/ivr-action';
      var isIvrType = this.get('type') === 'PhoneNumberActions::IvrAction';
      return isIvrModel || isIvrType;
    })
  });
});