define('mvp-web/components/drivers-browser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['row', 'drivers-browser'],
    firstLetterOfLastName: null,
    filteredDriversByLastName: null,
    maxResultsWithoutFiltering: 50,
    resultsFilteringEnabled: computed('drivers', function () {
      return this.get('drivers.length') > this.get('maxResultsWithoutFiltering');
    }),
    driverList: computed('filteredDriversByLastName', 'vehicles', 'drivers', function () {
      if (!this.get('resultsFilteringEnabled')) {
        return this.get('drivers');
      }

      return this.get('filteredDriversByLastName');
    }),

    actions: {
      toggleDriversBrowser: function toggleDriversBrowser() {
        this.sendAction('toggleDriversBrowser');
      },
      updateMemberByFullName: function updateMemberByFullName(memberAttributes) {
        this.sendAction('updateMemberByFullName', memberAttributes);
      },
      addThenSaveMember: function addThenSaveMember(memberAttributes) {
        this.sendAction('addThenSaveMember', memberAttributes);
      },
      filterDrivers: function filterDrivers() {
        var filterCharacter = this.get('firstLetterOfLastName').toLowerCase();
        var result = this.get('drivers').filter(function (item) {
          return item.get('lastName').toLowerCase().indexOf(filterCharacter) === 0;
        });
        this.set('filteredDriversByLastName', result);
      }
    }
  });
});