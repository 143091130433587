define('mvp-web/controllers/prospects/index', ['exports', 'mvp-web/controllers/groups/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    detailsPath: 'prospects.edit',
    transitionStateIsProspect: true,
    transitionStateIsCustomer: false,
    modelName: 'prospect',
    status: 'quoted'
  });
});