define('mvp-web/routes/users/quotes-defaults', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route,
      RSVP = Ember.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    model: function model(params) {
      return RSVP.hash({
        vehicleQuoteTemplate: this.store.find('vehicle-quote-template', params.id),
        homeQuoteTemplate: this.store.find('home-quote-template', params.id),
        lifeQuoteTemplate: this.store.find('life-quote-template', params.id),
        umbrellaQuoteTemplate: this.store.find('umbrella-quote-template', params.id),
        specialtyQuoteTemplate: this.store.find('specialty-quote-template', params.id),
        commercialQuoteTemplate: this.store.find('commercial-quote-template', params.id),
        callLogTemplate: this.store.find('call-log-template', params.id),
        lifePolicyTypes: this.store.findAll('life-policy-type'),
        paymentOptions: this.store.findAll('payment-option'),
        paymentTerms: this.store.findAll('payment-term')
      });
    }
  });
});