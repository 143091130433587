define('mvp-web/components/quote-forms/life/life-quote', ['exports', 'mvp-web/mixins/quote-shareable'], function (exports, _quoteShareable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_quoteShareable.default, {
    store: service(),

    policyTypes: computed(function () {
      return this.get('store').findAll('life-policy-type');
    }),

    paymentOptions: computed(function () {
      return this.get('store').findAll('payment-option');
    }),

    paymentSelections: computed.filterBy('paymentOptions', 'appliesForLife'),

    availableInsuranceCarriers: computed(function () {
      return this.get('isFirstParty') ? this.get('captiveInsuranceCarriers') : this.get('insuranceCarriers');
    }),

    insuranceCarriers: computed(function () {
      return this.get('store').query('insurance-carrier', { for: 'life' });
    }),

    policyName: computed('isFirstParty', 'index', function () {
      var index = this.get('index');

      return this.get('isFirstParty') ? 'Your Policy ' + (index + 1) : 'Competitor Policy ' + (index + 1);
    }),

    isNotFirstQuote: computed.gt('index', 0),

    isSold: computed('quote.markedAsSold', function () {
      return this.get('quote.markedAsSold');
    })
  });
});