define('mvp-web/helpers/number-plus-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberPlusOne = numberPlusOne;


  /**
   * Returns the provided number plus one
   * Useful for iterators
   * @param params
   * @returns {number}
   */

  function numberPlusOne(params) {
    return params[0] + 1;
  }

  exports.default = Ember.Helper.helper(numberPlusOne);
});