define('mvp-web/components/existing-policies/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    addingPolicy: false,

    editingPolicies: [],

    actions: {
      addPolicy: function addPolicy() {
        var group = this.get('group');
        this.get('store').createRecord('existing-policy', {
          policyTerm: '6 months',
          group: group
        });
      },
      disableForm: function disableForm() {
        this.set('addingPolicy', false);
      }
    }
  });
});