define('mvp-web/models/messages/attachment', ['exports', 'ember-data/attr', 'mvp-web/models/message'], function (exports, _attr, _message) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _message.default.extend({
    attachment: (0, _attr.default)('string'),

    hasAttachment: true
  });
});