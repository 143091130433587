define('mvp-web/adapters/organization-setting', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(type, id, snapshot) {
      var organization = snapshot ? snapshot.belongsTo('organization') : null;
      var organizationId = organization ? organization.id : null;

      // The array order matters
      var pathObject = [{ resourceName: 'organization', id: organizationId }, { resourceName: 'organization-setting', id: id }];

      return this.buildNestedUrl(pathObject);
    }
  });
});