define('mvp-web/components/quote-forms/specialty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      selectPaymentTerm: function selectPaymentTerm(quote, selectedOption) {
        quote.setPaymentTerm(selectedOption);
      }
    }
  });
});