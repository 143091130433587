define('mvp-web/helpers/invalid-thick-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.invalidThickClass = invalidThickClass;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function invalidThickClass(params) {
    var _params = _toArray(params),
        isValid = _params[0],
        classes = _params.slice(1);

    if (!isValid) {
      classes.push('red-thick-border');
    }
    return classes.join(' ');
  }

  exports.default = Ember.Helper.helper(invalidThickClass);
});