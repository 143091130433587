define('mvp-web/components/phone-numbers/phone-number-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    confirmDeleteId: computed('phoneNumber.id', function () {
      return 'confirm-delete-' + this.get('phoneNumber.id');
    }),

    confirmUnarchiveId: computed('phoneNumber.id', function () {
      return 'confirm-unarchive-' + this.get('phoneNumber.id');
    }),

    editModalId: computed('phoneNumber.id', function () {
      return 'edit-phone-number-' + this.get('phoneNumber.id');
    }),

    editModalTitle: computed('phoneNumber.isSetup', function () {
      return this.get('phoneNumber.isSetup') ? 'Phone Number Setup' : 'Edit Phone Number';
    }),

    verifyCallerIdModalId: computed('phoneNumber.id', function () {
      return 'verify-caller-id-' + this.get('phoneNumber.id');
    }),

    resetSipPasswordModalId: computed('phoneNumber.id', function () {
      return 'reset-sip-password-' + this.get('phoneNumber.id');
    }),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});