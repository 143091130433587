define('mvp-web/components/phone-numbers/add-phone-number/select-an-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      setActiveComponent: function setActiveComponent(componentName) {
        this.sendAction('setActiveComponent', componentName);
      }
    }
  });
});