define('mvp-web/routes/users/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var service = Ember.inject.service,
      Route = Ember.Route,
      RSVP = Ember.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },


    queryParams: {
      queryTerm: { refreshModel: true },
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    model: function model(params) {
      var pageParams = { queryTerm: params.queryTerm, page: params.page, size: params.size };
      var newParams = _extends({}, params, pageParams, { status: 'active' });
      var newParamsWithPendingStatus = _extends({}, newParams, { status: 'pending' });
      var newParamsWithInactiveStatus = _extends({}, newParams, { status: 'inactive' });

      return RSVP.hash({
        regularModel: this.store.query('user', newParams),
        pendingModel: this.store.query('user', newParamsWithPendingStatus),
        inactiveModel: this.store.query('user', newParamsWithInactiveStatus)
      });
    },
    setupController: function setupController(controller, models) {
      controller.setProperties({
        model: models.regularModel,
        pendingModel: models.pendingModel,
        inactiveModel: models.inactiveModel
      });
    }
  });
});