define('mvp-web/serializers/application', ['exports', 'ember-data/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var get = Ember.get,
      isNone = Ember.isNone,
      _Ember$String = Ember.String,
      singularize = _Ember$String.singularize,
      underscore = _Ember$String.underscore;
  exports.default = _jsonApi.default.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return underscore(attr);
    },
    keyForRelationship: function keyForRelationship(key) {
      return underscore(key);
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      payloadKey = payloadKey.replace(/--/g, '/');
      return this._super(payloadKey);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
      var result = this._super.apply(this, arguments);
      result.meta = result.meta || {};

      if (payload.links) {
        result.meta.pagination = this._createPageMeta(payload.links);
      }

      return result;
    },
    serialize: function serialize(snapshot, options) {
      var _this = this;

      var json = {};

      if (options && options.includeId) {
        var id = snapshot.id;


        if (id) {
          json[get(this, 'primaryKey')] = id;
        }
      }

      var serializableAttrs = this.get('attrs') || {};

      snapshot.eachAttribute(function (key, attribute) {
        var attrName = serializableAttrs[key];
        var serializable = attrName ? attrName.serialize : true;

        if (serializable) {
          _this.serializeAttribute(snapshot, json, key, attribute);
        }
      });

      snapshot.eachRelationship(function (key, relationship) {
        if (relationship.kind === 'belongsTo') {
          _this.serializeBelongsTo(snapshot, json, relationship);
        } else if (relationship.kind === 'hasMany') {
          _this.serializeHasMany(snapshot, json, relationship);
        }
      });

      return json;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      var type = attribute.type;


      if (this._canSerialize(underscore(key))) {
        var value = snapshot.attr(key);
        if (type) {
          var transform = this.transformFor(type);
          value = transform.serialize(value, attribute.options);
        }

        // if provided, use the mapping provided by `attrs` in the serializer
        var payloadKey = this._getMappedKey(key, snapshot.type);

        if (payloadKey === key && this.keyForAttribute) {
          payloadKey = this.keyForAttribute(key, 'serialize');
        }

        json[payloadKey] = value;
      }
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;


      if (this._canSerialize(key)) {
        var belongsToId = snapshot.belongsTo(key, { id: true });

        // if provided, use the mapping provided by `attrs` in the serializer
        var payloadKey = this._getMappedKey(key, snapshot.type);
        if (payloadKey === key && this.keyForRelationship) {
          payloadKey = this.keyForRelationship(key, 'belongsTo', 'serialize');
        }

        // Need to check whether the id is there for new&async records
        if (isNone(belongsToId)) {
          json[payloadKey] = null;
        } else {
          json[payloadKey] = belongsToId;
        }

        if (relationship.options.polymorphic) {
          this.serializePolymorphicType(snapshot, json, relationship);
        }
      }
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      var shouldSerializeHasMany = 'shouldSerializeHasMany';

      if (this[shouldSerializeHasMany](snapshot, key, relationship)) {
        var hasMany = snapshot.hasMany(key, { ids: true });
        if (hasMany !== undefined) {
          // if provided, use the mapping provided by `attrs` in the serializer
          var payloadKey = this._getMappedKey(key, snapshot.type);
          if (payloadKey === key && this.keyForRelationship) {
            payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
          }

          json[payloadKey] = hasMany;
          // TODO support for polymorphic manyToNone and manyToMany relationships
        }
      }
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      // singularize the root key of the primary payload
      var normalizedRootKey = underscore(singularize(this.payloadKeyFromModelName(typeClass.modelName)));
      hash[normalizedRootKey] = this.serialize(snapshot, options);
    },


    // Private Methods

    _createPageMeta: function _createPageMeta(data) {
      var meta = {};

      Object.keys(data).forEach(function (type) {
        var link = data[type];
        meta[type] = {};
        var aTag = document.createElement('a');
        aTag.href = link;

        aTag.search.slice(1).split('&').forEach(function (pairs) {
          var _pairs$split = pairs.split('='),
              _pairs$split2 = _slicedToArray(_pairs$split, 2),
              param = _pairs$split2[0],
              value = _pairs$split2[1];

          if (param === 'page%5Bnumber%5D') {
            meta[type].number = parseInt(value);
          }
          if (param === 'page%5Bsize%5D') {
            meta[type].size = parseInt(value);
          }
        });
        aTag = null;
      });

      return meta;
    }
  });
});