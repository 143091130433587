define('mvp-web/models/duplicated-group', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    fullName: (0, _attr.default)(),
    transitionState: (0, _attr.default)(),

    route: computed('transitionState', function () {
      return Ember.String.pluralize(this.get('transitionState')) + '.edit';
    })
  });
});