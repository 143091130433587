define('mvp-web/helpers/transform-word', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformWord = transformWord;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  /**
   * Transforms words into desired formats given a "transformType"
   * @param params
   * @returns {string}
   */

  function transformWord(params) {
    var _params = _toArray(params),
        transformType = _params[0],
        words = _params.slice(1);

    var string = '';

    switch (transformType) {
      case 'capitalize':
        string = words.map(function (word) {
          return word.capitalize();
        }).join(' ');
        break;
      case 'pluralize':
        string = words.map(function (word) {
          return word.pluralize();
        }).join(' ');
        break;
      case 'lowercase':
        string = words.map(function (word) {
          return word.toLowerCase();
        }).join(' ');
        break;
    }

    return string.trim();
  }

  exports.default = Ember.Helper.helper(transformWord);
});