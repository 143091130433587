define('mvp-web/controllers/new-leads/edit', ['exports', 'mvp-web/controllers/leads/edit'], function (exports, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _edit.default.extend({
    detailsPath: 'leads.edit',
    primaryActionButtonLabel: 'Save',
    actions: {
      delete: function _delete(group) {
        this.set('isBeingDeleted', true);
        group.destroyRecord();
        this.transitionToRoute('new-leads.index');
      }
    }
  });
});