define('mvp-web/adapters/deleted-household', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    restoreDeletedHousehold: function restoreDeletedHousehold(groupId) {
      var url = this.get('namespace') + '/deleted_households/' + groupId + '/restore';

      return this.ajax(url, 'PUT');
    }
  });
});