define('mvp-web/helpers/array-contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayContains = arrayContains;
  var isNone = Ember.isNone;
  function arrayContains(params) {
    var array = params[0];
    var value = params[1];
    var property = params[2];

    if (isNone(array)) array = [];
    if (array && isNone(array.length)) array = [];

    if (property) return array.some(function (object) {
      return object[property] === value;
    });

    return array.includes(value);
  }

  exports.default = Ember.Helper.helper(arrayContains);
});