define('mvp-web/helpers/sum', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sum = sum;
  function sum(params) {
    return params.reduce(function (a, b) {
      return a + b;
    });
  }

  exports.default = Ember.Helper.helper(sum);
});