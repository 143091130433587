define('mvp-web/services/constants/call-back-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    action1: {
      title: 'Call Back Today',
      label: 'Call Back Today',
      description: 'By scheduling today, you are allowing this household to be ranked according to the applications internal algorithms.',
      customClass: 'call-back-today'
    },
    action2: {
      title: 'Call Tomorrow',
      label: 'Call Tomorrow',
      description: 'By scheduling tomorrow, you are allowing this household to be ranked according to the applications internal algorithms.',
      customClass: 'call-back-tomorrow'
    },
    action3: {
      title: 'Call Back Specific Date/Time',
      label: 'Call Back Specific Date/Time',
      description: 'By scheduling a specific date and time, you are removing the ability to have this household ranked according to the application internal algorithms.',
      customClass: 'call-back-specific-date-time'
    },
    action4: {
      title: 'Call Back Future Date',
      label: 'Call Back Future Date',
      description: 'By scheduling a future call back date, you are allowing this household to be ranked according to the application internal algorithms and it will not show in your call log until the date you have specified.',
      customClass: 'call-back-future'
    },
    nextActionGeneralDescription: 'By scheduling a future call back date, you are allowing this household to be ranked according to the application internal algorithms and it will not show in your call log until the date you have specified.'
  };
});