define('mvp-web/components/quote-forms/vehicle/vehicle-coverage', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    coverage: computed('vehicleQuote', 'vehicleQuote.vehicleCoverages.[]', function () {
      var vehicle = this.get('vehicle');
      var coverages = this.get('vehicleQuote.vehicleCoverages');
      return coverages ? coverages.find(function (coverage) {
        return coverage.get('vehicle.content') === vehicle;
      }) : {};
    }),

    isCovered: computed('vehicleQuote.vehicleCoverages.[]', function () {
      var quote = this.get('vehicleQuote');
      return quote ? quote.get('vehicleCoverages').includes(this.get('coverage')) : false;
    }),

    actions: {
      toggleCoverage: function toggleCoverage() {
        if (this.get('isCovered')) {
          this.sendAction('removeVehicleCoverage', this.get('coverage'));
        } else {
          this.sendAction('addVehicleCoverage', this.get('vehicle'), this.get('vehicleQuote'));
        }
      }
    }
  });
});