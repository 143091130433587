define('mvp-web/routes/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      run = Ember.run;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: service(),

    redirect: function redirect() {
      var _this = this;

      var organization = this.get('session.currentUserOrganization');

      run(function () {
        organization.then(function (org) {
          if (org.get('active')) {
            return _this.transitionTo('prospects.index');
          }
          return _this.transitionTo('organizations.account', org);
        });
      });
    }
  });
});