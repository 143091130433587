define('mvp-web/components/quote-forms/home/home-deductible', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    fieldClass: computed('deductible.name', function () {
      var inputClass = 'form-control input-sm deductible-amount';

      if (this.get('deductible.name')) {
        var deductibleName = this.get('deductible.name').toLowerCase();

        var dashedCasedName = deductibleName.replace(/[^a-zA-Z0-9]/g, '-');

        inputClass += ' deductible-' + dashedCasedName + '-field';
      }

      return inputClass;
    })
  });
});