define('mvp-web/routes/imports/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    session: service(),
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    setupController: function setupController(controller) {
      controller.send('resetLoaders');
      controller.get('loadWorkflows').perform();
      var organizationId = this.get('session.session.authenticated.organization_id');

      controller.setProperties({
        availableTags: this.store.findAll('tag'),
        organization: this.store.findRecord('organization', organizationId),
        referralSourceId: '',
        tags: []
      });
    }
  });
});