define('mvp-web/components/basic-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    isValid: true,

    classNames: ['_basic-input'],

    validationClass: computed('isValid', function () {
      return this.get('isValid') ? 'is-valid' : 'is-invalid';
    }),

    cssClasses: computed('validationClass', function () {
      return this.get('customClasses') + ' ' + this.get('validationClass');
    })
  });
});