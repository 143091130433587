define('mvp-web/components/page-numbers', ['exports', 'mvp-web/mixins/page-number'], function (exports, _pageNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      pluralize = Ember.String.pluralize;
  exports.default = Component.extend(_pageNumber.default, {
    span: 3, // num of range to show

    // count computed property from mixin

    routeName: computed('model', function () {
      return pluralize(this.get('model.content.firstObject.modelName'));
    })
  });
});