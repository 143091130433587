define('mvp-web/mixins/active-components', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    _setActiveComponent: function _setActiveComponent(componentName) {
      var components = this.get('components');

      if (!components.includes(componentName)) return;

      this.set('currentComponent', componentName);
    },


    actions: {
      setActiveComponent: function setActiveComponent(componentName) {
        this._setActiveComponent(componentName);
      }
    }
  });
});