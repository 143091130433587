define('mvp-web/components/quote-templates-forms/life', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    store: service(),

    paymentSelections: computed.filterBy('paymentOptions', 'appliesForLife'),

    selectedPaymentOption: computed('paymentOptions', 'template.paymentOptionId', function () {
      var _this = this;

      if (this.get('paymentOptions')) {
        return this.get('paymentOptions').toArray().find(function (paymentOption) {
          return parseInt(paymentOption.get('id')) === parseInt(_this.get('template.paymentOptionId'));
        });
      }

      return null;
    }),

    selectedLifePolicyType: computed('lifePolicyTypes', 'template.lifePolicyTypeId', function () {
      var _this2 = this;

      if (this.get('lifePolicyTypes')) {
        return this.get('lifePolicyTypes').toArray().find(function (lifePolicyType) {
          return parseInt(lifePolicyType.get('id')) === parseInt(_this2.get('template.lifePolicyTypeId'));
        });
      }

      return null;
    }),

    actions: {
      selectPaymentOption: function selectPaymentOption(selectedOption) {
        var option = null;
        if (selectedOption) option = parseInt(selectedOption.id);
        this.get('template').set('paymentOptionId', option);
      },
      selectLifePolicyType: function selectLifePolicyType(selectedOption) {
        var option = null;
        if (selectedOption) option = parseInt(selectedOption.id);
        this.get('template').set('lifePolicyTypeId', option);
      }
    }
  });
});