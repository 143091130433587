define('mvp-web/components/quote-forms/home/home-coverage', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_powerSelectHighlighted.default, {

    coverageIdAppendage: Ember.computed('coverage', function () {
      return this.get('coverage.id') || '';
    }),

    actions: {
      selectGuestMedicalCoverage: function selectGuestMedicalCoverage(selectedOption) {
        var selectedItem = selectedOption ? Number(selectedOption.id) : null;
        this.get('coverage').set('guestMedicalCoverageId', selectedItem);
      },
      selectLivingExpenseTerm: function selectLivingExpenseTerm(selectedOption) {
        var selectedItem = selectedOption ? Number(selectedOption.id) : null;
        this.get('coverage').set('livingExpenseTermId', selectedItem);
      },
      selectPersonalLiabilityCoverage: function selectPersonalLiabilityCoverage(selectedOption) {
        var selectedItem = selectedOption ? Number(selectedOption.id) : null;
        this.get('coverage').set('personalLiabilityCoverageId', selectedItem);
      },
      setPersonalLiabilityFromExtension: function setPersonalLiabilityFromExtension() {
        var possibleOptions = this.get('formOptions.personalLiabilityCoverages');
        var valueFromHtml = Ember.$('.personal-liability-' + this.get('coverageIdAppendage') + ' .ember-power-select-selected-item').text();
        var selectedOption = possibleOptions.find(function (option) {
          return option.name.trim().replaceAll(',', '') === valueFromHtml.trim();
        });
        if (selectedOption) this.get('coverage').set('personalLiabilityCoverageId', selectedOption.id);
      },
      setLivingExpenseFromExtension: function setLivingExpenseFromExtension() {
        var possibleOptions = this.get('formOptions.livingExpenseTerms');
        var valueFromHtml = Ember.$('.living-expense-' + this.get('coverageIdAppendage') + ' .ember-power-select-selected-item').text();
        var selectedOption = possibleOptions.find(function (option) {
          return option.name.trim().replaceAll(',', '') === valueFromHtml.trim();
        });
        if (selectedOption) this.get('coverage').set('livingExpenseTermId', selectedOption.id);
      },
      setGuestMedicalFromExtension: function setGuestMedicalFromExtension() {
        var possibleOptions = this.get('formOptions.guestMedicalCoverages');
        var valueFromHtml = Ember.$('.guest-medical-' + this.get('coverageIdAppendage') + ' .ember-power-select-selected-item').text();
        var selectedOption = possibleOptions.find(function (option) {
          return option.name.trim().replaceAll(',', '') === valueFromHtml.trim();
        });
        if (selectedOption) this.get('coverage').set('guestMedicalCoverageId', selectedOption.id);
      }
    }
  });
});