define('mvp-web/components/downloadable-reports/custom-range-report', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    dateRange: '',
    customDateRange: computed('dateRange', function () {
      return this.dateRange;
    }),

    baseParams: computed('dateRange', function () {
      return {
        range: null,
        start_time: this.startDate,
        end_time: this.endDate
      };
    }),

    actions: {
      handleDateRangeChanged: function handleDateRangeChanged(startDate, endDate) {
        var startNum = startDate.split('-');
        var endNum = endDate.split('-');

        var startDays = (0, _moment.default)([startNum[0], startNum[1], startNum[2]]);
        var endDays = (0, _moment.default)([endNum[0], endNum[1], endNum[2]]);
        var daysDiff = endDays.diff(startDays, 'days');

        this.set('dateRange', daysDiff + '.days');
      }
    }

  });
});