define('mvp-web/models/subscription-event', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    organization: (0, _relationships.belongsTo)('organization'),
    subscription: (0, _relationships.belongsTo)('subscription'),
    subscriptionPlan: (0, _relationships.belongsTo)('subscriptionPlan'),
    status: (0, _attr.default)('string'),
    eventDate: (0, _attr.default)('utc'),
    stripeId: (0, _attr.default)('string')
  });
});