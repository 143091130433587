define('mvp-web/models/specialty-quote-template', ['exports', 'ember-data/attr', 'mvp-web/models/specialty-quote', 'mvp-web/utils/magic-ids'], function (exports, _attr, _specialtyQuote, _magicIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      getProperties = Ember.getProperties;
  exports.default = _specialtyQuote.default.extend({
    oneTimeFee: (0, _attr.default)('number'),
    premium: (0, _attr.default)('number'),
    insuranceCoverageOptionId: (0, _attr.default)('number'),
    paymentOptionId: (0, _attr.default)('number'),
    specialtyPolicyTypeId: (0, _attr.default)('number'),

    defaultAttrs: computed(function () {
      return this._allowedAttrs(this.toJSON());
    }),

    paymentOptionAttrs: function paymentOptionAttrs() {
      return {
        isDefault: true,
        paymentOptionId: this.get('paymentOptionId') || _magicIds.default.payInFullId,
        oneTimeFee: this.get('oneTimeFee'),
        premium: this.get('premium')
      };
    },
    _allowedAttrs: function _allowedAttrs(attrs) {
      return getProperties(attrs, 'oneTimeFee', 'insuranceCarrierId', 'insuranceCoverageOptionId', 'paymentOptionId', 'specialtyPolicyTypeId', 'selectedInsuranceCarrier', 'premium');
    }
  });
});