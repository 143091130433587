define('mvp-web/serializers/group', ['exports', 'mvp-web/serializers/application', 'mvp-web/mixins/embedded-relationships'], function (exports, _application, _embeddedRelationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRelationships.default, {
    attrs: {
      address: { serialize: true },
      commercials: { serialize: true },
      competitorRenewalDates: { serialize: false },
      history: { serialize: false },
      homes: { serialize: true },
      homeQuotes: { serialize: true },
      lifeQuotes: { serialize: true },
      umbrellaQuotes: { serialize: true },
      specialtyQuotes: { serialize: true },
      commercialQuotes: { serialize: true },
      isRowExpanded: { serialize: false },
      members: { serialize: true },
      phoneCalls: { serialize: false },
      primaryMember: { serialize: true },
      quoteOriginMember: { serialize: true },
      score: { serialize: false },
      user: { serialize: true },
      vehicles: { serialize: true },
      vehicleQuotes: { serialize: true },
      uploadedFiles: { serialize: true },
      primaryMemberId: { serialize: true },
      quoteOriginMemberId: { serialize: true },
      task: { serialize: true }
    }
  });
});