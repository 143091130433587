define('mvp-web/models/external-number', ['exports', 'ember-data/attr', 'ember-data/model', 'ember-data/relationships', 'ember-cp-validations', 'mvp-web/helpers/format-phone'], function (exports, _attr, _model, _relationships, _emberCpValidations, _formatPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    number: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'External Number'
    }),
    label: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'External Number Name'
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 255
    })]
  });

  exports.default = _model.default.extend(Validations, {
    number: (0, _attr.default)(),
    label: (0, _attr.default)(),
    organization: (0, _relationships.belongsTo)('organization'),

    formattedPhone: computed('number', function () {
      return (0, _formatPhone.formatPhoneDasherized)(this.get('number'));
    }),

    fullDescription: computed('number', 'label', function () {
      return this.get('formattedPhone') + ' (' + this.get('label') + ')';
    })
  });
});