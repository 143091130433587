define('mvp-web/helpers/first-last-to-initials', ['exports', 'lodash/lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.firstLastToInitials = firstLastToInitials;


  /**
   * Converts a space separated string into initials
   * @param params
   * @returns {string}
   */

  function firstLastToInitials(params) {
    var firstName = params[0] || '';
    var lastName = params[1] || '';
    var fullName = _lodash.default.trimStart(firstName + ' ' + lastName);

    // Check to make sure we have first/last
    // If not, just return the first two letters of the fullName

    if (fullName.length > 0 && fullName.split(' ').length < 2) {
      return _lodash.default.toUpper(fullName.substring(0, 2));
    }

    // If we have first/last, go ahead and initial them.

    return _lodash.default.map(_lodash.default.words(fullName), function (name) {
      return _lodash.default.toUpper(name.substring(0, 1));
    }).join('');
  }

  exports.default = Ember.Helper.helper(firstLastToInitials);
});