define('mvp-web/mixins/model-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cleanUpNestedModels: function cleanUpNestedModels(nestedModelName) {
      /*
       * This is necessary because certain models are being saved as nested resources to do away with unnecessary
       * API calls. As a side affect, Ember has no idea that a new resource was created, leaving the old resource
       * around, which shows up on the UI and will confuse the user.
       * */
      this.get(nestedModelName).forEach(function (model) {
        if (model && model.get('isNew')) {
          model.rollbackAttributes();
        }
      });
    }
  });
});