define('mvp-web/validations/organization-settings-validations', ['exports', 'ember-changeset-validations/validators', 'ember-validators'], function (exports, _validators, _emberValidators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validateEitherPhoneNumber = function validateEitherPhoneNumber() {
    return function (key, newValue, oldValue, changes, content) {
      var isThisValid = validatePhoneNumber(newValue, key);

      if (isThisValid) {
        return true;
      }

      return 'Organization phone number must be a valid phone number';
    };
  };

  var validatePhoneNumber = function validatePhoneNumber(value, key) {
    return (0, _emberValidators.validate)('format', value, { type: 'phone' }, null, key) === true && (0, _emberValidators.validate)('presence', value, { presence: true }, null, key) === true;
  };

  exports.default = {
    organizationName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2 })],
    organizationNumber: [validateEitherPhoneNumber()],
    timezone: [(0, _validators.validatePresence)(true)],
    street1: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 4 })],
    city: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2 })],
    usState: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2 })],
    postalCode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 5 }), (0, _validators.validateNumber)({ integer: true })],
    officialBusinessName: [],
    taxId: [],
    websiteUrl: [(0, _validators.validateLength)({ max: 100 })],
    businessType: [(0, _validators.validateInclusion)({ list: ['Sole Proprietorship', 'Partnership', 'Limited Liability Corporation', 'Co-operative', 'Non-profit Corporation', 'Corporation'] })]
  };
});