define('mvp-web/services/agents-activity-chart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    createChart: function createChart(_ref) {
      var activitiesByUser = _ref.activitiesByUser,
          element = _ref.element,
          isCurrency = _ref.isCurrency;

      var userLineData = this._createUserLineData(activitiesByUser);

      if (activitiesByUser) {
        return Morris.Bar({
          barColors: ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', '#d9d9d9', '#bc80bd', '#ccebc5', '#ffed6f'],
          data: userLineData.data,
          element: element,
          gridLineColor: '#e3e3e3',
          gridTextColor: '#666',
          hideHover: true,
          labels: userLineData.ykeys,
          preUnits: isCurrency ? '$' : '',
          resize: true,
          stacked: true,
          xkey: 'date',
          xLabels: 'day',
          ykeys: userLineData.ykeys
        });
      }
    },


    // We need to do that because as long as we use old library Morris.js
    refresh: function refresh(chart) {
      if (chart) {
        chart.redraw();
        chart.el.find('svg').css({ width: '100%' });
      }
    },
    _createUserLineData: function _createUserLineData() {
      var byUserData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var axisData = [];
      var lineData = [];

      byUserData.forEach(function (user) {
        var axisLabel = user.first_name + ' ' + user.last_name;
        var userByDate = user.by_date || [];

        userByDate.forEach(function (datum, i) {
          lineData[i] = lineData[i] || {};
          lineData[i].date = datum.date;
          lineData[i][axisLabel] = datum.value;
        });
        axisData.push(axisLabel);
      });

      return {
        data: lineData,
        ykeys: axisData
      };
    }
  });
});