define('mvp-web/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      run = Ember.run,
      Controller = Ember.Controller;
  exports.default = Controller.extend({
    notifications: service('notification-messages'),
    session: service(),
    store: service(),

    thisYear: computed(function () {
      return new Date().getFullYear();
    }),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _getProperties = this.getProperties('identification', 'password'),
            identification = _getProperties.identification,
            password = _getProperties.password;

        this.get('session').authenticate('authenticator:devise', identification, password).catch(function (error) {
          var errorMessage = error ? error.error : 'Whoops! Unable to login with that email and password.';
          run.schedule('afterRender', _this, function () {
            _this.get('notifications').error(errorMessage);
          });
        });
      }
    }
  });
});