define('mvp-web/components/chat-room-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    message: null,
    sentByMember: computed('message', function () {
      return this.get('message.sender.content._internalModel.modelName') === 'member';
    }),

    messageComponentName: computed(function () {
      var modelName = this.get('message.constructor.modelName') || 'messages/text';
      return 'message-items/' + modelName;
    })
  });
});