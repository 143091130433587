define('mvp-web/components/call-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    activeCallService: service('active-call'),
    call: service(),

    hasExternalCall: computed('activeCallService.activeCall', 'call.emptyOngoingCall', function () {
      return this.get('activeCallService.activeCall') && this.get('call.emptyOngoingCall');
    })
  });
});