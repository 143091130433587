define('mvp-web/instance-initializers/segmentio', ['exports', 'ember-cli-segment/instance-initializer', 'mvp-web/config/environment'], function (exports, _instanceInitializer, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'segment',
    initialize: function initialize(applicationInstance) {
      var segment = applicationInstance.lookup('service:segment');

      segment.set('config', _environment.default);
      _instanceInitializer.default.apply(undefined, arguments);
    }
  };
});