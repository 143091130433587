define('mvp-web/authenticators/impersonate', ['exports', 'ember-simple-auth/authenticators/base', 'mvp-web/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      isEmpty = Ember.isEmpty,
      run = Ember.run,
      RSVP = Ember.RSVP;
  exports.default = _base.default.extend({
    identificationAttributeName: 'email',
    rejectWithXhr: false,
    resourceName: 'user',
    tokenAttributeName: 'token',

    restore: function restore(data) {
      return this._validate(data) ? RSVP.Promise.resolve(data) : RSVP.Promise.reject();
    },
    invalidate: function invalidate() {
      return RSVP.Promise.resolve();
    },
    authenticate: function authenticate(user) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var useXhr = _this.get('rejectWithXhr');

        var _getProperties = _this.getProperties('resourceName', 'tokenAttributeName'),
            resourceName = _getProperties.resourceName,
            tokenAttributeName = _getProperties.tokenAttributeName;

        var serverUrl = _environment.default.apiEndpointVersion + '/users/' + user.id + '/impersonate';

        return _this.makeRequest(serverUrl).then(function (response) {
          if (_this._validate(response)) {
            var _response = response[resourceName] ? response[resourceName] : response;
            run(null, resolve, _response);
          } else {
            run(null, reject, 'Check that server response includes ' + tokenAttributeName);
          }
        }, function (xhr) {
          return run(null, reject, useXhr ? xhr : xhr.responseJSON || xhr.responseText);
        });
      });
    },
    makeRequest: function makeRequest(serverUrl, options) {
      var requestOptions = $.extend({}, {
        url: serverUrl,
        type: 'POST',
        dataType: 'json',
        beforeSend: function beforeSend(xhr, settings) {
          xhr.setRequestHeader('Accept', settings.accepts.json);
        }
      }, options || {});

      return $.ajax(requestOptions);
    },
    _validate: function _validate(data) {
      var tokenAttributeName = this.get('tokenAttributeName');
      var identificationAttributeName = this.get('identificationAttributeName');
      var resourceName = this.get('resourceName');
      var _data = data[resourceName] ? data[resourceName] : data;

      return !isEmpty(_data[tokenAttributeName]) && !isEmpty(_data[identificationAttributeName]);
    }
  });
});