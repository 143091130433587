define('mvp-web/components/quote-templates-forms/vehicle', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    store: service(),
    captiveInsuranceCarriers: computed(function () {
      return this.get('store').query('insurance-carrier', { captive: 'true' });
    }),

    selectedPaymentOption: computed('paymentOptionId', function () {
      var paymentOptionId = this.get('template.paymentOptionId');
      return paymentOptionId ? this.get('store').findRecord('payment-option', paymentOptionId) : null;
    }),

    paymentSelections: computed.filterBy('paymentOptions', 'appliesForVehicle'),

    paymentOptions: computed(function () {
      return this.get('store').findAll('payment-option');
    }),

    actions: {
      selectDraftDate: function selectDraftDate(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('template').setDraftDate(id);
      },
      selectPaymentOption: function selectPaymentOption(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('template').setPaymentOption(id);
      },
      selectPolicyPlan: function selectPolicyPlan(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('template').set('paymentPlanId', id);
      },
      selectPaymentTerm: function selectPaymentTerm(selectedOption) {
        var id = selectedOption ? Number(selectedOption.id) : null;
        this.get('template').set('paymentTermId', id);
      }
    }
  });
});