define('mvp-web/models/vehicle-coverage', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var computed = Ember.computed;
  exports.default = _model.default.extend({
    collisionCoverage: (0, _attr.default)(),
    comprehensiveCoverage: (0, _attr.default)(),
    rentalCarCoverage: (0, _attr.default)(),
    towingCoverage: (0, _attr.default)(),
    vehicle: (0, _relationships.belongsTo)('vehicle'),
    vehicleQuote: (0, _relationships.belongsTo)('vehicleQuote'),

    quote: computed.alias('vehicleQuote'),

    clone: function clone(targetModel) {
      var model = this;
      var attrs = model.toJSON();
      var root = model._internalModel.modelName;

      model.eachRelationship(function (key, relationship) {
        if (relationship.kind === 'belongsTo') {
          attrs[key] = model.get(key);
        }
      });

      // eslint-disable-next-line no-unused-vars
      var finalAttrs = _extends({}, attrs, { quote: targetModel });
      var clonedModel = this.store.createRecord(root, attrs);

      return clonedModel;
    }
  });
});