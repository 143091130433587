define('mvp-web/mixins/score-labelable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    tagLabel: function tagLabel(score, isSum) {
      if (isSum == null) {
        isSum = false;
      }

      var scale = isSum ? 15 : 0;

      if (score > 1 + scale && 5 + scale > score) {
        return 'info';
      }if (score >= 5 + scale && 8 + scale > score) {
        return 'warning';
      }if (score >= 8 + scale) {
        return 'success';
      }
      return 'default';
    }
  });
});