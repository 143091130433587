define('mvp-web/controllers/organizations/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Controller = Ember.Controller;
  exports.default = Controller.extend({
    page: 1,
    queryParams: ['page', 'size'],
    size: 25,

    organizationsCount: computed.alias('model.length')
  });
});