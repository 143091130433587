define('mvp-web/helpers/format-message-body', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatMessageBody = formatMessageBody;


  /**
   * Format chat message body
   * @param body
   * @returns {string}
   */

  function formatMessageBody(body) {
    return body ? body.replace(/\n/g, '<br />') : null;
  }

  exports.default = Ember.Helper.helper(formatMessageBody);
});