define('mvp-web/controllers/customers/index', ['exports', 'mvp-web/controllers/groups/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    detailsPath: 'customers.edit',
    transitionStateIsProspect: false,
    transitionStateIsCustomer: true,
    modelName: 'customer',
    includeCalled: true
  });
});