define('mvp-web/models/commercial-claim', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'moment', 'ember-cp-validations'], function (exports, _relationships, _attr, _model, _moment, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isEmpty = Ember.isEmpty;


  var Validations = (0, _emberCpValidations.buildValidations)({
    commercial: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Commercial'
    }),
    claimDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Date of Incident'
    })
  });

  exports.default = _model.default.extend(Validations, {
    claimDate: (0, _attr.default)('utc'),
    createdAt: (0, _attr.default)('utc'),
    commercial: (0, _relationships.belongsTo)('commercial'),
    isEvidenceNeeded: (0, _attr.default)('boolean', { defaultValue: false }),
    isDisputed: (0, _attr.default)('boolean', { defaultValue: false }),

    displayLine: computed('claimDate', 'commercial.address', 'commercial.address.displayLine', function () {
      var claimDate = this.get('claimDate');
      var yearAndMonth = claimDate ? '' + claimDate.format('MM/YYYY') : null;
      var businessName = this.get('commercial.businessName');
      return yearAndMonth && businessName ? yearAndMonth + ', ' + businessName : null;
    }),

    shouldDisplay: computed.notEmpty('displayLine'),

    organizationSetting: null, // transient

    commercialClaimFallOff: computed.reads('organizationSetting.otherClaimFallOff'),

    claimFallOffDate: computed('organizationSetting', function () {
      var claimDate = this.get('claimDate');
      var commercialClaimFallOff = this.get('commercialClaimFallOff');

      return claimDate ? claimDate.clone().add(commercialClaimFallOff, 'months') : null;
    }),

    claimFallOffLabel: computed('claimFallOffDate', function () {
      var claimFallOffDate = this.get('claimFallOffDate');

      if (isEmpty(claimFallOffDate)) return null;

      return 'Commercial (' + claimFallOffDate.format('MM/DD/YYYY') + ')';
    }),

    claimFallOffDateDiffFromNow: computed('claimFallOffDate', function () {
      var claimFallOffDate = this.get('claimFallOffDate');

      if (isEmpty(claimFallOffDate)) return null;

      return (0, _moment.default)().diff(this.get('claimFallOffDate'), 'days');
    })
  });
});