define('mvp-web/models/existing-policy', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    renewalDate: (0, _attr.default)(),
    policyTerm: (0, _attr.default)(),
    currentPremium: (0, _attr.default)('number', { defaultValue: 0 }),
    group: (0, _relationships.belongsTo)('group', { inverse: 'existingPolicies' }),
    policyTypeName: (0, _attr.default)()
  });
});