define('mvp-web/components/vehicles-browser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var computed = Ember.computed,
      service = Ember.inject.service,
      run = Ember.run;
  exports.default = Ember.Component.extend({

    classNames: ['row', 'vehicles-browser'],

    store: service(),
    segment: service(),
    showAddress: false,
    firstLetterOfName: null,
    filteredVehicles: null,
    maxResultsWithoutFiltering: 50,
    resultsFilteringEnabled: computed('vehicles', function () {
      return this.get('vehicles.length') > this.get('maxResultsWithoutFiltering');
    }),
    vehicleList: computed('filteredVehicles', 'vehicles', function () {
      if (!this.get('resultsFilteringEnabled')) {
        return this.get('vehicles');
      }

      return this.get('filteredVehicles');
    }),

    actions: {
      toggleVehiclesBrowser: function toggleVehiclesBrowser() {
        this.sendAction('toggleVehiclesBrowser');
      },
      addThenSaveVehicle: function addThenSaveVehicle(vehicleLookup) {
        var ownershipStatus = 'Owned';

        if (vehicleLookup.get('lienholder.full_name')) {
          ownershipStatus = 'Leased';
        }

        var newRecord = this.get('store').createRecord('vehicle', _extends({
          group: this.get('group'),
          ownershipStatus: ownershipStatus
        }, vehicleLookup.toVehicleAttributes()));

        if (this.get('group.id')) {
          newRecord.save();
        }

        run.scheduleOnce('afterRender', function () {
          Ember.$('.vehicle-info .vehicle-list').find('> tbody > tr:last td button.trigger-btn').trigger('click');
        });

        this.get('segment').trackEvent('Added vehicle to group.', {
          groupId: this.get('group').id,
          userId: this.get('session.currentUser.id')
        });
      },
      filterVehicles: function filterVehicles() {
        var filterCharacter = this.get('firstLetterOfName').toLowerCase();
        var attribute = 'owner.last_name';
        if (this.get('isTexas')) {
          attribute = 'owner.full_name';
        }

        var result = this.get('vehicles').filter(function (item) {
          return item.get(attribute).toLowerCase().indexOf(filterCharacter) === 0;
        });

        this.set('filteredVehicles', result);
      }
    }
  });
});