define('mvp-web/components/downloadable-reports/dnc-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    reportType: 'dncReport',

    baseParams: computed(function () {
      return { range: this.range };
    })
  });
});