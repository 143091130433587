define('mvp-web/components/radio-buttons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['radio-button'],

    actions: {
      selectItem: function selectItem(selectedItem) {
        if (this.get('mainAction') && this.get('mainAction').perform) {
          this.get('mainAction').perform();
        }

        this.set('item', selectedItem);
      }
    }
  });
});