define('mvp-web/routes/customers/pipelined', ['exports', 'mvp-web/routes/customers/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    model: function model(params) {
      var baseParams = this.prepareParams(params);

      return {
        previousRegularTask: this.get('getRegularModel.lastSuccessful'),
        currentRegularTask: this.get('getRegularModel').perform(baseParams)
      };
    }
  });
});