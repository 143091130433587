define('mvp-web/components/groups-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isPresent = Ember.isPresent,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service(),

    classNameBindings: ['isPriority:mb-0', 'isPriority:mvp-table-primary:table-light', 'isPriority:table-info:table-light', 'groups-table'],

    isPriority: false,

    isInitialLoad: computed.and('isLoading', 'notCached'),

    notCached: computed.not('hasCache'),

    shouldShowUserColumn: computed('selectedTeamMemberIds', 'session.currentUser', function () {
      return isPresent(this.get('selectedTeamMemberIds')) && this.get('session.currentUser.isAccountOwner');
    }),

    transitionState: computed('quoteTypeFocus', function () {
      if (this.transitionStateIsProspect) return 'prospect';
      if (this.transitionStateIsCustomer) return 'customer';
      return 'lead';
    }),

    groupComponentName: computed('quoteTypeFocus', function () {
      if (this.get('transitionStateIsCustomer')) {
        return 'groups-table/group-customer';
      }
      return 'groups-table/group-non-customer';
    }),

    noContactMessage: computed('isPriority', function () {
      return this.get('isPriority') ? 'No scheduled contacts for today' : 'No ranked contacts';
    }),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});