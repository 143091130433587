define('mvp-web/helpers/de-snake', ['exports', 'lodash/chain', 'lodash/object', 'lodash/string'], function (exports, _chain2, _object2, _string2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.deSnake = deSnake;


  /**
   * Takes a snake case string and de-snake cases it
   * Option to capitalize the phrase
   * @param params
   * @param options
   * @returns {string}
   */

  function deSnake(params, options) {
    options = _object2.default.defaults(options, {
      capitalize: true
    });

    var target = params[0] || '';

    return _chain2.default.chain(target).words().map(function (word) {
      return options.capitalize ? _string2.default.capitalize(word) : word;
    }).join(' ');
  }

  exports.default = Ember.Helper.helper(deSnake);
});