define('mvp-web/components/members-group-index-panel-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    classNameBindings: ['member.isQuoteOriginMember:font-weight-bold'],
    tagName: 'tr',

    specialStatus: computed('member.isPrimaryMember', 'member.isQuoteOriginMember', function () {
      var statuses = [];
      if (this.get('member.isPrimaryMember')) {
        statuses.push('Primary');
      }
      if (this.get('member.isQuoteOriginMember')) {
        statuses.push('Main Contact');
      }
      return statuses.join(', ');
    })
  });
});