define('mvp-web/models/driver', ['exports', 'ember-data/model', 'ember-data/attr', 'moment'], function (exports, _model, _attr, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    dateOfBirth: (0, _attr.default)(),
    firstName: (0, _attr.default)(),
    lastName: (0, _attr.default)(),
    licenseNumber: (0, _attr.default)(),
    licenseType: (0, _attr.default)(),
    addressLine: (0, _attr.default)(),

    fullName: computed('firstName,lastName', function () {
      return (this.get('firstName') || '') + ' ' + (this.get('lastName') || '');
    }),

    toMemberAttributes: function toMemberAttributes() {
      return {
        dob: (0, _moment.default)(this.get('dateOfBirth')),
        driverId: this.get('id'),
        driversLicense: this.get('licenseNumber'),
        driversLicenseType: this.get('licenseType'),
        firstName: this.get('firstName'),
        lastName: this.get('lastName')
      };
    }
  });
});