define('mvp-web/validations/template-script-email-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    title: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 3 })],
    body: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 3 })]
  };
});