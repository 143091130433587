define('mvp-web/components/import-history-table-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var service = Ember.inject.service,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),
    isStreaming: false,
    websocket: service(),

    fileName: computed(function () {
      return _typeof(this.get('import.fileName')) === 'object' ? 'n/a' : this.get('import.fileName');
    }),
    hasFailedRows: function hasFailedRows() {
      return parseInt(this.get('import.stats.total_failed')) > 0;
    },
    hasParams: function hasParams() {
      return Object.keys(this.get('import.params')).length > 0;
    },
    hasTotalRows: function hasTotalRows() {
      return parseInt(this.get('import.stats.total_rows')) > 0;
    },
    hasUpdatedRows: function hasUpdatedRows() {
      return parseInt(this.get('import.stats.total_updated')) > 0;
    },

    importedDetails: computed(function () {
      var totalNew = this.get('import.stats.total_new') ? parseInt(this.get('import.stats.total_new')) : 'n/a';
      var totalUpdated = this.get('import.stats.total_updated') ? parseInt(this.get('import.stats.total_updated')) : 'n/a';

      return 'New: ' + totalNew + '\n            <br/>\n            Updated: ' + totalUpdated;
    }),

    isFailed: function isFailed() {
      var totalFailed = this.get('import.stats.total_failed');
      return this.fileName === 'n/a' || !this.hasParams() || this.hasParams() && totalFailed && totalFailed === this.get('import.stats.total_rows');
    },
    isPartiallyProcessed: function isPartiallyProcessed() {
      return this.hasParams() && this.hasTotalRows() && this.hasFailedRows();
    },

    isPending: computed('import.stats', function () {
      return this.fileName !== 'n/a' && this.hasParams() && this.get('import.stats.total_failed') === undefined && this.get('import.stats.total_rows') === undefined;
    }),
    isSuccessful: function isSuccessful() {
      return this.hasParams() && this.hasTotalRows() && !this.hasFailedRows();
    },


    subscription: computed(function () {
      var store = this.get('store');
      var opts = {
        channel: 'LeadsImportChannel',
        id: this.get('import.id')
      };

      return this.get('websocket.consumer').subscriptions.create(opts, {
        received: function received(res) {
          store.pushPayload(res);
        }
      });
    }),

    _removeSubscription: function _removeSubscription() {
      var subscription = this.get('subscription');
      this.get('websocket.consumer').subscriptions.remove(subscription);
    },


    processStatus: computed('import.stats', function () {
      if (this.isFailed()) {
        return { class: 'label label-danger', title: 'Failed' };
      }
      if (this.isSuccessful()) {
        return { class: 'label label-success', title: 'Processed' };
      }
      if (this.isPartiallyProcessed()) {
        return { class: 'label label-warning', title: 'Partially Processed' };
      }
      if (this.isPending) {
        return { class: 'label label-info', title: 'Pending' };
      }
      return { class: 'fa-circle-o', title: 'Indeterminate' }; // catch all
    }),
    totalFailed: computed('import.stats.total_failed', function () {
      return this.get('import.stats.total_failed') ? this.get('import.stats.total_failed') : 'n/a';
    }),
    totalImported: computed('import.stats.total_imported', function () {
      return this.get('import.stats.total_imported') ? this.get('import.stats.total_imported') : 'n/a';
    }),

    actions: {
      // TODO: Refactor modals – https://trello.com/c/K5ETiWJz
      // Very very bad & un-Ember... But time crunch!
      showErrorsModal: function showErrorsModal() {
        var exceptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var errorsModal = Ember.$('#import-errors-modal');
        var details = '';

        exceptions.forEach(function (log) {
          for (var key in log) {
            var filteredMsg = log[key].split('\n')[0];
            details += '<div><span class="label label-info">Row #' + key + '</span> ' + filteredMsg + '</div><br/>';
          }
        });

        errorsModal.modal('show');
        errorsModal.find('.modal-data').html(details);
      }
    },
    didInsertElement: function didInsertElement() {
      if (!this.get('isPending')) return;

      this.get('subscription').perform('check_pending');
      this.set('isStreaming', true);
    },
    willClearRender: function willClearRender() {
      if (!this.get('isStreaming')) return;

      var subscription = this.get('subscription');
      this.get('websocket.consumer').subscriptions.remove(subscription);
    }
  });
});