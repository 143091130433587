define('mvp-web/validations/at-workflow-task-actions-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    atActionId: [(0, _validators.validatePresence)(true)],
    atActionTimeValue: [(0, _validators.validatePresence)(true)],
    'additionalOptions.title': [(0, _validators.validatePresence)({
      presence: true,
      message: 'Title can\'t be blank'
    })],
    'additionalOptions.description': [(0, _validators.validatePresence)({
      presence: true,
      message: 'Description can\'t be blank'
    })],
    'additionalOptions.assignee_id': [(0, _validators.validatePresence)({
      presence: true,
      message: 'Assignee can\'t be blank'
    })],
    'additionalOptions.due_date': [(0, _validators.validatePresence)({
      presence: true,
      message: 'Due date can\'t be blank'
    })]
  };
});