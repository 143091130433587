define('mvp-web/models/payment-methods/stripe', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    organization: (0, _relationships.belongsTo)('organization'),
    sourceToken: (0, _attr.default)('string'),
    cardDetail: (0, _attr.default)()
  });
});