define('mvp-web/models/ivr-tree', ['exports', 'ember-data/attr', 'ember-data/model', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _attr, _model, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    label: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Phone Number Name'
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 255
    })]
  });

  exports.default = _model.default.extend(Validations, {
    label: (0, _attr.default)(),
    ivrAction: (0, _relationships.belongsTo)('phoneNumberActions/ivrAction'),
    rootNode: (0, _attr.default)(),

    mainRootNode: computed('rootNode', function () {
      var rootNodeId = this.get('rootNode.data.id');
      return this.get('store').peekRecord('ivr-node', rootNodeId);
    }),

    rootNodeChildren: computed.alias('rootNode.data.attributes.children'),
    nodeIds: computed.mapBy('rootNodeChildren', 'data.id'),
    nodes: computed.map('nodeIds', function (id) {
      return this.get('store').peekRecord('ivr-node', id);
    })
  });
});