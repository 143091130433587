define("mvp-web/form-objects/customer-new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    toAddressAttrs: function toAddressAttrs() {
      return {
        usState: this.usState,
        street1: this.street1,
        city: this.city,
        postalCode: this.postalCode,
        latitude: this.latitude,
        longitude: this.longitude,
        isVerified: this.isVerified
      };
    },
    toMemberAttrs: function toMemberAttrs() {
      return {
        firstName: this.memberFirstName,
        lastName: this.memberLastName,
        mobileNumber: this.memberMobileNumber,
        phoneNumber: this.memberHomeNumber
      };
    },


    existingPolicies: []
  };
});