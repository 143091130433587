define('mvp-web/components/quote-forms/specialty/first-party-quote', ['exports', 'mvp-web/mixins/power-select-highlighted', 'mvp-web/mixins/quote-shareable', 'mvp-web/utils/magic-ids'], function (exports, _powerSelectHighlighted, _quoteShareable, _magicIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_powerSelectHighlighted.default, _quoteShareable.default, {
    store: service(),
    magicIds: _magicIds.default,
    isFirstParty: true,

    isSold: computed('quote.markedAsSold', function () {
      return this.get('quote.markedAsSold');
    }),

    policyTypes: computed.alias('staticData.staticData.specialtyQuotePolicyTypes'),

    paymentSelections: computed.filterBy('paymentOptions', 'appliesForSpecialty'),

    paymentTerms: computed(function () {
      return this.get('store').findAll('payment-term');
    }),

    paymentOptions: computed(function () {
      return this.get('store').findAll('payment-option');
    }),

    actions: {
      addPaymentOption: function addPaymentOption(quote) {
        var paymentOption = this.get('store').createRecord('quotePaymentOption');
        quote.get('quotePaymentOptions').pushObject(paymentOption);
      },
      selectPolicyType: function selectPolicyType(quote, selectedOption) {
        quote.setPolicyType(selectedOption);
      },
      attachmentUploaded: function attachmentUploaded(uploadedFile) {
        this.get('quote').setAttachment(uploadedFile);
      },
      removeAttachment: function removeAttachment(e) {
        this.get('quote').removeAttachment();
      }
    }
  });
});