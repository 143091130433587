define('mvp-web/components/mvp-exclusive-dashboard/home-leads-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    session: service(),

    columnCount: computed('shouldShowDistributedAgency', function () {
      return this.get('shouldShowDistributedAgency') ? 16 : 15;
    }),

    shouldShowDistributedAgency: computed('session.currentUserOrganization.hasChildOrganizations', function () {
      return this.get('session.currentUserOrganization.hasChildOrganizations');
    }),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      },
      setRefundRequestLeadId: function setRefundRequestLeadId(exclusiveLeadId) {
        this.sendAction('setRefundRequestLeadId', exclusiveLeadId);
      }
    }
  });
});