define('mvp-web/components/quote-forms/attached-files', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['form-group'],

    actions: {
      attachmentUploaded: function attachmentUploaded(uploadedFile) {
        this.get('quote').setAttachment(uploadedFile);
      },
      removeAttachment: function removeAttachment() {
        this.get('quote').removeAttachment();
      }
    }
  });
});