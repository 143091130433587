define('mvp-web/components/quote-forms/commercial/toggle-third-party-quote', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    actions: {
      toggleThirdPartyQuote: function toggleThirdPartyQuote() {
        var firstPartyQuote = this.get('firstPartyQuote');

        firstPartyQuote.toggleProperty('hasThirdPartyQuote');

        if (firstPartyQuote.get('hasThirdPartyQuote')) {
          this._addThirdPartyQuote(firstPartyQuote);
        } else {
          firstPartyQuote.get('thirdPartyQuote').destroyRecord();
        }
      }
    },

    _addThirdPartyQuote: function _addThirdPartyQuote(firstPartyQuote) {
      var store = this.get('store');
      var payment = store.createRecord('quotePaymentOption', { isDefault: true });

      var attributes = {
        group: firstPartyQuote.get('group'),
        isFirstParty: false,
        quoteIndex: firstPartyQuote.get('quoteIndex'),
        quotePaymentOptions: [payment],
        transitionState: firstPartyQuote.get('transitionState')
      };

      store.createRecord('commercialQuote', attributes);
    }
  });
});