define('mvp-web/models/quote-premium-estimation', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    premium: (0, _attr.default)('number'),
    createdAt: (0, _attr.default)('utc'),
    updatedAt: (0, _attr.default)('utc')
  });
});