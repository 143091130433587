define('mvp-web/components/dashboard/call-activity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['_call-activity'],
    store: service(),

    count: computed('count', function () {
      // 7 is the count ID for call activity stats
      return this.get('store').findRecord('count', 7);
    })
  });
});