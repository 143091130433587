define('mvp-web/adapters/phone-number', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    availableNumbers: function availableNumbers(_ref) {
      var areaCode = _ref.areaCode,
          search = _ref.search;

      var url = this.buildURL('phone-number') + '/available';
      return this.ajax(url, 'GET', {
        data: {
          area_code: areaCode,
          search: search
        }
      });
    },
    deletePhoneNumber: function deletePhoneNumber(_ref2) {
      var id = _ref2.id;

      var url = this.buildURL('phone-number') + '/' + id + '/archive';
      return this.ajax(url, 'PATCH');
    },
    unarchivePhoneNumber: function unarchivePhoneNumber(_ref3) {
      var id = _ref3.id;

      var url = this.buildURL('phone-number') + '/' + id + '/unarchive';
      return this.ajax(url, 'PATCH');
    },
    verifyCallerId: function verifyCallerId(_ref4) {
      var id = _ref4.id;

      var url = this.buildURL('phone-number') + '/' + id + '/verify_caller_id';
      return this.ajax(url, 'POST');
    }
  });
});