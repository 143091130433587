define('mvp-web/models/deleted-household', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    claimDate: (0, _attr.default)('utc'),
    createdAt: (0, _attr.default)('utc'),
    deletedAt: (0, _attr.default)('utc'),
    addressLine: (0, _attr.default)('string'),
    primaryMemberName: (0, _attr.default)('string'),
    phoneNumber: (0, _attr.default)('string'),
    mobileNumber: (0, _attr.default)('string'),
    transitionState: (0, _attr.default)('string')
  });
});