define('mvp-web/components/groups-tabs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    session: service(),

    canViewMainMenuItems: computed.and('session.isAuthenticated', 'session.currentUserOrganization.active', 'session.currentUser.active'),

    isLeadsType: computed('groupType', function () {
      return this.get('groupType') === 'lead';
    }),

    isProspectsType: computed('groupType', function () {
      return this.get('groupType') === 'prospect';
    }),

    isCustomerType: computed('groupType', function () {
      return this.get('groupType') === 'customer';
    })
  });
});