define('mvp-web/components/mvp-exclusive-dashboard/exclusive-group-index-tables', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    session: service(),
    store: service(),
    websocket: service(),

    classNames: ['group-index-tables'],
    blocked: [],

    routing: service('-routing'),

    isAuto: computed('exclusiveLeadFocus', function () {
      var focus = this.get('exclusiveLeadFocus');
      if (focus === 'auto' || focus === undefined || focus === null) {
        return true;
      }

      return false;
    }),

    isLife: computed('exclusiveLeadFocus', function () {
      var focus = this.get('exclusiveLeadFocus');
      if (focus === 'life') return true;

      return false;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },


    showPagination: computed.or('regularModel.meta.pagination.prev.number', 'regularModel.meta.pagination.next.number'),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});