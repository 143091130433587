define('mvp-web/routes/organizations/account', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    session: service(),
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    },
    model: function model(params) {
      return this.store.findRecord('organization', params.id);
    },
    afterModel: function afterModel(model) {
      model.setInsuranceCarrierIds();
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        subscriptionPlans: this.store.query('subscriptionPlan', {
          upgrades: true
        }),
        billingAddress: model.get('billingAddress'),
        subscription: model.get('subscription')
      });
    }
  });
});