define('mvp-web/controllers/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Controller = Ember.Controller;
  exports.default = Controller.extend({
    session: service(),

    currentUser: computed.alias('session.currentUser'),

    currentUserId: computed.alias('currentUser.id')
  });
});