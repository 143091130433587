define('mvp-web/helpers/number-to-currency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberToCurrency = numberToCurrency;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function numberToCurrency(params) {
    var _params = _slicedToArray(params, 3),
        num = _params[0],
        _params$ = _params[1],
        currency = _params$ === undefined ? 'USD' : _params$,
        _params$2 = _params[2],
        fractionDigits = _params$2 === undefined ? 0 : _params$2;

    var options = { minimumFractionDigits: fractionDigits };

    if (currency) {
      options = _extends({}, options, { currency: currency, style: 'currency' });
    }

    return num ? Number(num).toLocaleString('en-US', options) : num;
  }

  exports.default = Ember.Helper.helper(numberToCurrency);
});