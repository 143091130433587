define('mvp-web/components/header/missed-appointments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    anyMissed: computed.gt('counter', 0),

    linkText: computed('groupType', function () {
      var groupType = this.get('groupType');
      return groupType + '.missed-appointments';
    })
  });
});