define('mvp-web/models/phone-number', ['exports', 'ember-data/attr', 'ember-data/model', 'moment', 'ember-data/relationships', 'ember-cp-validations', 'mvp-web/helpers/format-phone'], function (exports, _attr, _model, _moment, _relationships, _emberCpValidations, _formatPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    number: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Phone Number'
    }),
    label: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Phone Number Name'
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 255
    })]
  });

  exports.default = _model.default.extend(Validations, {
    number: (0, _attr.default)(),
    label: (0, _attr.default)(),
    archivedAt: (0, _attr.default)('utc'),
    organizationId: (0, _attr.default)(),
    primaryActionId: (0, _attr.default)(),
    afterHoursActionId: (0, _attr.default)(),
    holidayActionId: (0, _attr.default)(),
    availability: (0, _attr.default)('object'),
    callerId: (0, _attr.default)(),
    callerIdVerificationStatus: (0, _attr.default)(),
    hasSipPassword: (0, _attr.default)(),
    fallbackNumber: (0, _attr.default)(),
    recordInbound: (0, _attr.default)(),
    recordOutbound: (0, _attr.default)(),
    users: (0, _relationships.hasMany)('user', { inverse: 'outboundPhoneNumber' }),
    holdRecordings: (0, _relationships.hasMany)('holdRecording', { inverse: 'phoneNumber' }),

    organization: (0, _relationships.belongsTo)('organization'),
    primaryAction: (0, _relationships.belongsTo)('phoneNumberAction', { polymorphic: true, inverse: 'phoneNumbers' }),
    afterHoursAction: (0, _relationships.belongsTo)('phoneNumberAction', { polymorphic: true, inverse: 'phoneNumbers' }),
    holidayAction: (0, _relationships.belongsTo)('phoneNumberAction', { polymorphic: true, inverse: 'phoneNumbers' }),

    removalDays: 30,

    hasCallerId: computed('callerId', 'number', function () {
      return this.get('callerId') && this.get('rawCallerId') !== this.get('rawNumber');
    }),

    rawCallerId: computed('callerId', function () {
      return (0, _formatPhone.dasherizedToRaw)(this.get('callerId'));
    }),

    rawNumber: computed('number', function () {
      return (0, _formatPhone.dasherizedToRaw)(this.get('number'));
    }),

    callerIdStatus: computed('callerIdVerificationStatus', function () {
      var status = this.get('callerIdVerificationStatus') || 'Pending';

      return status.capitalize();
    }),

    callerIdNotVerified: computed('hasCallerId', 'callerIdStatus', function () {
      return this.get('hasCallerId') && this.get('callerIdStatus') !== 'Verified';
    }),

    formattedPhone: computed('number', function () {
      return (0, _formatPhone.formatPhoneDasherized)(this.get('number'));
    }),

    formattedCallerId: computed('callerId', function () {
      if (!this.get('callerId') || this.get('rawCallerId') === this.get('rawNumber')) return 'None';

      return (0, _formatPhone.formatPhoneDasherized)(this.get('callerId'));
    }),

    formattedFallbackNumber: computed('fallbackNumber', function () {
      if (!this.get('fallbackNumber')) return 'None';

      return (0, _formatPhone.formatPhoneDasherized)(this.get('fallbackNumber'));
    }),

    fullDescription: computed('number', 'label', function () {
      return this.get('formattedPhone') + ' (' + this.get('label') + ')';
    }),

    formattedOutboundDescription: computed('callerId', 'label', function () {
      var number = this.get('formattedCallerId') === 'None' ? this.get('formattedPhone') : this.get('formattedCallerId');
      return number + ' (' + this.get('label') + ')';
    }),

    isActive: computed.and('isNotArchived', 'isNotSetup'),

    isArchived: computed.notEmpty('archivedAt'),
    isNotArchived: computed.not('isArchived'),

    isSetup: computed.reads('primaryActionEmpty'),
    isNotSetup: computed.not('isSetup'),

    primaryActionEmpty: computed.empty('primaryAction.id'),
    afterHoursActionEmpty: computed.empty('afterHoursAction.id'),
    holidayActionEmpty: computed.empty('holidayAction.id'),

    primaryActionPresent: computed.notEmpty('primaryAction.id'),
    afterHoursActionPresent: computed.notEmpty('afterHoursAction.id'),
    holidayActionPresent: computed.notEmpty('holidayAction.id'),

    removalDate: computed('archivedAt', function () {
      if (this.get('isActive')) return null;

      var archivedAt = this.get('archivedAt');
      var removalDays = this.get('removalDays');

      return (0, _moment.default)(archivedAt).add(removalDays, 'days');
    }),

    removalDateFormatted: computed('removalDate', function () {
      return this.get('removalDate') && this.get('removalDate').format('MMM. D');
    }),

    status: computed('isActive', 'isArchived', 'isSetup', function () {
      if (this.get('isArchived')) {
        var archivedAtFormatted = this.get('removalDate').format('MMM. D');

        return 'Pending Deletion (' + archivedAtFormatted + ')';
      }

      if (this.get('isSetup')) return 'Setup';
      if (this.get('isActive')) return 'Active';
    }),

    inbound: computed('primaryAction.modelName', function () {
      if (this.get('primaryAction.isForwardAction')) return 'Forward';

      if (this.get('primaryAction.isVoicemailAction')) return 'Voicemail';

      if (this.get('primaryAction.isDialAction')) return 'Ring Team Members';

      if (this.get('primaryAction.isIvrAction')) return 'IVR Phone Tree';

      return 'None';
    })
  });
});