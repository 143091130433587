define('mvp-web/components/dashboard/performance-statistics/quote-activity/savings-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNameBindings: ['lessThanZero:text-danger', 'greaterThanZero:text-success'],
    tagName: 'span',
    showParenthesis: true,

    lessThanZero: computed.lt('valueSaved', 0),

    greaterThanZero: computed.gt('valueSaved', 0),

    valueSavedNotZero: computed('valueSaved', function () {
      return parseInt(this.get('valueSaved')) !== 0;
    }),

    shouldBeDisplayed: computed.and('valueSavedNotZero', 'premium')
  });
});