define('mvp-web/controllers/users/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Controller.extend({
    session: service('session'),
    isSingleUserSubscription: computed.alias('session.currentUserOrganization.subscriptionPlan.singleUser')
  });
});