define('mvp-web/models/stripe-invoice', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'moment'], function (exports, _relationships, _attr, _model, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    amountDue: (0, _attr.default)('number'),
    attemptCount: (0, _attr.default)('number'),
    invoiceCreatedAt: (0, _attr.default)('utc'),
    isPaid: (0, _attr.default)('boolean'),
    organization: (0, _relationships.belongsTo)('organization'),
    nextPaymentAttempt: (0, _attr.default)('utc'),
    periodEnd: (0, _attr.default)('utc'),
    periodStart: (0, _attr.default)('utc'),

    durationFormatted: computed('periodStart', 'periodEnd', function () {
      var periodStart = this.get('periodStart');
      var periodEnd = this.get('periodEnd');

      if (!periodStart || !periodEnd) {
        return null;
      }

      var periodStartFormatted = (0, _moment.default)(periodStart).format('LL');
      var periodEndFormatted = (0, _moment.default)(periodEnd).format('LL');
      return periodStartFormatted + ' to ' + periodEndFormatted;
    }),

    amountDueInDollars: computed('amountDue', function () {
      var amountDue = this.get('amountDue');

      if (!amountDue) {
        return null;
      }

      return '$' + (amountDue / 100).toFixed(2);
    })

  });
});