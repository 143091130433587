define('mvp-web/models/organization-invite', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    createdAt: (0, _attr.default)('utc'),
    updatedAt: (0, _attr.default)('utc'),
    status: (0, _attr.default)(),
    inviteeName: (0, _attr.default)(),
    inviterName: (0, _attr.default)(),
    inviter: (0, _relationships.belongsTo)('organization', { inverse: 'sentOrganizationInvites' }),
    invitee: (0, _relationships.belongsTo)('organization', { inverse: 'receivedOrganizationInvites' }),

    isRejected: computed.equal('status', 'rejected'),
    isAccepted: computed.equal('status', 'accepted'),
    isPending: computed.equal('status', 'pending'),

    processedStatus: computed('status', function () {
      if (this.get('isRejected')) {
        return { class: 'label label-danger', title: 'Rejected' };
      }
      if (this.get('isAccepted')) {
        return { class: 'label label-success', title: 'Accepted' };
      }
      if (this.get('isPending')) {
        return { class: 'label label-info', title: 'Pending' };
      }
      return { class: 'fa-circle-o', title: 'Indeterminate' }; // catch all
    })
  });
});