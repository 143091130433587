define('mvp-web/components/hour-with-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    timestamp: null,
    placement: 'right'
  });
});