define('mvp-web/components/dashboard/performance-statistics/quote-activity/group-row', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    groupPath: computed('group', function () {
      var transitionStatePlural = this.get('group.transitionStatePlural');
      return transitionStatePlural + '.edit';
    }),

    pipelinedUntilInTheFuture: computed('group.pipelinedUntil', function () {
      var pipelinedUntil = this.get('group.pipelinedUntil');
      return pipelinedUntil && (0, _moment.default)(pipelinedUntil).isAfter((0, _moment.default)().endOf('day'));
    })
  });
});