define('mvp-web/services/polling', ['exports', 'rollbar'], function (exports, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    labelToIntervalIds: {},
    labelToReferenceCount: {},

    pollingInterval: 10000,

    startPolling: function startPolling(label, fn) {
      _rollbar.default.info('polling.js - startPolling: ' + label);

      if (this.labelToIntervalIds[label]) {
        this.labelToReferenceCount[label]++;
        _rollbar.default.info('polling.js - increase reference count for ' + label + ': ' + this.labelToReferenceCount[label]);
        return;
      }

      fn();
      var id = setInterval(function () {
        fn();
      }, this.get('pollingInterval'));

      this.labelToIntervalIds[label] = id;
      this.labelToReferenceCount[label] = 1;
    },
    stopPolling: function stopPolling(label) {
      _rollbar.default.info('polling.js - stopPolling: polledLabels.' + label);
      this.labelToReferenceCount[label]--;
      _rollbar.default.info('polling.js - decrease reference count for ' + label + ': ' + this.labelToReferenceCount[label]);
      if (this.labelToReferenceCount[label] === 0) {
        _rollbar.default.info('polling.js - no more references for ' + label);
        clearInterval(this.labelToIntervalIds[label]);
        delete this.labelToIntervalIds[label];
      }
    }
  });
});