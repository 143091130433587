define('mvp-web/helpers/format-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatNumber = formatNumber;


  /**
   * format number to a locale sensitive format
   * @param params
   * @returns {Number|number}
   */

  function formatNumber(params) {
    var number = parseInt(params[0]) || 0;

    if (number > 0) {
      number = number.toLocaleString();
    }
    return number;
  }

  exports.default = Ember.Helper.helper(formatNumber);
});