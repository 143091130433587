define('mvp-web/components/template-script-list', ['exports', 'mvp-web/mixins/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_errors.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.set('taskFetchTemplates', this.get('fetchTemplates').perform());

      this.set('taskFetchTemplates.isRunning', true);
    }
  });
});