define('mvp-web/models/home', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'ember-cp-validations'], function (exports, _relationships, _attr, _model, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    address: (0, _emberCpValidations.validator)('belongs-to')
  });

  exports.default = _model.default.extend(Validations, {
    address: (0, _relationships.belongsTo)('address'),
    baths: (0, _attr.default)('simple-decimal'),
    buildYear: (0, _attr.default)(),
    fireplaces: (0, _attr.default)(),
    garages: (0, _attr.default)('simple-decimal'),
    group: (0, _relationships.belongsTo)('group', { polymorphic: true, inverse: 'homes' }),
    hasElectricHeating: (0, _attr.default)('boolean', { defaultValue: false }),
    hasGasHeating: (0, _attr.default)('boolean', { defaultValue: false }),
    hasPool: (0, _attr.default)('boolean', { defaultValue: false }),
    homeClaims: (0, _relationships.hasMany)('homeClaim'),
    homeQuotes: (0, _relationships.hasMany)('homeQuote'),
    notes: (0, _attr.default)(),
    roofYear: (0, _attr.default)(),
    roofType: (0, _attr.default)(),
    exteriorType: (0, _attr.default)(),
    dwellingType: (0, _attr.default)(),
    householdSize: (0, _attr.default)(),
    squareFeet: (0, _attr.default)(),
    stories: (0, _attr.default)('simple-decimal'),
    totalMarketValue: (0, _attr.default)('number'),
    purchaseYear: (0, _attr.default)(),
    purchaseMonth: (0, _attr.default)()
  });
});