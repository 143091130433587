define('mvp-web/helpers/titlecase', ['exports', 'mvp-web/utils/capitalize-first-letter-each-word'], function (exports, _capitalizeFirstLetterEachWord) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.titlecase = titlecase;
  function titlecase(params) {
    if (typeof params[0] !== 'string') return;

    return (0, _capitalizeFirstLetterEachWord.default)(params[0]);
  }

  exports.default = Ember.Helper.helper(titlecase);
});