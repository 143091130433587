define('mvp-web/models/user', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model', 'ember-cp-validations', 'mvp-web/helpers/format-phone'], function (exports, _relationships, _attr, _model, _emberCpValidations, _formatPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'First name'
    })],
    lastName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Last name'
    })],
    phoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Phone number'
    }), (0, _emberCpValidations.validator)('format', {
      type: 'phone',
      allowBlank: false,
      message: 'Must be a valid phone number'
    })],
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Email'
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowBlank: false,
      message: 'Must be a valid email address'
    })],
    roles: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.roles.[]'],
      message: 'Select at least one role'
    })
  });

  exports.default = _model.default.extend(Validations, {
    active: (0, _attr.default)('boolean'),
    createdAt: (0, _attr.default)('utc'),
    email: (0, _attr.default)(),
    emailHash: (0, _attr.default)(),
    firstName: (0, _attr.default)(),
    lastName: (0, _attr.default)(),
    notes: (0, _attr.default)(),
    invitedByUserId: (0, _attr.default)(),
    invitationSentAt: (0, _attr.default)('utc'),
    isAccountOwner: (0, _attr.default)('boolean', { defaultValue: false }),
    isAdmin: (0, _attr.default)('boolean', { defaultValue: false }),
    isAgent: (0, _attr.default)('boolean', { defaultValue: false }),
    isFarmers: (0, _attr.default)('boolean', { defaultValue: false }),
    isProducer: (0, _attr.default)('boolean', { defaultValue: false }),
    isPending: (0, _attr.default)('boolean', { defaultValue: false }),
    isTelemarketer: (0, _attr.default)('boolean', { defaultValue: false }),
    roles: (0, _attr.default)('array'),
    organization: (0, _relationships.belongsTo)('organization'),
    phoneCalls: (0, _relationships.hasMany)('phoneCall', { inverse: 'user' }),
    orgStateName: (0, _attr.default)('string'),
    phoneNumber: (0, _attr.default)(),
    quoteburstAgentId: (0, _attr.default)(),
    farmersAgentId: (0, _attr.default)(),
    plraterId: (0, _attr.default)(),
    turboraterAccountId: (0, _attr.default)(),
    turboraterLocationId: (0, _attr.default)(),
    updatedAt: (0, _attr.default)('utc'),
    currentSignInAt: (0, _attr.default)('utc'),
    usState: (0, _attr.default)(),
    stallDays: (0, _attr.default)(),
    ezlynxAccountName: (0, _attr.default)(),
    voipContactPreference: (0, _attr.default)(),
    voipExtension: (0, _attr.default)(),
    voipStatus: (0, _attr.default)(),
    outboundPhoneNumber: (0, _relationships.belongsTo)('phoneNumber', { inverse: 'users' }),
    aaaAgentId: (0, _attr.default)(),
    aaaPassword: (0, _attr.default)(),
    smtpPassword: (0, _attr.default)(),
    smtpUsername: (0, _attr.default)(),
    smtpDomain: (0, _attr.default)(),
    smtpEmail: (0, _attr.default)(),
    smtpSettingsValidated: (0, _attr.default)('boolean'),
    hasAzureAuth: (0, _attr.default)(),
    azureAuthValid: (0, _attr.default)(),
    hasGoogleAuth: (0, _attr.default)(),
    googleAuthValid: (0, _attr.default)(),
    hasRingcentralAuth: (0, _attr.default)(),
    ringcentralAuthValid: (0, _attr.default)(),
    hasRingcentralAccountConnections: (0, _attr.default)('boolean', { defaultValue: false }),
    disableEmailSmsReplies: (0, _attr.default)('boolean', { defaultValue: false }),
    disableEmailReceivedSms: (0, _attr.default)('boolean', { defaultValue: false }),
    newMessageNotifications: (0, _attr.default)('boolean', { defaultValue: false }),
    newLeadNotifications: (0, _attr.default)('boolean', { defaultValue: false }),
    tasks: (0, _relationships.hasMany)('task', { inverse: 'assignee' }),
    disableSoldQuotesNotification: (0, _attr.default)('boolean', { defaultValue: false }),
    canopyConnectLink: (0, _attr.default)('string'),
    customEmailSignatureText: (0, _attr.default)('string'),
    testEmailSignatureText: (0, _attr.default)('string'),
    enableMemberUnsubscribedNotifications: (0, _attr.default)('boolean', { defaultValue: false }),
    memberUnsubscribedNotificationsDisabled: computed.not('enableMemberUnsubscribedNotifications'),
    inactive: computed.not('active'),
    enableRingcentralNextAction: (0, _attr.default)('boolean'),
    newTaskNotifications: (0, _attr.default)('boolean'),
    inboundCallNotifications: (0, _attr.default)('boolean'),
    leadOrganizationTransferNotifications: (0, _attr.default)('boolean'),
    disableAutomaticRingcentralCallLogging: (0, _attr.default)('boolean'),
    ringcentralSmsNumber: (0, _attr.default)('string'),
    textRequestSmsNumber: (0, _attr.default)('string'),

    showAzureAuthButton: computed(function () {
      var hasAzureAuth = this.get('hasAzureAuth');
      var azureAuthValid = this.get('azureAuthValid');
      return !hasAzureAuth || !azureAuthValid;
    }),

    showGoogleAuthButton: computed('hasGoogleAuth', 'googleAuthValid', function () {
      var hasGoogleAuth = this.get('hasGoogleAuth');
      var googleAuthValid = this.get('googleAuthValid');
      return !hasGoogleAuth || !googleAuthValid;
    }),

    showRingcentralAuthButton: computed('hasRingcentralAuth', 'ringcentralAuthValid', function () {
      var hasRingcentralAuth = this.get('hasRingcentralAuth');
      var ringcentralAuthValid = this.get('ringcentralAuthValid');
      return !hasRingcentralAuth || !ringcentralAuthValid;
    }),

    isRingcentralDisconnectButtonEnabled: computed('hasRingcentralAuth', 'ringcentralAuthValid', function () {
      var hasRingcentralAuth = this.get('hasRingcentralAuth');
      var ringcentralAuthValid = this.get('ringcentralAuthValid');
      return hasRingcentralAuth && ringcentralAuthValid;
    }),

    showRingcentralDisconnectButton: computed('hasRingcentralAccountConnections', function () {
      var hasRingcentralAccountConnections = this.get('hasRingcentralAccountConnections');
      return hasRingcentralAccountConnections;
    }),

    formattedVoipStatus: computed('voipStatus', function () {
      var voipStatus = this.get('voipStatus');
      if (voipStatus === 'on_call') return 'On Call';
      if (voipStatus === 'available') return 'Available';
      if (voipStatus === 'away') return 'Busy';
      if (voipStatus === 'offline') return 'Offline';
    }),

    hasVoipExtension: computed.notEmpty('voipExtension'),

    formattedVoipContactPreference: computed('voipContactPreference', function () {
      var contactPref = this.get('voipContactPreference');
      if (contactPref === 'sip') return 'External Phone';
      if (contactPref === 'browser') return 'MVP Browser Client';
    }),

    sipEnabled: computed.equal('voipContactPreference', 'sip'),

    invitedByUser: computed('invitedByUserId', function () {
      if (this.get('invitedByUserId')) {
        return this.get('store').findRecord('user', this.get('invitedByUserId'));
      }
    }),

    fullName: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    initials: computed('firstName', 'lastName', function () {
      return '' + this.get('firstName').charAt(0) + this.get('lastName').charAt(0);
    }),

    truncatedName: computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName').charAt(0);
      var lastName = this.get('lastName');
      if (lastName.length > 8) {
        lastName = lastName.substring(0, 8) + '\u2026';
      }
      return firstName + ' ' + lastName;
    }),

    fullNameWithFormattedPhone: computed('fullName', 'phoneNumber', function () {
      var fullName = this.get('fullName');
      var phoneNumber = this.get('phoneNumber');

      if (!phoneNumber) return fullName;

      var formattedPhone = (0, _formatPhone.formatPhoneDasherized)(phoneNumber);

      return fullName + ' (' + formattedPhone + ')';
    })
  });
});