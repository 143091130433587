define('mvp-web/components/import-selection', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;

  var isQuoteTypeSelected = false;

  exports.default = Component.extend(_powerSelectHighlighted.default, {
    csvKeyName: undefined,
    csvKeyNames: undefined,
    key: computed('modelName', 'mvpKeyName', 'index', function () {
      var key = '';
      var modelName = this.get('modelName');
      var mvpKeyName = this.get('mvpKeyName');
      var index = this.get('index');

      if (modelName === 'addresses' || modelName === 'homes') {
        key = modelName + '_' + mvpKeyName;
      } else if (index === undefined || index === null) {
        key = modelName + '_' + mvpKeyName;
      } else {
        key = modelName + '_' + index + '_' + mvpKeyName;
      }
      return key;
    }),
    keyMap: {},
    triggerDisabled: computed('csvKeyName', function () {
      var key = '';
      var modelName = this.get('modelName');
      var mvpKeyName = this.get('mvpKeyName');
      var index = this.get('index');

      if (this.get('isKeyMappingReset')) this.set('keyMap', {});

      if (modelName === 'addresses' || modelName === 'homes') {
        key = modelName + '_' + mvpKeyName;
      } else if (index === undefined || index === null) {
        key = modelName + '_' + mvpKeyName;
      } else {
        key = modelName + '_' + index + '_' + mvpKeyName;
      }

      // CUSTOM: If quote type is selected, Reset all underneath properties first
      if (isQuoteTypeSelected) {
        for (var i = 0; i < Object.keys(this.get('keyMap')).length; i++) {
          var keyMap = Object.keys(this.get('keyMap'))[i];

          if (keyMap !== 'quotes_' + index + '_quote_type' && keyMap.indexOf('quotes_' + index) > -1) {
            delete this.get('keyMap')[keyMap];
          }
        }

        Ember.$('.quote-import-row-' + index + ' .ember-power-select-trigger').removeClass('import-disabled');

        isQuoteTypeSelected = false;
        return '';
      }

      if (this.get('keyMap')[key]) {
        isQuoteTypeSelected = true;
        return 'import-disabled';
      }

      return '';
    }),

    triggerClasses: computed('triggerDisabled', function () {
      return this.get('triggerDisabled') + ' form-control input-sm';
    }),

    actions: {
      // Keep this function as a filler event handler to prevent the disable field bug selection
      selectHighlighted: function selectHighlighted(selection, event) {},
      didSelectCsvKey: function didSelectCsvKey(option) {
        var key = this.get('key');

        if (!option) this.sendAction('setColumnKeyPairs', this.get('keyMap')[key], option);
        this.get('keyMap')[key] = option;
        this.set('csvKeyName', option);
        this.sendAction('setColumnKeyPairs', key, option);
      }
    }
  });
});