define('mvp-web/components/group-forms/mailing-address', ['exports', 'mvp-web/mixins/power-select-highlighted'], function (exports, _powerSelectHighlighted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend(_powerSelectHighlighted.default, {
    store: service(),
    drivers: null,

    hasFetchedDrivers: false,
    hasFetchedDriversError: false,

    hasFetchedVehicles: false,
    hasFetchedVehiclesError: false,

    isDriversBrowserOpened: false,
    isLookingForDrivers: false,
    isTexas: computed('group.address.usState', function () {
      return this.get('group.address.usState') === 'Texas';
    }),

    showDmvLookup: computed.alias('group.address.hasAllFieldsSet'),

    actions: {
      addThenSaveMember: function addThenSaveMember(memberAttributes) {
        this.sendAction('addThenSaveMember', memberAttributes);
      },
      didSelectAddress: function didSelectAddress(selected, address) {
        this.sendAction('didSelectAddress', selected, address);
        this.set('hideDiscoverDriversButton', false);
      },
      setDrivers: function setDrivers(drivers) {
        this.set('hasFetchedDrivers', true);
        this.set('drivers', drivers.sortBy('lastName'));
      },
      setVehicles: function setVehicles(vehicles) {
        this.set('hasFetchedVehicles', true);
        if (this.get('isTexas')) {
          this.set('vehicles', vehicles.sortBy('owner.first_name'));
        } else {
          this.set('vehicles', vehicles.sortBy('owner.last_name'));
        }
      },
      onVehiclesFetchError: function onVehiclesFetchError() {
        this.set('isDriversBrowserOpened', false);

        this.set('hasFetchedVehicles', false);
        this.set('hasFetchedVehiclesError', true);
        this.set('isVehiclesBrowserOpened', true);
      },
      onDriversFetchError: function onDriversFetchError() {
        this.set('isVehiclesBrowserOpened', false);

        this.set('hasFetchedDrivers', false);
        this.set('hasFetchedDriversError', true);
        this.set('isDriversBrowserOpened', true);
      },
      setIsLookingForDrivers: function setIsLookingForDrivers(status) {
        this.set('isLookingForDrivers', status);
        this.set('isLookingForVehicles', false);
      },
      setIsLookingForVehicles: function setIsLookingForVehicles(status) {
        this.set('isLookingForVehicles', status);
        this.set('isLookingForDrivers', false);
      },
      toggleDriversBrowser: function toggleDriversBrowser() {
        this.set('drivers', null);
        this.set('hasFetchedDrivers', false);
        this.set('hasFetchedDriversError', false);
        this.set('isVehiclesBrowserOpened', false);
        this.toggleProperty('isDriversBrowserOpened');
      },
      toggleVehiclesBrowser: function toggleVehiclesBrowser() {
        this.set('vehicles', null);
        this.set('hasFetchedVehicles', false);
        this.set('hasFetchedVehiclesError', false);
        this.set('isDriversBrowserOpened', false);
        this.toggleProperty('isVehiclesBrowserOpened');
      },
      updateMemberByFullName: function updateMemberByFullName(memberAttributes) {
        this.sendAction('updateMemberByFullName', memberAttributes);
      }
    }
  });
});