define('mvp-web/components/verification-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;
  exports.default = Component.extend({
    pendo: service(),

    classNames: ['label'],
    tagName: 'span',
    classNameBindings: ['isAddressNotVerified:label-warning', 'addressIsVerified:label-success'],
    trackAddressVerificationInPendo: observer('addressIsVerified', function () {
      var verificationType = this.get('addressIsVerified') ? 'Google Verified' : 'User Verified';
      var trackEventName = verificationType + ' - ' + this.get('location');
      this.get('pendo').trackEvents(trackEventName);
    }),
    isAddressNotVerified: computed.not('addressIsVerified')

  });
});