define('mvp-web/errors/payment-required-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PaymentRequiredError = function PaymentRequiredError(errors) {
    var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Please renew the Subscription Plan';

    Ember.Error.call(this, message);

    this.errors = errors || [{
      title: 'Organization has not active subscription',
      detail: message
    }];
  };

  PaymentRequiredError.prototype = Object.create(Ember.Error.prototype);

  exports.default = PaymentRequiredError;
});