define('mvp-web/models/subscription-plan', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    organization: (0, _relationships.belongsTo)('organization'),
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    priceCents: (0, _attr.default)('number'),
    isCancellable: (0, _attr.default)('boolean'),
    monthlyPlan: (0, _attr.default)('boolean'),
    singleUser: (0, _attr.default)('boolean'),
    active: (0, _attr.default)('boolean'),
    couponApplied: (0, _attr.default)('boolean'),

    priceWithDuration: Ember.computed('priceCents', 'monthlyPlan', function () {
      var duration = this.get('monthlyPlan') ? 'per month' : 'per year';
      return '$' + (this.get('priceCents') / 100).toFixed(2) + ' ' + duration;
    }),

    price: Ember.computed('priceCents', function () {
      return '$' + (this.get('priceCents') / 100).toFixed(2);
    })
  });
});