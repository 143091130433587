define('mvp-web/services/store', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Store.extend({
    cancelRequest: function cancelRequest(adapterType, requestURL) {
      var requestType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';

      this._super.apply(this, arguments);

      var adapter = this.adapterFor(adapterType);

      if (typeof adapter.cancelRequest === 'function') {
        adapter.cancelRequest(requestURL, requestType);
      }
    }
  });
});