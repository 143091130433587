define('mvp-web/adapters/chat-room', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    jqXHRs: [],

    ajaxOptions: function ajaxOptions(url, type, hash) {
      var _this = this;

      var ajaxOptions = this._super(url, type, hash);

      var defaultBeforeSend = function defaultBeforeSend() {};

      if (typeof ajaxOptions.beforeSend === 'function') {
        defaultBeforeSend = ajaxOptions.beforeSend;
      }

      ajaxOptions.beforeSend = function (jqXHR, settings) {
        defaultBeforeSend(jqXHR, settings);

        _this.get('jqXHRs').push(jqXHR);

        var lastInsertIndex = _this.get('jqXHRs').length - 1;

        jqXHR.always(function () {
          _this.get('jqXHRs').splice(lastInsertIndex, 1);
        });
      };

      return ajaxOptions;
    },
    cancelRequest: function cancelRequest() {
      for (var i = 0; i < this.get('jqXHRs').length; i++) {
        this.jqXHRs[i].abort();
      }
    },
    bulkUpdateChatRooms: function bulkUpdateChatRooms(chatRoomIds, archive) {
      return this.ajax('/api/v1/chat_rooms/bulk_update', 'PUT', { data: { chat_room_ids: chatRoomIds, archive: archive } });
    }
  });
});