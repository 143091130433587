define('mvp-web/components/plan-upgrade-confirmation-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      openModal: function openModal() {
        Ember.run(function () {
          return Ember.$('#plan-upgrade-dialog').modal('show');
        });
      },
      submitConfirm: function submitConfirm() {
        this.send('closeModal');
        this.get('onConfirm')();
      },


      closeModal: function closeModal() {
        return Ember.run(function () {
          return Ember.$('#plan-upgrade-dialog').modal('hide');
        });
      }
    }
  });
});