define('mvp-web/services/websocket', ['exports', 'mvp-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Service = Ember.Service;
  exports.default = Service.extend({
    cable: service(),

    consumer: computed(function () {
      return this.get('cable').createConsumer(_environment.default.websocketUrl);
    })
  });
});