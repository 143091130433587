define('mvp-web/components/logout-warning', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      run = Ember.run,
      service = Ember.inject.service;
  exports.default = Component.extend({
    invalidateSession: service(),

    actions: {
      openModal: function openModal(event) {
        run(function () {
          return Ember.$('#logout-warning-modal').modal('show');
        });
      },
      closeModal: function closeModal() {
        this.get('invalidateSession').invalidate();

        run(function () {
          return Ember.$('#logout-warning-modal').modal('hide');
        });
      }
    }
  });
});