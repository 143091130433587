define('mvp-web/components/combined-savings-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['combined-savings'],
    classNameBindings: ['saved:text-primary', 'lost:text-danger'],

    saved: computed.gt('group.amountSaved', 0),
    lost: computed.not('saved')
  });
});