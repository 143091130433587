define('mvp-web/components/quote-forms/life/life-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    isAddButtonDisabled: computed.not('selectedMember'),

    quoteHeaderText: computed('isFirstParty', 'group.isCustomer', function () {
      var isFirstParty = this.get('isFirstParty');
      var isCustomer = this.get('group.isCustomer');

      if (isFirstParty) {
        return isCustomer ? 'Your Quotes & Policies' : 'Your Quote';
      }

      return isCustomer ? 'Prior Competitor' : 'Current Carrier';
    }),

    addLifeQuoteButtonComponent: computed('isFirstParty', function () {
      var isFirstParty = this.get('isFirstParty');

      return isFirstParty ? 'quote-forms/life/add-first-party-quote' : 'quote-forms/life/add-third-party-quote';
    }),

    actions: {
      selectMember: function selectMember(member) {
        this.set('selectedMember', member);
      },
      addMember: function addMember() {
        this.attrs.addMember();
      }
    }
  });
});