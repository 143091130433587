define('mvp-web/models/lead-open-api-import', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    groupId: (0, _attr.default)(),
    groupStatus: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    street: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zip: (0, _attr.default)(),
    phone: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    vehicleYear: (0, _attr.default)(),
    vehicleMake: (0, _attr.default)('string'),
    vehicleModel: (0, _attr.default)('string'),
    isOwner: (0, _attr.default)('boolean'),
    boberdooLeadId: (0, _attr.default)('string'),
    refundRequestedAt: (0, _attr.default)('utc'),
    refundRequestStatus: (0, _attr.default)('string'),
    refundRequestResponse: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('utc'),
    groupOrganizationId: (0, _attr.default)('string'),
    lifePolicyType: (0, _attr.default)('string'),
    leadType: (0, _attr.default)('string'),

    organization: (0, _relationships.belongsTo)('organization'),
    group: (0, _relationships.belongsTo)('group'),

    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    detailsPath: Ember.computed('groupStatus', function () {
      return this.get('groupStatus') + 's.edit';
    }),

    ownershipType: Ember.computed('isOwner', function () {
      return this.get('isOwner') ? 'Owner' : 'Renter';
    }),

    assignedToOtherOrg: Ember.computed('groupOrganizationId', function () {
      return this.get('groupOrganizationId') !== this.get('organization.id');
    })
  });
});