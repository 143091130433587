define('mvp-web/components/groups-table-life-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    tagName: 'tr',

    lifePolicyType: computed('quote.policyTypeId', function () {
      var policyTypeId = this.get('quote.policyTypeId');
      if (policyTypeId) {
        return this.get('store').findRecord('life-policy-type', policyTypeId);
      }
      return null;
    }),

    monthly: computed('quote.premium', function () {
      return (this.get('quote.premium') / 12).toFixed(2);
    })
  });
});