define('mvp-web/models/contact', ['exports', 'ember-data/model', 'ember-cp-validations'], function (exports, _model, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    scheduledAt: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Scheduled At'
    }),
    state: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Call Status'
    }),
    member: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '{description} is required',
      description: 'Household Member'
    })]
  });

  exports.default = _model.default.extend(Validations);
});