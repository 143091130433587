define('mvp-web/components/groups-table/group-customer-row', ['exports', 'mvp-web/components/groups-table-row'], function (exports, _groupsTableRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _groupsTableRow.default.extend({
    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});