define('mvp-web/models/ivr-recording', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    label: (0, _attr.default)(),
    recordingUrl: (0, _attr.default)(),

    // Saving attributes
    recording: (0, _attr.default)(),
    filename: (0, _attr.default)(),

    organization: (0, _relationships.belongsTo)('organization')
  });
});