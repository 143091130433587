define('mvp-web/validations/assign-task-sequence', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    groupId: [(0, _validators.validatePresence)(true)],
    assignee: [(0, _validators.validatePresence)(true)],
    existingTaskSequence: [(0, _validators.validatePresence)(true)]
  };
});