define('mvp-web/components/score-span', ['exports', 'mvp-web/mixins/score-labelable'], function (exports, _scoreLabelable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend(_scoreLabelable.default, {
    createLabel: computed('@score', function () {
      var isSum = this.scoreName === 'sum';
      return 'label-' + this.tagLabel(this.score, isSum);
    })
  });
});