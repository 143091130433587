define('mvp-web/components/mvp-thread-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: 'nav-item parent-item icon-item dropdown',

    chat: service(),
    session: service()

  });
});