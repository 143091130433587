define('mvp-web/utils/magic-ids', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.create({
    otherCarrierId: 117,
    payInFullId: 1,
    monthlyEftId: 4,
    sixMonthPaymentTermId: 13,
    twelveMonthPaymentTermId: 14,
    unknownCarrierId: 116,
    nonPolicyType: 12,
    homeOwnerPolicyType: 3,
    homeRenterPolicyType: 5
  });
});