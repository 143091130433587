define('mvp-web/validators/phone-number-format', ['exports', 'ember-cp-validations/validators/base', 'mvp-web/helpers/format-phone'], function (exports, _base, _formatPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PhoneNumberFormat = _base.default.extend({
    validate: function validate(phoneNumber, options, model, attribute) {
      return (0, _formatPhone.validateRawUsPhone)(phoneNumber) || (0, _formatPhone.validatePhoneDasherized)(phoneNumber);
    }
  });

  PhoneNumberFormat.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = PhoneNumberFormat;
});