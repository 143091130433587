define('mvp-web/components/group-organization-transfer-dashboard/group-organization-transfer-index-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    session: service(),
    store: service(),
    websocket: service(),

    classNames: ['group-index-tables'],
    blocked: [],

    routing: service('-routing'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },


    showPagination: computed.or('regularModel.meta.pagination.prev.number', 'regularModel.meta.pagination.next.number'),

    actions: {
      refreshModel: function refreshModel() {
        this.sendAction('refreshModel');
      }
    }
  });
});