define('mvp-web/adapters/home-coverage', ['exports', 'mvp-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(type, id, snapshot) {
      var homeQuote = snapshot ? snapshot.belongsTo('homeQuote') : null;
      var homeQuoteId = homeQuote ? homeQuote.id : null;

      // The array order matters
      var pathObject = [{ resourceName: 'homeQuote', id: homeQuoteId }, { resourceName: 'homeCoverage', id: id }];

      return this.buildNestedUrl(pathObject);
    }
  });
});