define('mvp-web/controllers/prospects/new', ['exports', 'mvp-web/controllers/groups/new'], function (exports, _new) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _new.default.extend({
    detailsPath: 'prospects.new',
    transitionStateIsProspect: true,
    transitionStateIsCustomer: false
  });
});