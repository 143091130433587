define('mvp-web/components/global-sidebar', ['exports', 'mvp-web/mixins/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_errors.default, {
    classNames: ['sidebar'],

    sidebar: service(),

    shouldHaveTabIndex: computed('sidebar.opened', function () {
      var openedName = this.get('sidebar.opened');
      return openedName !== this.get('uid');
    })
  });
});