define('mvp-web/validators/user-roles', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserRoles = _base.default.extend({
    validate: function validate(roles, options, model, attribute) {
      if (roles.length < 1) {
        return false;
      }
      var allowedRoles = ['Agent', 'Producer', 'Telemarketer'];
      return roles.every(function (role) {
        return allowedRoles.includes(role);
      });
    }
  });

  UserRoles.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = UserRoles;
});