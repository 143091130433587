define('mvp-web/routes/prospects/index', ['exports', 'mvp-web/routes/groups/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    modelName: 'prospect',
    queryParams: {
      status: { refreshModel: true }
    }

  });
});