define('mvp-web/serializers/user', ['exports', 'mvp-web/serializers/application', 'mvp-web/mixins/embedded-relationships'], function (exports, _application, _embeddedRelationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRelationships.default, {
    serialize: function serialize() {
      var payload = this._super.apply(this, arguments);
      if (!payload.smtp_password) {
        delete payload.smtp_password;
      }
      return payload;
    },


    attrs: {
      createdAt: { serialize: false },
      currentSignInAt: { serialize: false },
      notes: { serialize: false },
      invitationSentAt: { serialize: false },
      isAccountOwner: { serialize: false },
      isAdmin: { serialize: false },
      isAgent: { serialize: false },
      isProducer: { serialize: false },
      isPending: { serialize: false },
      isTelemarketer: { serialize: false },
      updatedAt: { serialize: false },
      usState: { serialize: false },
      invitedByUserId: { serialize: false },
      organization: { serialize: false }
    }
  });
});