define('mvp-web/components/quote-forms/umbrella/third-party-quote', ['exports', 'mvp-web/mixins/power-select-highlighted', 'mvp-web/utils/magic-ids'], function (exports, _powerSelectHighlighted, _magicIds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend(_powerSelectHighlighted.default, {
    store: service(),
    magicIds: _magicIds.default,

    insuranceCarriers: computed(function () {
      return this.get('store').query('insurance-carrier', { for: 'umbrella' });
    })
  });
});