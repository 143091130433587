define('mvp-web/components/driver-finder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var service = Ember.inject.service,
      computed = Ember.computed,
      Component = Ember.Component;

  var driverProperties = ['firstName', 'lastName', 'dateOfBirth', 'licenseNumber'];
  var addressProperties = ['street', 'postalCode'];
  var allProperties = driverProperties.concat(addressProperties);

  exports.default = Component.extend({
    store: service(),

    isLookingForDrivers: false,

    formValid: computed.or.apply(computed, _toConsumableArray(allProperties)),
    submitButtonDisabled: computed.not('formValid'),

    dateOfBirth: computed('dateOfBirthFormatted', function () {
      return this.get('dateOfBirthFormatted') ? this.get('dateOfBirthFormatted').format('YYYY-MM-DD') : undefined;
    }),

    licenseNumber: computed('displayedLicenseNumber', function () {
      if (!this.get('displayedLicenseNumber')) {
        return undefined;
      }
      return this.get('displayedLicenseNumber');
    }),

    actions: {
      search: function search() {
        var _this = this;

        var conditions = {
          driver: this.getProperties(driverProperties),
          address: this.getProperties(addressProperties)
        };

        this.sendAction('setIsLookingForDrivers', true);

        this.get('store').query('driver', conditions).then(function (drivers) {
          _this.set('drivers', drivers);
        }).finally(function () {
          _this.set('hasFetchedDrivers', true);
          _this.sendAction('setIsLookingForDrivers', false);
        });
      },
      updateMemberByFullName: function updateMemberByFullName(memberAttributes) {
        this.sendAction('updateMemberByFullName', memberAttributes);
      },
      addThenSaveMember: function addThenSaveMember(memberAttributes) {
        this.sendAction('addThenSaveMember', memberAttributes);
      },
      toggleDriversBrowser: function toggleDriversBrowser() {
        this.sendAction('toggleDriversFinder');
      }
    }
  });
});