define('mvp-web/components/dashboard/performance-statistics/quote-activity/cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    defaultCss: 'text-center text-primary __dashboard_performance-statistics_quote-activity_cell',
    classNameBindings: ['defaultCss', 'isSoldCss'],

    isSoldCss: computed('isSold', function () {
      if (this.get('isSold')) return 'highlight-sold';
    }),

    isSold: computed.or('isSoldByAgent', 'totalPremiumSold'),
    isNotSold: computed.not('isSold'),
    isSoldByAgent: computed.gt('premiumSold', 0),
    isQuotedAndSoldByAgent: computed.and('isQuotedByAgent', 'isSoldByAgent'),

    isQuoted: computed.gt('totalPremiumQuoted', 0),
    isQuotedByAgent: computed.gt('premiumQuoted', 0),
    isQuotedByAgentAndSoldbyAnyone: computed.and('isQuotedByAgent', 'isSold'),

    showQuotedValue: computed.or('isQuotedByAgentAndSoldbyAnyone', 'isNotSold'),
    anySavings: computed.gt('savings', 0),

    soldOutsideTimerangeOrByOthers: computed('totalPremiumSold', 'savings', function () {
      var totalPremiumSold = this.get('totalPremiumSold');
      var saved = this.get('anySavings') ? '(' + this.get('savings') + ')' : '';

      return 'One or more policies sold: ' + totalPremiumSold + ' ' + saved + '.  Not quoted in time range.';
    }),

    quotedOutsideTimerangeOrByOthers: computed('totalPremiumQuoted', 'savings', function () {
      var totalPremiumQuoted = this.get('totalPremiumQuoted');
      var saved = this.get('anySavings') ? '(' + this.get('savings') + ')' : '';

      return 'Quoted outside chosen time range or by other agent: ' + totalPremiumQuoted + ' ' + saved;
    })
  });
});