define('mvp-web/components/mvp-datetime', ['exports', 'ember-bootstrap-datetimepicker/components/bs-datetimepicker', 'moment'], function (exports, _bsDatetimepicker, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer;
  exports.default = _bsDatetimepicker.default.extend({
    icons: {
      time: 'fa fa-fw fa-clock-o',
      date: 'fa fa-fw fa-calendar',
      up: 'fa fa-fw fa-chevron-up',
      down: 'fa fa-fw fa-chevron-down',
      previous: 'fa fa-fw fa-chevron-left',
      next: 'fa fa-fw fa-chevron-right',
      today: 'fa fa-fw fa-calendar-o',
      clear: 'fa fa-fw fa-trash-o',
      close: 'fa fa-fw fa-remove'
    },

    inputClassNames: 'mvp-input-lg',

    init: function init() {
      this._super.apply(this, arguments);

      this.setDisplayDate();
    },


    dateValueChanged: observer('date', function () {
      this.setDisplayDate();
    }),

    setDisplayDate: function setDisplayDate() {
      if (this.get('date') === undefined) {
        this.set('date', null);
        this.set('displayDate', null);
        return;
      }

      this.set('displayDate', (0, _moment.default)(this.get('date')).format(this.get('format')));
    }
  });
});