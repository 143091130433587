define('mvp-web/models/call-log-template', ['exports', 'ember-data/model', 'ember-data/attr', 'mvp-web/services/constants/call-back-options'], function (exports, _model, _attr, _callBackOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      getProperties = Ember.getProperties;
  exports.default = _model.default.extend({
    status: (0, _attr.default)('string', { defaultValue: 'unanswered' }),
    nextAction: (0, _attr.default)('string', { defaultValue: _callBackOptions.default.action2.label }),

    defaultAttrs: computed(function () {
      return this._allowedAttrs(this.toJSON());
    }),

    _allowedAttrs: function _allowedAttrs(attrs) {
      return getProperties(attrs, 'status', 'nextAction');
    }
  });
});