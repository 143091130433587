define('mvp-web/models/metrics/total-commission', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    byDate: (0, _attr.default)(),
    byLob: (0, _attr.default)(),
    byUser: (0, _attr.default)(),
    total: (0, _attr.default)()
  });
});