define('mvp-web/components/generic-modal', ['exports', 'mvp-web/templates/components/generic-modal'], function (exports, _genericModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _genericModal.default,

    isPrimaryButtonDisabled: false,

    actions: {
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      },
      modalPrimaryAction: function modalPrimaryAction(model) {
        // gets model from template
        this.sendAction('modalPrimaryAction', model);
      },
      modalSecondaryAction: function modalSecondaryAction(model) {
        this.sendAction('modalSecondaryAction', model);
      }
    }
  });
});