define('mvp-web/components/groups-tabs/customers', ['exports', 'mvp-web/components/groups-tabs/group-tab'], function (exports, _groupTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _groupTab.default.extend({
    totalCustomers: computed('count.totalCustomers', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.totalCustomers');
    }),

    crossSellVehicleCustomers: computed('count.crossSellVehicleCustomers', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.crossSellVehicleCustomers');
    }),

    crossSellHomeCustomers: computed('count.crossSellHomeCustomers', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.crossSellHomeCustomers');
    }),

    crossSellUmbrellaCustomers: computed('count.crossSellUmbrellaCustomers', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.crossSellUmbrellaCustomers');
    }),

    crossSellLifeCustomers: computed('count.crossSellLifeCustomers', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.crossSellLifeCustomers');
    }),

    crossSellSpecialtyCustomers: computed('count.crossSellSpecialtyCustomers', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.crossSellSpecialtyCustomers');
    }),

    crossSellCommercialCustomers: computed('count.crossSellCommercialCustomers', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.crossSellCommercialCustomers');
    }),

    missedCustomersAppointments: computed('count.missedCustomersAppointments', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.missedCustomersAppointments');
    }),

    pipelinedCustomers: computed('count.pipelinedCustomers', 'isLoading', function () {
      if (this.get('isLoading')) return null;
      return this.get('count.pipelinedCustomers');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // 5 is the count ID for customer stats
      this.get('fetchCount').perform(5);
    }
  });
});