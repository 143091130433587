define('mvp-web/controllers/referral-program', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Controller = Ember.Controller,
      service = Ember.inject.service;
  exports.default = Controller.extend({
    features: service(),
    featureFlag: service(),
    session: service(),

    authToken: computed.reads('session.currentUserOrganization.firstpromoterToken'),
    featureEnabled: computed.reads('features.referrals'),
    referralEnabled: computed.and('authToken', 'featureEnabled')
  });
});