define('mvp-web/adapters/application', ['exports', 'ember-data/adapters/rest', 'ember-simple-auth/mixins/data-adapter-mixin', 'mvp-web/config/environment', 'mvp-web/errors/payment-required-error'], function (exports, _rest, _dataAdapterMixin, _environment, _paymentRequiredError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var _Ember$String = Ember.String,
      underscore = _Ember$String.underscore,
      pluralize = _Ember$String.pluralize,
      service = Ember.inject.service;
  exports.default = _rest.default.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:application',
    namespace: _environment.default.apiEndpointVersion,

    notifications: service('notification-messages'),
    session: service(),

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },
    pathForType: function pathForType(type) {
      var underscored = underscore(type);
      return pluralize(underscored);
    },
    buildNestedUrl: function buildNestedUrl() {
      for (var _len = arguments.length, pathDataObjects = Array(_len), _key = 0; _key < _len; _key++) {
        pathDataObjects[_key] = arguments[_key];
      }

      var _ref = pathDataObjects || [],
          _ref2 = _slicedToArray(_ref, 1),
          pathData = _ref2[0];

      var resourcePath = '';

      pathData.forEach(function (pd) {
        var id = pd.id || '';
        resourcePath += '/' + pluralize(underscore(pd.resourceName)) + '/' + id;
      });

      return '' + this.get('namespace') + resourcePath;
    },
    handleResponse: function handleResponse(status) {
      if (status >= 500 && status < 600) {
        this.get('notifications').error('Whoops. A connectivity error occurred. No additional detail was provided.');
      }

      if (status === 402) {
        throw new _paymentRequiredError.default();
      }

      return this._super.apply(this, arguments);
    }
  });
});