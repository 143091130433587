define('mvp-web/serializers/phone-number', ['exports', 'lodash', 'mvp-web/serializers/application', 'mvp-web/mixins/embedded-relationships'], function (exports, _lodash, _application, _embeddedRelationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var underscore = Ember.String.underscore;
  exports.default = _application.default.extend(_embeddedRelationships.default, {
    // Backend Controller doesn't expect `_attributes` key
    // for primary_action, after_hours_action, and holiday_action
    transformKeyForRails: function transformKeyForRails(key) {
      var keyName = underscore(key);
      var endsWithId = typeof ''.endsWith === 'function' ? keyName.endsWith('_id') : (0, _lodash.default)(keyName, '_id');

      if (keyName && endsWithId) {
        return keyName.replace(/(_id)$/, '');
      }
      return '' + key;
    },


    attrs: {
      organizationId: { serialize: false },
      primaryAction: { serialize: true },
      afterHoursAction: { serialize: true },
      holidayAction: { serialize: true }
    }
  });
});