define('mvp-web/validations/task-template-validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    title: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Template name can\'t be blank'
    })],
    description: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Template details can\'t be blank'
    })]
  };
});