define('mvp-web/routes/leads/hot', ['exports', 'mvp-web/routes/leads/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    priorityModelParams: function priorityModelParams(baseParams) {
      return {
        scheduledToday: true,
        page: 1,
        size: 25,
        hot: true,
        neutral: false,
        phone_filter: 'present',
        best_time: 0,
        quote_type_focus: baseParams.quote_type_focus
      };
    }
  });
});