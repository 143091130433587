define('mvp-web/models/stats-data-point', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    fullName: (0, _attr.default)(),
    newQlob: (0, _attr.default)('number', { defaultValue: 0 }),
    premiumQuoted: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    totalPremiumQuoted: (0, _attr.default)('number', { defaultValue: 0 }),
    premiumSold: (0, _attr.default)('number-decimal', { defaultValue: 0 }),

    // home
    homeQuoted: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    totalPremiumQuotedForHome: (0, _attr.default)('number', { defaultValue: 0 }),
    totalPremiumSoldForHome: (0, _attr.default)('number', { defaultValue: 0 }),
    homeSold: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    homeSavings: (0, _attr.default)('number', { defaultValue: 0 }),
    homeQuotesCount: (0, _attr.default)('number', { defaultValue: 0 }),
    missingHomePremium: (0, _attr.default)('number', { defaultValue: 0 }),

    // vehicle
    vehicleQuoted: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    totalPremiumQuotedForVehicle: (0, _attr.default)('number', { defaultValue: 0 }),
    totalPremiumSoldForVehicle: (0, _attr.default)('number', { defaultValue: 0 }),
    vehicleSold: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    vehicleSavings: (0, _attr.default)('number', { defaultValue: 0 }),
    vehicleQuotesCount: (0, _attr.default)('number', { defaultValue: 0 }),
    missingVehiclePremium: (0, _attr.default)('number', { defaultValue: 0 }),

    // umbrella
    umbrellaQuoted: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    totalPremiumQuotedForUmbrella: (0, _attr.default)('number', { defaultValue: 0 }),
    totalPremiumSoldForUmbrella: (0, _attr.default)('number', { defaultValue: 0 }),
    umbrellaSold: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    umbrellaSavings: (0, _attr.default)('number', { defaultValue: 0 }),
    umbrellaQuotesCount: (0, _attr.default)('number', { defaultValue: 0 }),
    missingUmbrellaPremium: (0, _attr.default)('number', { defaultValue: 0 }),

    // life
    lifeQuoted: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    totalPremiumQuotedForLife: (0, _attr.default)('number', { defaultValue: 0 }),
    totalPremiumSoldForLife: (0, _attr.default)('number', { defaultValue: 0 }),
    lifeSold: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    lifeSavings: (0, _attr.default)('number', { defaultValue: 0 }),
    lifeQuotesCount: (0, _attr.default)('number', { defaultValue: 0 }),
    missingLifePremium: (0, _attr.default)('number', { defaultValue: 0 }),

    // specialty
    specialtyQuoted: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    totalPremiumQuotedForSpecialty: (0, _attr.default)('number', { defaultValue: 0 }),
    totalPremiumSoldForSpecialty: (0, _attr.default)('number', { defaultValue: 0 }),
    specialtySold: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    specialtySavings: (0, _attr.default)('number', { defaultValue: 0 }),
    specialtyQuotesCount: (0, _attr.default)('number', { defaultValue: 0 }),
    missingSpecialtyPremium: (0, _attr.default)('number', { defaultValue: 0 }),

    // commercial
    commercialQuoted: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    totalPremiumQuotedForCommercial: (0, _attr.default)('number', { defaultValue: 0 }),
    totalPremiumSoldForCommercial: (0, _attr.default)('number', { defaultValue: 0 }),
    commercialSold: (0, _attr.default)('number-decimal', { defaultValue: 0 }),
    commercialSavings: (0, _attr.default)('number', { defaultValue: 0 }),
    commercialQuotesCount: (0, _attr.default)('number', { defaultValue: 0 }),
    missingCommercialPremium: (0, _attr.default)('number', { defaultValue: 0 }),

    annualSavings: (0, _attr.default)('number', { defaultValue: 0 }),
    creditClass: (0, _attr.default)(),
    tags: (0, _attr.default)(),
    numberOfAttempts: (0, _attr.default)('simple-decimal'),
    leftNotes: (0, _attr.default)(),
    quoteSentAt: (0, _attr.default)(),
    quoteViewedAt: (0, _attr.default)(),
    claims: (0, _attr.default)(),
    pipelinedUntil: (0, _attr.default)(),
    transitionState: (0, _attr.default)(),
    requoted: (0, _attr.default)('number', { defaultValue: 0 }),
    groupId: (0, _attr.default)(),
    takeovered: (0, _attr.default)('boolean'),

    userId: (0, _attr.default)(),
    userFullName: (0, _attr.default)(),
    zipCode: (0, _attr.default)(),

    transitionStatePlural: Ember.computed('transitionState', function () {
      var transitionState = this.get('transitionState');
      return transitionState + 's';
    }),

    annualSavingsPerUser: Ember.computed('commercialSavings', function () {
      var homeSavings = this.get('homeQuoted') > 0 ? this.get('homeSavings') : 0;
      var vehicleSavings = this.get('vehicleQuoted') > 0 ? this.get('vehicleSavings') : 0;
      var umbrellaSavings = this.get('umbrellaQuoted') > 0 ? this.get('umbrellaSavings') : 0;
      var lifeSavings = this.get('lifeQuoted') > 0 ? this.get('lifeSavings') : 0;
      var specialtySavings = this.get('specialtyQuoted') > 0 ? this.get('specialtySavings') : 0;
      var commercialSavings = this.get('commercialQuoted') > 0 ? this.get('commercialSavings') : 0;

      return homeSavings + vehicleSavings + umbrellaSavings + lifeSavings + specialtySavings + commercialSavings;
    })
  });
});