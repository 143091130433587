define('mvp-web/components/quote-forms/umbrella/add-third-party-quote', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    actions: {
      addThirdPartyQuote: function addThirdPartyQuote() {
        var store = this.get('store');
        var group = this.get('group');
        var paymentOption = store.createRecord('quotePaymentOption', { isDefault: true });

        store.createRecord('umbrellaQuote', {
          group: group,
          isFirstParty: false,
          quotePaymentOptions: [paymentOption],
          transitionState: group.get('transitionState')
        });
      }
    }
  });
});