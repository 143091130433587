define('mvp-web/serializers/at-workflow', ['exports', 'mvp-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      users: { serialize: false },
      type: { serialize: true },
      transitionToAtWorkflow: { serialize: false },
      fromUser: { serialize: false },
      replyToUser: { serialize: false }
    }
  });
});