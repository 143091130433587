define('mvp-web/components/cancel-quotes-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('index') === 0) {
        this.toggleProperty('quote.isMarkedForCancelation');
        this.get('toggleMarkedCanceled').perform(this.get('quote'));
      }
    },


    actions: {
      // didSelectStatus(value) {
      //   this.set('selectedStatus', value);
      // },
    }
  });
});