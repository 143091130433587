define('mvp-web/services/json-api-errors-notifier', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Service = Ember.Service;
  exports.default = Service.extend({
    notifications: service('notification-messages'),

    sendErrorInfo: function sendErrorInfo(error) {
      var errorMessage = error.errors.map(function (e) {
        return e.source.pointer.split('/').slice(-1)[0] + ' ' + e.detail;
      });
      this.get('notifications').error('Something went wrong: ' + errorMessage);
    }
  });
});