define('mvp-web/models/email-unsubscribe-report', ['exports', 'ember-data/relationships', 'ember-data/attr', 'ember-data/model'], function (exports, _relationships, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    createdAt: (0, _attr.default)(),
    updatedAt: (0, _attr.default)(),
    start_time: (0, _attr.default)(), // eslint-disable-line camelcase
    end_time: (0, _attr.default)(), // eslint-disable-line camelcase
    url: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    range: (0, _attr.default)('string'),
    user: (0, _relationships.belongsTo)('user')
  });
});