define('mvp-web/components/chat-window-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Component = Ember.Component;
  exports.default = Component.extend({
    chat: service(),
    classNames: ['chat-window-manager']
  });
});